import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, ControlContainer, FormBuilder, FormGroup} from '@angular/forms';
import {DefaultRadio, StatusInCanada} from '../utils/questions/question-choices';
import {ApplicantType} from '../../models/applicant-type';
import {Observable, Subscription} from 'rxjs';
import {StatusInCanadaValidationUtil as ValidationUtil} from './util/status-in-canada-validation.util';
import {ValidationService} from '../../services/validation.service';
import {FormControlConfigs} from './util/status-in-canada-component.formcontrols';
import {StatusInCanadaComponentUtil as Util} from './util/status-in-canada-component.util';
import {StringUtil} from '../../utils/string-util';
import {DataType} from '../ui/text-question/util/text-question-component.util';
import {UrlInfo} from '../../models/url-map';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'sd-status-in-canada-questions',
  templateUrl: './status-in-canada-questions.component.html',
  styleUrls: ['./status-in-canada-questions.component.scss']
})
export class StatusInCanadaQuestionsComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  @Input()
  public showError: boolean;
  @Input()
  public applicationAnswers: any
  @Input()
  public id: string;
  @Input()
  applicantType: ApplicantType;
  @Input()
  firstName: string;
  @Input()
  lastName: string;
  @Input()
  parentFormGroups: AbstractControl[];
  @Input()
  public parentFormGroupIndex: string;
  @Input()
  public labelParam: any | {};
  @Input()
  checkboxBackgroundClass: string;
  @Input()
  disableIdentifyingFields: boolean;
  @Input()
  hintText: string | undefined;
  @Input()
  hintContent: string | undefined;
  @Input()
  hintContentParam: any;
  @Input()
  smallRightMargin: boolean;

  readonly dataType = DataType;

  form: FormGroup;
  noSINCheckBoxItems: {value: string, label: string, labelParam: any, checked: boolean}[] |
    {value: string, label: string, checked: boolean}[];
  choices = DefaultRadio;
  uciFieldValidationFns: {validationFunction: (params: any) => boolean, errorKey: string}[];
  sinValidationFns: {validationFunction: (value: []) => boolean, errorKey: string}[];
  asyncSinValidationFns: [{validationFunction: (value: string) => Observable<boolean>, errorKey: string}];
  certificateOfIndianStatusNumberValidationFns: {validationFunction: (value: []) => boolean, errorKey: string}[];
  healthCardNoValidationFns: {validationFunction: (value: []) => boolean, errorKey: string}[];
  asyncHealthCardNoValidationFns: [{validationFunction: (value: string) => Observable<boolean>, errorKey: string}];
  immigrationInfoFieldsRequired: boolean | false;
  statusDropDownValidationFns;
  formControlNames: any;
  statusInCanadaChoices = StatusInCanada;
  onChangeSubs: Subscription[] = [];
  invalidSINError: string | undefined;
  invalidCISNError: string | undefined;
  invalidHNError: string | undefined;
  sponsoredFieldLabelParams: any = {};
  resettlementAssistanceProgramLinkParam: any;
  noLegalStatusInCanadaCheckBoxItem;

  constructor(public controlContainer: ControlContainer,
              public formBuilder: FormBuilder,
              public validationService: ValidationService,
              public configService: ConfigService,
              public translator: TranslateService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
    this.formControlNames = FormControlConfigs[this.applicantType];
    this.noSINCheckBoxItems = this.getNoSINCheckBoxValue(false);
    this.noLegalStatusInCanadaCheckBoxItem = this.getNoLegalStatusInCanCheckBoxChanges(false);
    this.setupLinks();
    this.setupValidators();
    this.addInitialFormControls();

    this.onChangeSubs.push(this.form.get(this.formControlNames.statusInCanada.name).valueChanges.subscribe(value => {
      if (value !== undefined) {
        this.immigrationInfoFieldsRequired = Util.isStatusRequireImmigrationRelatedFields(value);
        // Update arrivalDateValidator for gov-assisted refugee status
        if (this.isPrimaryApplicant()) {
          this.validateArrivalDate('dateOfBirth')
        }

        this.addOrRemoveDynamicFormControls(value);
      }
    }));

    this.setUpInitialValues();

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
      if (this.isPrimaryApplicant()) {
        this.validateArrivalDate('dateOfBirth')
      }
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.firstName || changes.lastName) && !this.isPrimaryApplicant()) {
      this.noSINCheckBoxItems = this.getNoSINCheckBoxValue(!!this.form?.get(this.formControlNames.noSin.name)?.value);
      this.sponsoredFieldLabelParams = {firstName: this.firstName, lastName: this.lastName};
    }

    this.noLegalStatusInCanadaCheckBoxItem = this.getNoLegalStatusInCanCheckBoxChanges(!!this.form?.get(this.formControlNames.noLegalStatusInCanada?.name)?.value)
  }

  ngOnDestroy() {
    for (const sub of this.onChangeSubs) {
      sub?.unsubscribe();
    }
  }

  private setupLinks(): void {
    this.resettlementAssistanceProgramLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.resettlementAssistanceProgram)
    };
  }

  isUCIRequired() {
    if (Util.isRefugee(this.statusInCanada)) {
      return true;
    }
    const isSponsored = 'yes' === this.sponsored;
    const isNoSin = !!this.form.get(this.formControlNames.noSin.name)?.value;
    const isHeathCardNumberEntered = !!this.healthCardNumber;

    return (Util.isNonCanadianBornCitizenNonRefugee(this.statusInCanada) &&
      (isSponsored || (!isSponsored && isNoSin && !isHeathCardNumberEntered)));
  }

  isCertificateOfIndianStatusNumberRequired() {
    const isNoSin = !!this.form.get(this.formControlNames.noSin.name)?.value;
    const isHeathCardNumberEntered = !!this.healthCardNumber;
    const isHealthCardNumberValid = this.form.get(this.formControlNames.healthCardNumber.name)?.status === 'VALID';
    const isHealthCardNumberPending = this.form.get(this.formControlNames.healthCardNumber.name)?.status === 'PENDING';

    return (Util.isStatusIndian(this.statusInCanada) && isNoSin &&
      (!isHeathCardNumberEntered ||  // Not entered
       // Entered but invalid or validating (to avoid the flickering of 'option' and 'required' while waiting for async-call.
       !(isHeathCardNumberEntered && (isHealthCardNumberValid || isHealthCardNumberPending))));
  }

  isHealthCardNumberRequired() {
    const isSponsored = 'yes' === this.sponsored;
    const isNoSin = !!this.form.get(this.formControlNames.noSin.name)?.value;
    const isImmigrationFileNumberEntered = !!this.immigrationFileNumber;
    const isCertificateOfIndianStatusNumberEntered = !!this.certificateOfIndianStatusNumber;
    const isCertificateOfIndianStatusNumberValid = this.form.get(this.formControlNames.certificateOfIndianStatusNumber.name)?.status === 'VALID';

    return ((Util.isCitizenBornInCanada(this.statusInCanada) && isNoSin && !isCertificateOfIndianStatusNumberEntered) ||
      (Util.isStatusIndian(this.statusInCanada) && isNoSin && (!isCertificateOfIndianStatusNumberEntered ||
        !(isCertificateOfIndianStatusNumberEntered && isCertificateOfIndianStatusNumberValid))) ||  // Not entered or entered but invalid
      (Util.isNonCanadianBornCitizenNonRefugee(this.statusInCanada) && isNoSin && !isSponsored && !isImmigrationFileNumberEntered));
  }

  isPrimaryApplicant() {
    return ApplicantType.APPLICANT === this.applicantType;
  }

  isSpouseApplicant() {
    return ApplicantType.SPOUSE === this.applicantType;
  }

  isSINRequired() {
    return !this.form.get(this.formControlNames.noSin.name)?.value;
  }

  onNoSINCheckBoxChanges(event: any) {
    if (event && event.length) {
      this.form.get(this.formControlNames.noSin.name).setValue(event)
      this.form.get(this.formControlNames.socialInsuranceNumber.name).setValue(null)
      this.form.get(this.formControlNames.socialInsuranceNumber.name).disable();
      // Delete from JSON data in order to clean up the saved data
      delete this.applicationAnswers.jsonData[this.formControlNames.socialInsuranceNumber.name];
    } else {
      this.form.get(this.formControlNames.noSin.name).reset()
      this.form.get(this.formControlNames.socialInsuranceNumber.name).enable()
      // Delete from JSON data in order to clean up the saved data
      delete this.applicationAnswers.jsonData[this.formControlNames.noSin.name];
    }
  }

  private addInitialFormControls(): any {
    for (const v of Object.values(this.formControlNames) as any) {
      if (v.alwaysVisible) {
        this.form.addControl(v.name, this.formBuilder.control(''));
      }
    }
  }

  private addOrRemoveDynamicFormControls(statusInCanada: string): void {
    for (const v of Object.values(this.formControlNames) as any) {
      if (this.isFieldVisible(v, statusInCanada)) {
        this.form.addControl(v.name, this.formBuilder.control(''));
      } else {
        this.form.removeControl(v.name);

        if (ApplicantType.CHILD === this.applicantType &&
            this.applicationAnswers.jsonData.childList[this.parentFormGroupIndex]) {
          delete this.applicationAnswers.jsonData.childList[this.parentFormGroupIndex][v.name];
        } else {
          delete this.applicationAnswers.jsonData[v.name];
        }

        // uncheck the 'no Sin' checkbox
        if (v.name === this.formControlNames.noSin.name) {
          this.noSINCheckBoxItems = this.getNoSINCheckBoxValue(false);
        }

        // uncheck the 'no Status in Canada' checkbox
        if (v.name === this.formControlNames.noLegalStatusInCanada?.name) {
          this.noLegalStatusInCanadaCheckBoxItem = this.getNoLegalStatusInCanCheckBoxChanges(false);
        }
      }
    }
  }

  isFieldVisible(fieldConfig: any, statusInCanada: string): boolean {
    if (!fieldConfig) {
      return false;
    }

    let fieldVisible = false;
    if (fieldConfig.alwaysVisible) {
      fieldVisible = true;
    } else if (!this.isPrimaryApplicant() && fieldConfig.visibleForNoLegalStatusInCanada){
      fieldVisible = !!this.form?.get(this.formControlNames.noLegalStatusInCanada.name)?.value;
    } else if (Util.isCitizenBornInCanada(statusInCanada) && fieldConfig.visibleForCitizenBornInCanada) {
      fieldVisible = true;
    } else if (Util.isStatusRequireImmigrationRelatedFields(statusInCanada) &&
        (fieldConfig.visibleForNonCanadaBornCitizen || fieldConfig.visibleForNonCitizenNonRefugee || fieldConfig.visibleForRefugee)) {
      // Special case for government assisted refugee
      fieldVisible = !(Util.isGovernmentAssistedRefugee(statusInCanada) && !fieldConfig.visibleForGovernmentAssistedRefugee);
    } else if (!this.isPrimaryApplicant() && Util.isStatusNotEligibleForSupport(statusInCanada) &&
        fieldConfig.visibleForTempResidents) {
      fieldVisible = true;
    } else if ((Util.isStatusIndian(statusInCanada)) && fieldConfig.visibleForStatusIndian) {
      fieldVisible = true;
    } else if (fieldConfig.name === this.formControlNames.tenYearsLivedInCanada?.name &&
      this.isPrimaryApplicant() &&
      Util.isTenYearsLivedInCanadaFieldVisible(statusInCanada, this.form.controls.dateOfBirth?.value)) {
      fieldVisible = true;
    }

    return fieldVisible;
  }

  isStatusInCanadaRequired(){
    return !!this.form?.get(this.formControlNames.noLegalStatusInCanada.name)?.value;
  }

  private setUpInitialValues() {
    if (this.applicationAnswers) {
      for (const v of Object.values(this.formControlNames) as any) {
        if (this.applicationAnswers.jsonData[v.name] && this.form.get(v.name)) {
          this.form.get(v.name).setValue(this.applicationAnswers.jsonData[v.name], {emitEvent: true});
        }
      }

      if (this.form.get(this.formControlNames.noSin.name)?.value) {
        this.noSINCheckBoxItems = this.getNoSINCheckBoxValue(true);
        this.form.get(this.formControlNames.socialInsuranceNumber.name).setValue(null);
        this.form.get(this.formControlNames.socialInsuranceNumber.name).disable();
      }

      if (this.form.get(this.formControlNames.noLegalStatusInCanada?.name)?.value) {
        this.noLegalStatusInCanadaCheckBoxItem = this.getNoLegalStatusInCanCheckBoxChanges(true);
        this.form.get(this.formControlNames.statusInCanada.name).setValue('');
        this.form.get(this.formControlNames.statusInCanada.name).disable();
      }
    }
  }

  private setupValidators() {
    this.statusDropDownValidationFns = ValidationUtil.getStatusInCanadaValidationFns(this.applicantType);

    this.sinValidationFns = ValidationUtil.getSINFieldValidationFns(this.applicationAnswers, this.applicantType, this.parentFormGroups);
    this.asyncSinValidationFns = [
      {
        validationFunction: (value) => {
          const cleansedSin = StringUtil.removeSinHealthCardNumberSeparators(value);
          return this.validationService.validate('sin', cleansedSin);
        },
        errorKey: 'status-in-canada.error.invalidSIN2'
      }
    ];

    this.certificateOfIndianStatusNumberValidationFns =
      ValidationUtil.getCertificateOfIndianStatusNumberFieldValidationFns(this.applicationAnswers,
        this.applicantType, this.parentFormGroups);

    this.healthCardNoValidationFns = ValidationUtil.getHNFieldValidationFns(this.applicationAnswers, this.applicantType,
      this.parentFormGroups);
    this.asyncHealthCardNoValidationFns = [
      {
        validationFunction: (value) => {
          const cleansedHealthCardNumber = StringUtil.removeSinHealthCardNumberSeparators(value);
          return this.validationService.validate('health-card-number', cleansedHealthCardNumber);
        },
        errorKey: 'status-in-canada.error.invalidHealthCardNumber'
      }
    ];

    this.uciFieldValidationFns = ValidationUtil.getUCIFieldValidationFns(this.applicationAnswers, this.applicantType,
      this.parentFormGroups);

    if (this.isPrimaryApplicant()) {
      this.onChangeSubs.push(this.form.controls.dateOfBirth?.valueChanges.subscribe(value => {
        this.validateArrivalDate('dateOfBirth');
        // Remove the control, if the condition is no longer valid, otherwise it stays in the form even if the element is not in the DOM.
        if ((!!this.form.get(this.formControlNames?.tenYearsLivedInCanada?.name))
            && (!Util.isTenYearsLivedInCanadaFieldVisible(this.statusInCanada, this.form.controls.dateOfBirth?.value))) {
          this.form.removeControl(this.formControlNames.tenYearsLivedInCanada.name);
          delete this.applicationAnswers.jsonData[this.formControlNames.tenYearsLivedInCanada.name];
        }
      }));
    } else if (ApplicantType.SPOUSE === this.applicantType && this.form.controls.spouseDateOfBirth) {
      // Listen to spouse DOB changes to update the value to compare with
      this.onChangeSubs.push(this.form.controls.spouseDateOfBirth.valueChanges.subscribe(value => {
        this.validateArrivalDate('spouseDateOfBirth')
      }));
    } else if (ApplicantType.CHILD === this.applicantType && this.form.controls.childDateOfBirth) {
      // Listen to child DOB changes to update the value to compare with
      this.onChangeSubs.push(this.form.controls.childDateOfBirth.valueChanges.subscribe(value => {
        this.validateArrivalDate('childDateOfBirth')
      }));
    }
  }

  // use blur event because arrivalDateToCanada valueChanges event does not work.
  onArrivalDateToCanadaBlur(event): void {
    if (!event) {
      return;
    }
    // this is to deal with user selects status in canada drop down, then fills birthday and arrivalDate to Canada.
    let dateOfBirthControlName: string;
    if (this.isPrimaryApplicant()) {
      dateOfBirthControlName = 'dateOfBirth'
    } else if (this.isSpouseApplicant()) {
      dateOfBirthControlName = 'spouseDateOfBirth'
    } else {
      dateOfBirthControlName = 'childDateOfBirth'
    }
    this.validateArrivalDate(dateOfBirthControlName)
  }

  isDisabled(fieldName: string) {
    return (this.disableIdentifyingFields && !!this.applicationAnswers?.jsonData && !!this.applicationAnswers?.jsonData[fieldName]);
  }

  private validateArrivalDate(dateOfBirthControlName: string) {
    if (this.isPrimaryApplicant()) {
      const isValid = ValidationUtil.validateArrivalDateToCanadaWithDateOfBirth(this.form.get('dateOfBirth'),
        this.form.get(this.formControlNames.arrivalDateToCanada.name));
      if (isValid) {
        ValidationUtil.validateArrivalDateToCanadaForGovernmentRefugee(
          this.applicationAnswers.jsonData?.withDisability,
          this.form.get(this.formControlNames.arrivalDateToCanada.name),
          this.form.get(this.formControlNames.statusInCanada.name),
          this.resettlementAssistanceProgramLinkParam)
      }
    } else {
      ValidationUtil.validateArrivalDateToCanadaWithDateOfBirth(this.form.get(dateOfBirthControlName),
        this.form.get(this.formControlNames.arrivalDateToCanada.name));
    }
  }

  private getNoLegalStatusInCanCheckBoxChanges(checked: boolean){
    if (ApplicantType.CHILD === this.applicantType) {
      return [{value: 'yes', label: this.id + '.noLegalStatusInCanada', labelParam: this.labelParam, checked}];
    } else {
      return [{value: 'yes', label: this.id + '.noLegalStatusInCanada', checked}];
    }
  }

  onNoLegalStatusInCanadaCheckBoxChanges(event: any): void{
    if (event && event.length) {
      this.form.get(this.formControlNames.noLegalStatusInCanada.name).setValue(event)
      this.form.get(this.formControlNames.statusInCanada.name).setValue('')
      this.form.get(this.formControlNames.statusInCanada.name).disable();
      // Delete from JSON data in order to clean up the saved data
      delete this.applicationAnswers.jsonData[this.formControlNames.statusInCanada.name];
    } else {
      this.form.get(this.formControlNames.noLegalStatusInCanada.name).reset()
      this.form.get(this.formControlNames.statusInCanada.name).enable()
      // Delete from JSON data in order to clean up the saved data
      delete this.applicationAnswers.jsonData[this.formControlNames.noLegalStatusInCanada.name];
    }
  }

  private getNoSINCheckBoxValue(checked: boolean) {
    if (ApplicantType.CHILD === this.applicantType) {
      return [{value: 'yes', label: this.id + '.noSin', labelParam: this.labelParam, checked}];
    } else {
      return [{value: 'yes', label: this.id + '.noSin', checked}];
    }
  }

  get statusInCanada() {
    return this.form.get(this.formControlNames.statusInCanada.name)?.value;
  }

  private get certificateOfIndianStatusNumber() {
    return this.form.get(this.formControlNames.certificateOfIndianStatusNumber.name)?.value;
  }

  private get healthCardNumber() {
    return this.form.get(this.formControlNames.healthCardNumber.name)?.value;
  }

  private get sponsored() {
    return this.form.get(this.formControlNames.sponsored.name)?.value;
  }

  private get immigrationFileNumber() {
    return this.form.get(this.formControlNames.immigrationFileNumber.name)?.value;
  }
}
