import moment from 'moment';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {StringUtil} from '../utils/string-util';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {inValidVehicleModelYearPattern} from '../common/utils/validators/custom-validators';

export abstract class SadaCustomValidator {
  static validateOverAge(date: string, ageLimit: number) {
    if (!date || !SadaCustomValidator.validateFullDateEntered(date)) {
      return false
    }
    const trimmedDate = date.replace(/\s/g, '');
    const age = moment().diff(moment(trimmedDate, 'YYYY/MM/DD'), 'years', true);
    return age >= ageLimit;
  }
  static validateAgeBelow20(date: string) {
    if (!date) {
      return false
    }
    const trimmedDate = date.replace(/\s/g, '');
    const age = moment().diff(moment(trimmedDate, 'YYYY/MM/DD'), 'years', true);
    return age < 20;
  }
  static validateFullDateEntered(date: string){
    if (!date) {
      return false;
    }
    return date.match(/\d+/g)?.length === 3;
  }
  static validateAgeBetween16And17_5(date: string): boolean {
    if (!date || !/^\d{4}\/\d{2}\/\d{2}$/.test(date) ) {
      return false;
    }

    const today = moment().startOf('day'); // Start of the current day
    const birthDate = moment(date, 'YYYY/MM/DD');
    const exact16Birthday = birthDate.clone().add(16, 'years');
    const halfBirthday = birthDate.clone().add(17.5, 'years');

    // Check that today is strictly after the 16th birthday and strictly before the 17.5th birthday
    return today.isSameOrAfter(exact16Birthday, 'day') && today.isBefore(halfBirthday, 'day');
  }


  // Params 0: date, Params 1: ageLimit
  static validateOptionalOverAge(params) {
    if (!params[0]) {
      return true;
    }
    return SadaCustomValidator.validateOverAge(params[0], params[1]);
  }

  // Params 0: difference in years, Params 1: Year
  static validateYearsFromToday(params) {
    const inputYear = params[0]
    const differenceInYears = params[1]
    if (!differenceInYears || !inputYear)  {
      return false
    }
    return inputYear - new Date().getFullYear() > differenceInYears;
  }

  static checkAnyChildNotBornInCanada(params) {
    if (Array.isArray(params[0]) && params[1] === 'yes') {
      const notBornInCanada = params[0].filter(child => {
        return child.childStatusInCanada !== 'Canadian citizen born in Canada'
      });
      return notBornInCanada.length > 0;
    }
    return false;
  }

  // Params: {childList}, {childrenLivingWithYou}, {dateOfBirth}, {sexAtBirth}, {spouseDateOfBirth}, {spouseSexAtBirth}
  static checkFemalesOver12Present(params) {
    let applicantDOB;
    let applicantSex;
    let spouseDOB;
    let spouseSex;

    if ( params[2]) {
      applicantDOB = params[2].replace(/\s/g, '');
    }
    if ( params[3]) {
      applicantSex = params[3];
    }
    if ( params[4]) {
      spouseDOB = params[4].replace(/\s/g, '');
    }
    if ( params[5]) {
      spouseSex = params[5];
    }

    if (applicantSex && applicantSex === 'female') {
      const age = moment().diff(
        moment(applicantDOB, 'YYYY/MM/DD'),
        'years',
        true
      );
      return age >= 12;
    } else {
      if (spouseSex && spouseSex === 'female') {
        const age = moment().diff(
          moment(spouseDOB, 'YYYY/MM/DD'),
          'years',
          true
        );
        return age >= 12;
      } else {
        if (Array.isArray(params[0]) && params[1] === 'yes') {
          const femaleChildrenOver12 = params[0].filter((child) => {
            if (child.childDateOfBirth) {
              const trimmedDate = child.childDateOfBirth.replace(/\s/g, '');
              const age = moment().diff(
                moment(trimmedDate, 'YYYY/MM/DD'),
                'years',
                true
              );
              return child.childSexAtBirth === 'female' && age >= 12;
            }
          });
          return femaleChildrenOver12.length > 0;
        }
      }
    }
  }

  static validateName(params): boolean {
    const name = `${params[0]}`.trim()
    if ('- -' === name) {
      return true
    }

    const regexp = new RegExp('^[-A-Za-zÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\']+[-A-Za-zÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\' ]*$')
    return regexp.test(name)
  }

  static validateInvalidFrCharacterInName(params): boolean {
    const name = `${params[0]}`.trim()
    const invalidPattern = new RegExp(invalidFrenchCharacterPattern);
    return SadaCustomValidator.validateName(params) || !invalidPattern.test(name)
  }
  static validateInvalidNameWithOnlySpecialCharacter(params): boolean {
    const name = `${params[0]}`.trim()
    const invalidPattern = new RegExp(invalidNameWithOnlySpecialCharacter);
    const matchesPattern = invalidPattern.test(name);
    return !matchesPattern;
  }

  static validateSponsorName(params): boolean {
    const name = `${params[0]}`.trim()
    if ('- -' === name) {
      return true
    }

    const regexp = new RegExp('^[-A-Za-zÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\']+[-A-Za-zÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\' \ ]*$')
    return regexp.test(name)
  }

  static validateStreetNameLength(params): boolean {
    const required = params[0]
    const streetName = params[1]

    const regexp = new RegExp('^[\sa-zA-Z0-9-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'\. ]+[\sa-zA-Z0-9-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'\/. ]*$')
    const isBetween1And60Chars = streetName && streetName.length >= 1 && streetName.length <= 60
    if (required) {
      return isBetween1And60Chars && regexp.test(streetName)
    } else {
      return !streetName || isBetween1And60Chars
    }
  }

  static validateStation(params): boolean {
    const value = params[0];
    const regexp = new RegExp('^[a-zA-Z0-9ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü ]*$')

    return regexp.test(value)
  }

  static validateStringHasOnlyAlphaNumericCharsAndDashes(params): boolean {
    const value = params[0];
    const regexp = new RegExp('^[a-zA-Z0-9-àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸÇßØÅÆÞ]*$')
    return regexp.test(value)
  }

  static validateStringHasOnlyNumericChars(params): boolean {
    const value = params[0];
    const regexp = new RegExp('^[0-9]*$')
    return regexp.test(value)
  }

  static validateDescription(params): boolean {
    const desc = params[0];
    const regexp = new RegExp('^[\sa-zA-Z0-9-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'. ]*$')
    return regexp.test(desc)
  }

  static validateStreetName(params): boolean {
    const desc = params[0];
    const regexp = new RegExp('^[a-zA-Z0-9&.ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü \'-]*$')
    return regexp.test(desc)
  }

  static validateVehicleMake(params): boolean {
    const desc = params[0];
    const regexp = new RegExp('^[a-zA-Z-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü]*$')
    return regexp.test(desc)
  }

  static validateGeneralDelivery(params): boolean {
    const desc = params[0].toString();
    const regexp = new RegExp('^[\sa-zA-Z0-9-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'\/. ]*$')

    if ( desc.length < 1 || desc.length > 60) {
      return false;
    } else {
      return regexp.test(desc)
    }
  }

  static validateDashSlashLetterAndNumber(params): boolean {
    const value = params[0];
    const regexp = new RegExp('^[a-zA-Z0-9-àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸÇßØÅÆÞ\/]*$')
    return regexp.test(value)
  }

  static validatePoBox(params): boolean {
    const value = params[0];
    const regexp = new RegExp('^[0-9-A-Za-zÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü ]*$')

    return regexp.test(value)
  }

  static validateCityOrTownName(params): boolean {
    const desc = params[0];
    const regexp = new RegExp('^[\sa-zA-Z-\'. ]*$')
    return regexp.test(desc)
  }

  /**
   * Valid SIN formats:
   * 123-456-789
   * 123 456 789
   * 12345-6789
   * 123456789
   */
  static validateSocialInsuranceNumberFormat(params): boolean {
    const socialInsuranceNumberFormat1 = new RegExp('^([0-9]{3}-[0-9]{3}-[0-9]{3})$');
    const socialInsuranceNumberFormat2 = new RegExp('^([0-9]{9})$');
    return (socialInsuranceNumberFormat1.test(params[0]) || socialInsuranceNumberFormat2.test(params[0]));
  }

  /**
   * Valid Health Card Number formats:
   * 123-456-7890
   * 123 456 7890
   * 12345-67890
   * 1234567890
   */
  static validateHealthCardNumberFormat(params): boolean {
    const firstDigitHCN = params[0].charAt(0)
    const regexTwinDigit = '^('+firstDigitHCN+')\\1{3}-('+firstDigitHCN+')\\1{2}-('+firstDigitHCN+')\\1{2}$'
    const healthCardNumberFormat1 = new RegExp('^([0-9]{4}-[0-9]{3}-[0-9]{3})$');
    const healthCardNumberFormat2 = new RegExp('^([0-9]{10})$');
    const healthCardNumberFormat3 = new RegExp('^([0-9])\\1{9}$');
    const healthCardNumberFormat4 = new RegExp(regexTwinDigit);

    return ((healthCardNumberFormat1.test(params[0]) || healthCardNumberFormat2.test(params[0])) &&
      !(healthCardNumberFormat3.test(params[0]) || healthCardNumberFormat4.test(params[0])))
  }

  static validatePhoneNumber(params): boolean {
    let validNumber = false;
    const value = params[0];
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const regionCode = params[1];
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        value, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode);
    } catch (e) { }
    return validNumber;
  }

  /**
   * Valid Phone Number formats:
   * (416)-234-5678
   * (416) 234 5678
   * 416-234-5678
   * 4162345678
   */
  static validatePhoneNumberFormat(params): boolean {
    const phoneNumberFormat1 = new RegExp('^[(][0-9]{3}[)]\\s[0-9]{3}[-][0-9]{4}$');
    const phoneNumberFormat2 = new RegExp('^([0-9]{10})$');
    return (phoneNumberFormat1.test(params[0]) || phoneNumberFormat2.test(params[0]));
  }

  static validateImmigrationFileNumber(params): boolean {
    if (!params[0]) {
      return true
    }
    const fileNumber = params[0];
    const filePattern1 = /^[0-9]{4}-[0-9]{4}$/;
    const filePattern2 = /^[0-9]{2}-[0-9]{4}-[0-9]{4}$/;
    const filePattern3 = /^([0-9]{8}|[0-9]{10})$/;

    return filePattern1.test(fileNumber) || filePattern2.test(fileNumber) || filePattern3.test(fileNumber);
  }

  static validateUCIPattern(params): boolean {
    const fileNumber = params[0].replace(/-/g, '');
    if (fileNumber && /^[0-9]+$/.test(fileNumber) && (fileNumber.length === 8 || fileNumber.length === 10)) {
      const repeatingDigitsPattern = /^(?:(\d)(?!\1+$)\d{7}|(\d)(?!\2+$)\d{9})$/;
      return repeatingDigitsPattern.test(fileNumber);
    }
    return true;
  }
  static validateUniqueSIN(sinToCompare, sinList): boolean {
    return !sinList.some(value => value === sinToCompare)
  }

  static validateUniqueApplicantSIN(params): boolean {
    const applicantSIN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseSIN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const sinToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators(child.childSocialInsuranceNumber));
    sinToCompare.push(spouseSIN);

    return SadaCustomValidator.validateUniqueSIN(applicantSIN,
      sinToCompare.filter(childSIN => childSIN))
  }

  static validateUniqueSpouseSIN(params): boolean {
    const applicantSIN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseSIN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const sinToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators(child.childSocialInsuranceNumber));
    sinToCompare.push(applicantSIN);

    return SadaCustomValidator.validateUniqueSIN(spouseSIN, sinToCompare.filter(childSIN => childSIN))
  }

  static validateUniqueChildSIN(params): boolean {
    const childSIN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const applicantSIN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const spouseSIN = StringUtil.removeSinHealthCardNumberSeparators(params[2]);
    const children = params[3];

    if (!childSIN) { return true }

    const childrenWithSameSIN = children.filter(child => {
      let childSocialInsuranceNumber;
      if (child instanceof FormGroup) {
        childSocialInsuranceNumber = StringUtil.removeSinHealthCardNumberSeparators(child.get('childSocialInsuranceNumber')?.value);
      } else {
        childSocialInsuranceNumber = StringUtil.removeSinHealthCardNumberSeparators(child.childSocialInsuranceNumber);
      }
      return childSocialInsuranceNumber && Number(childSocialInsuranceNumber) === Number(childSIN);
    });
    return ((!applicantSIN || applicantSIN !== childSIN)) &&
      (!spouseSIN || spouseSIN !== childSIN) &&
      childrenWithSameSIN.length === 1; // children with the SIN includes yourself
  }

  static validateUniqueCertificateOfIndianStatusNumber(cisnToCompare, cisnList): boolean {
    return !cisnList.some(value => value === cisnToCompare)
  }

  static validateUniqueApplicantCertificateOfIndianStatusNumber(params): boolean {
    const applicantCisn = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseCisn = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const cisnToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators
      (child.childCertificateOfIndianStatusNumber));
    cisnToCompare.push(spouseCisn);

    return SadaCustomValidator.validateUniqueCertificateOfIndianStatusNumber(applicantCisn,
      cisnToCompare.filter(childCisn => childCisn))
  }

  static validateUniqueSpouseCertificateOfIndianStatusNumber(params): boolean {
    const applicantCisn = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseCisn = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const cisnToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators
      (child.childCertificateOfIndianStatusNumber));
    cisnToCompare.push(applicantCisn);

    return SadaCustomValidator.validateUniqueCertificateOfIndianStatusNumber(spouseCisn, cisnToCompare.filter(childCisn => childCisn))
  }

  static validateUniqueChildCertificateOfIndianStatusNumber(params): boolean {
    const childCisn = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const applicantCisn = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const spouseCisn = StringUtil.removeSinHealthCardNumberSeparators(params[2]);
    const children = params[3];

    if (!childCisn) { return true }

    const childrenWithSameCisn = children.filter(child => {
      let childCertificateOfIndianStatusNumber;
      if (child instanceof FormGroup) {
        childCertificateOfIndianStatusNumber = StringUtil.removeSinHealthCardNumberSeparators(child.get('childCertificateOfIndianStatusNumber')?.value);
      } else {
        childCertificateOfIndianStatusNumber = StringUtil.removeSinHealthCardNumberSeparators(child.childCertificateOfIndianStatusNumber);
      }
      return childCertificateOfIndianStatusNumber && Number(childCertificateOfIndianStatusNumber) === Number(childCisn);
    });
    return ((!applicantCisn || applicantCisn !== childCisn)) &&
      (!spouseCisn || spouseCisn !== childCisn) &&
      childrenWithSameCisn.length === 1; // children with the CISN includes yourself
  }

  static validateUniqueHN(hnToCompare, hnList): boolean {
    return !hnList.some(value => value === hnToCompare)
  }

  static validateUniqueApplicantHN(params): boolean {
    const applicantHN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseHN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const hnToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators(child.childHealthCardNumber));
    hnToCompare.push(spouseHN);

    return SadaCustomValidator.validateUniqueHN(applicantHN,
      hnToCompare.filter(childHN => childHN))
  }

  static validateUniqueSpouseHN(params): boolean {
    const applicantHN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const spouseHN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const hnToCompare = params[2].map(child => StringUtil.removeSinHealthCardNumberSeparators(child.childHealthCardNumber));
    hnToCompare.push(applicantHN);

    return SadaCustomValidator.validateUniqueHN(spouseHN, hnToCompare.filter(childHN => childHN))
  }

  static validateUniqueChildHN(params): boolean {
    const childHN = StringUtil.removeSinHealthCardNumberSeparators(params[0]);
    const applicantHN = StringUtil.removeSinHealthCardNumberSeparators(params[1]);
    const spouseHN = StringUtil.removeSinHealthCardNumberSeparators(params[2]);
    const children = params[3];

    if (!childHN) { return true }

    const childrenWithSameHN = children.filter(child => {
      let childHealthCardNumber;
      if (child instanceof FormGroup) {
        childHealthCardNumber = StringUtil.removeSinHealthCardNumberSeparators(child.get('childHealthCardNumber')?.value);
      } else {
        childHealthCardNumber = StringUtil.removeSinHealthCardNumberSeparators(child.childHealthCardNumber);
      }
      return childHealthCardNumber && Number(childHealthCardNumber) === Number(childHN);
    });
    return ((!applicantHN || applicantHN !== childHN)) &&
      (!spouseHN || spouseHN !== childHN) &&
      childrenWithSameHN.length === 1;  // children with the HN includes yourself
  }

  static validateUniqueUCI(uciToCompare, uciList): boolean {
    return !uciList.some(value => {
      return SadaCustomValidator.removeHyphens(value) === SadaCustomValidator.removeHyphens(uciToCompare)
    })
  }

  static validateUniqueApplicantUCI(params): boolean {
    const applicantUCI = params[0]
    const spouseUCI = params[1]
    const uciToCompare = params[2].map(child => child.childImmigrationFileNumber)
    uciToCompare.push(spouseUCI)

    return SadaCustomValidator.validateUniqueUCI(applicantUCI,
      uciToCompare.filter(childUCI => childUCI))
  }

  static validateUniqueSpouseUCI(params): boolean {
    const applicantUCI = params[0]
    const spouseUCI = params[1]
    const uciToCompare = params[2].map(child => child.childImmigrationFileNumber)
    uciToCompare.push(applicantUCI)

    return SadaCustomValidator.validateUniqueUCI(spouseUCI, uciToCompare.filter(childUCI => childUCI))
  }

  static validateUniqueChildUCI(params): boolean {
    const childUCI: string = SadaCustomValidator.removeHyphens(params[0]);
    const applicantUCI: string = SadaCustomValidator.removeHyphens(params[1]);
    const spouseUCI: string = SadaCustomValidator.removeHyphens(params[2]);
    const children = params[3]

    if (!childUCI) { return true }

    const childrenWithSameHN = children.filter(child => {
      let childImmigrationFileNumber;
      if (child instanceof FormGroup) {
        childImmigrationFileNumber = SadaCustomValidator.removeHyphens(child.get('childImmigrationFileNumber')?.value);
      } else {
        childImmigrationFileNumber = SadaCustomValidator.removeHyphens(child.childImmigrationFileNumber)
      }
      // UCI should not be non-numeric, this is just to avoid flagging as duplicate UCI when the input UCI is non-numeric.
      return childImmigrationFileNumber && childImmigrationFileNumber === childUCI;
    })
    return ((!applicantUCI || applicantUCI !== childUCI)) &&
      (!spouseUCI || spouseUCI !== childUCI) &&
      childrenWithSameHN.length === 1; // children with the HN includes yourself
  }

  static removeHyphens(value): string {
    return value ? String(value).replace(/-/g, '') : undefined;
  }

  static validateEmailDomainAllowed(params): boolean {
    return !params.toString().endsWith('.ru');
  }

  static validateBasicEmailFormat(params): boolean {
    const regexpEmailAddress = new RegExp(basicEmailAddressPattern);

    return regexpEmailAddress.test(params[0])
  }

  static validateEmail(params): boolean {
    const regexpEmailAddress = new RegExp(emailAddressPattern);

    return regexpEmailAddress.test(params[0])
  }

  static validateSpecialCharactersInEmail(params): boolean {
    const regexpEmailAddress = new RegExp(emailAddressSpecialCharactersPattern);
    return regexpEmailAddress.test(params[0])
  }

// 66437-Functionality might be used in future if SAMS wants to support french characters for the email field
  static validateFrenchCharactersInEmail(params): boolean {
    const regexpEmailAddress = new RegExp(invalidFrenchCharacterPattern);
    return !regexpEmailAddress.test(params[0])
  }


  static validateUniqueEmail(emailToCompare, emails): boolean {
    return !emails.some(value => value.toLowerCase() === emailToCompare.toLowerCase())
  }

  static validateUniqueMainApplicantEmail(params) {
    // if 'applyingForSomeoneElseRelationship' then ignore validator
    if (params[3] === 'APPLICATION_OTHER') {
      return true;
    }

    const mainApplicantEmail = params[0]
    const spouseEmail = params[1]
    const emailsToCompare = params[2].map(child => child.childEmail)
    emailsToCompare.push(spouseEmail)

    // filter out emails that are undefined
    return SadaCustomValidator.validateUniqueEmail(mainApplicantEmail, emailsToCompare.filter(email => email))
  }

  static validateUniqueSpouseEmail(params) {
    // if 'applyingForSomeoneElseRelationship'
    // and submitted by public anonymous user (isAuthorizedUser is false) then ignore validator
    if (params[3] === 'APPLICATION_OTHER' && params[4] === false) {
      return true;
    }

    const spouseApplicantEmail = params[0]
    const mainApplicantEmail = params[1]
    const emailsToCompare = params[2].map(child => child.childEmail)
    emailsToCompare.push(mainApplicantEmail)

    // filter out emails that are undefined
    return SadaCustomValidator.validateUniqueEmail(spouseApplicantEmail, emailsToCompare.filter(email => email))
  }

  static validateUniqueChildEmail(params) {
    // if 'applyingForSomeoneElseRelationship'
    // and submitted by public anonymous user (isAuthorizedUser is false) then ignore validator
    if (params[4] === 'APPLICATION_OTHER' && params[5] === false) {
      return true;
    }

    const childEmail = params[0]
    const mainApplicantEmail = params[1]
    const spouseEmail = params[2]
    const children = params[3]

    if (!childEmail) { return true }

    const childrenWithSameEmail = children.filter(child => {
      let childEmailAddress;
      if (child instanceof FormGroup) {
        childEmailAddress = child.get('childEmail')?.value;
      } else {
        childEmailAddress = child.childEmail;
      }
      return childEmailAddress && childEmailAddress.toLowerCase() === childEmail.toLowerCase();
    })
    return ((!mainApplicantEmail || mainApplicantEmail.toLowerCase() !== childEmail.toLowerCase())) &&
      (!spouseEmail || spouseEmail.toLowerCase() !== childEmail.toLowerCase()) &&
      childrenWithSameEmail.length === 1; // children with the email includes yourself
  }

  static isFamilySponsored(params) {
    const spouseSponsored = params[0];
    const children = params[1];
    return 'yes' === spouseSponsored || (children != null && children.some(child => 'yes' === child.childSponsored));
  }

   static isTrusteeIdentified(applicationAnswers){
    return applicationAnswers.applyingForYourselfOrSomeoneElse === 'APPLICATION_OTHER' &&
      applicationAnswers.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified';
  }

  static validateCurrency(params): boolean {
    const regexpDomain = new RegExp('^-?([0-9]+)?([.]?[0-9]{0,2})?$')
    return regexpDomain.test(params[0])
  }

  // We validate non currency because Surveyjs does not have concept of short circuiting validator application
  static validateNonCurrencyOrNonNegativeCurrency(params): boolean {
    if (SadaCustomValidator.validateCurrency(params)) {
      return Number(params[0]) >= 0
    }
    return true
  }

  static validateNonZeroCurrency(params): boolean {
    if (SadaCustomValidator.validateCurrency(params)) {
      return Number(params[0]) !== 0
    }
    return false
  }

  static validateDateWithMin(params) {
    const date = params[0]
    const minDate = params[1]

    if (!date || !minDate) {
      return false
    }

    const trimmedDate = date.replace(/\s/g, '');
    const trimmedMinDate = minDate.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    const beforeMin = momentDate.isBefore(moment(trimmedMinDate, 'YYYY/MM/DD'))

    return !(beforeMin)
  }

  static validateDateWithMinAndFuture(params) {
    const date = params[0]
    const minDate = params[1]

    if (!date || !minDate) {
      return true
    }

    const trimmedDate = date.replace(/\s/g, '');
    const trimmedMinDate = minDate.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    const inFuture = momentDate.isAfter(moment())
    const beforeMin = momentDate.isBefore(moment(trimmedMinDate, 'YYYY/MM/DD'))

    return !(inFuture || beforeMin)
  }

  static validatePast(params) {
    const date = params[0]

    if (!date) {
      return false
    }

    const trimmedDate = date.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    return momentDate.isBefore(moment())
  }

  static isInPastExcludeToday(params) {
    const date = params[0]

    if (!date) {
      return false
    }

    const trimmedDate = date.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')

    const now = moment().startOf('day')

    return momentDate.isBefore(now)
  }

  static isValidDate(params, format='YYYY/MM/DD') {
    const date = params[0]
    if (!date) {
      return true
    }

    const trimmedDate = date.replace(/\s/g, '');
    const momentDate = moment(trimmedDate, format,true)
    return momentDate.isValid()
  }

  static isValidYearMonth(params, format='YYYY/MM') {
    const date = params[0]
    if (!date) {
      return true
    }

    const trimmedDate = date.replace(/\s/g, '');
    const momentDate = moment(trimmedDate, format,true)
    return momentDate.isValid()
  }

  static isFutureDate(dateStr: any): boolean {
    const trimmedDate = dateStr.replace(/\s/g, '');
    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    const now = moment();
    return momentDate.isAfter(now);
  }

  static isCurrentMonth(dateStr: string): boolean {
    const trimmedDate = dateStr.replace(/\s/g, '');
    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    return moment().year() === momentDate.year() && moment().month() === momentDate.month()
  }

  static isDateWithinDaysIncludeToday(params) {
    const date = params[0]
    const daysWithin = params[1]

    if (!date || !daysWithin) {
      return false
    }

    const trimmedDate = date.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    const now = moment().startOf('day')

    const isSameOrAfter = momentDate.isSameOrAfter(now)

    const daysFromNow = momentDate.diff(now, 'days');
    return isSameOrAfter && !(daysFromNow > daysWithin)
  }

  static validateDateWithinDays(params) {
    const date = params[0]
    const daysWithin = params[1]

    if (!date || !daysWithin) {
      return false
    }

    const trimmedDate = date.replace(/\s/g, '');

    const momentDate = moment(trimmedDate, 'YYYY/MM/DD')
    const now = moment();
    const inFuture = momentDate.isAfter(now)
    const daysFromNow = momentDate.diff(now, 'days');
    return inFuture && (daysFromNow < (daysWithin - 1))
  }

  static validateDateOfBirthWithDayAndMonth(params): boolean {
    const DOB: string = params[0]
    const [year, month, day] = DOB.split('/')
    const lengthDOB: number = [year, month, day].join('').length;
    const monthWith31Day = ['01', '03', '05', '07', '08', '10', '12']

    const monthPattern = new RegExp('^(1[0-2]|0[1-9])$')
    let dayPattern

    if (month === '02') {
      dayPattern = new RegExp('^(0[1-9]|[1-2][0-9])$')
    } else if (monthWith31Day.some(uidMonth => uidMonth === month)) {
      dayPattern = new RegExp('^(0[1-9]|[1-2][0-9])|3[0-1]$')
    } else {
      dayPattern = new RegExp('^(0[1-9]|[1-2][0-9])|3[0]$')
    }

    const isLengthValid: boolean = lengthDOB === 8 ? true : false
    const isMonthValid = monthPattern.test(month);
    const isDayValid = dayPattern.test(day)

    return isLengthValid && isMonthValid && isDayValid
  }

  static validateMemberID(params) {
    const memberID = params[0]
    if (!memberID) {
      return true;
    }
    const memberIdPattern = new RegExp('^\\d{9}$')
    return memberIdPattern.test(memberID)
  }

  static validateInstitutionName(params) {
    const institutionName = params[0]
    if (!institutionName) {
      return true;
    }
    const institutionNamePattern = new RegExp('^[a-zA-Z-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'. ]+$')
    return institutionNamePattern.test(institutionName)
  }

  static validateBankName(params) {
    const bankName = params[0]
    const bankNamePattern = new RegExp('^[-a-zA-ZÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'. ]+$')

    return bankNamePattern.test(bankName)
  }

  static validateBranchNumber(params) {
    const branchNum = params[0].trim();
    const branchNumPattern = new RegExp('^\\d{5}$')
    return branchNumPattern.test(branchNum)
  }

  static validateInstitutionNumber(params) {
    const institutionNum = params[0].trim();
    const institutionNumPattern = new RegExp('^\\d{3}$')
    return institutionNumPattern.test(institutionNum)
  }

  static validateAccountNumber(params) {
    const accountNum = params[0].trim();
    const accountNumPattern = new RegExp('^\\d{7,12}$')
    return accountNumPattern.test(accountNum)
  }

  static validateOtherPaymentDescription(params) {
    const otherPaymentDescription = params[0]
    const otherPaymentDescriptionPattern = new RegExp('^[-a-zA-Z-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'. ]{1,131}$')
    return otherPaymentDescriptionPattern.test(otherPaymentDescription)
  }

  static validateRefCodeFormat(params) {
    const appStatusNum = params[0]
    const refCodePattern = new RegExp('^[a-zA-Z0-9-]*$')
    return refCodePattern.test(appStatusNum)
  }

  static validateRequiredLength(params) {
    const text = params[0] ? params[0] + '' : ''

    const minLength = params[1]
    const maxLength = params[2]
    const required = params[3]

    const isBetweenChars = text.length >= minLength && text.length <= maxLength

    if (required === 'true') {
      return isBetweenChars
    } else {
      return !text || isBetweenChars
    }
  }

  /**
   * This logic validates the text has max length (excluding decimal-point) allowed.
   * @param params First entry is the validating text and the second entry is the max length allowed.
   */
  static validateMaxNumericValueLength(params): boolean {
    const text = params[0] ? params[0] + '' : '';
    const maxLength = params[1] ? params[1] : 10;

    const trimmedText = text.trim().replace(/\./g, '');
    return trimmedText.length <= maxLength;
  }

  static validateYearBefore1900Check(params): boolean {
    const text = params[0] ? params[0] + '' : '';
    const regex = params[1] ? params[1] : inValidVehicleModelYearPattern;
    const pattern = new RegExp(regex);
    return pattern.test(text);
  }

  /**
   * This is a validator wrapper of validateMaxNumericValueLength.
   * @param maxLength Max length allowed by this input control.
   */
  static maxNumericLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.validateMaxNumericValueLength([control?.value, maxLength]) ? null : {maxNumericLength: true};
    }
  }

  static vehicleYearBefore1900Check(regex: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !this.validateYearBefore1900Check([control?.value, regex]) ? null : {vehicleYearBefore1900Check: true};
    }
  }

  static isDateWithinPastMonths(momentDate: moment.Moment, start: number, end: number, additionalCondition?: boolean) {
    return !!additionalCondition ? additionalCondition && momentDate.isBetween(moment().subtract(end, 'months'), moment().subtract(start, 'months'))
      : momentDate.isBetween(moment().subtract(end, 'months'), moment().subtract(start, 'months'))
  }

  static isDateWithinPastMonthsOnCondition(momentDate: moment.Moment, start: number, end: number, additionalCondition: boolean) {
    return additionalCondition && momentDate.isBetween(moment().subtract(end, 'months'), moment().subtract(start, 'months'));
  }

  static isValidPregnancyDueDate(params) {
    const date = params[0];
    const maxWeeksFromToday = 40; // furthest allowable due date (40 weeks)

    const trimmedDate = date.replace(/\s/g, '');
    const momentDate = moment(trimmedDate, 'YYYY/MM/DD',true);
    const now = moment();
    const end = moment().add(maxWeeksFromToday, 'weeks');
    return momentDate.isBetween(now, end, 'day','[]');
  }

  static validateSameValueAsDropdown(params){
    const lowerCaseDropdownOptions = params[1].map(option => option.toLowerCase());
    return !lowerCaseDropdownOptions.includes(params[0].toLowerCase())
  }
  static validateDisabilityBasedOnFirstNation(disabilityResponseValue, livingOnFirstNationsReserveLand: string): boolean {
    return livingOnFirstNationsReserveLand === 'yes' ? disabilityResponseValue[0] === 'yes' : true;
  }

  static validateFirstNationBasedOnDisabilityBya(livingOnFirstNationsReserveLandValue: string, withDisability: string): boolean {
    return (livingOnFirstNationsReserveLandValue === 'yes' && withDisability === 'no') ? false : true;
  }

  static isFinancialIndependenceRequired(data: any): boolean {
    if (this.isSingleApplicant(data) && SadaCustomValidator.validateOverAge(data.dateOfBirth, 18) &&
      !!data.otherPersonsLivingWithYou && data.otherPersonsLivingWithYou.length > 0 &&
      (data.currentHousingSituation !== 'I own my home' && data.currentHousingSituation !==
        'I am staying in an emergency hostel or shelter')) {
      const parentsLivingWithApplicant = data.otherPersonsLivingWithYou?.filter(
        v => v.livingWithYouRelationship === 'Parent' && Number(v.livingWithYouPaymentAmount) === 0);
      const isOnlyParentsLivingWithApplicant = parentsLivingWithApplicant.length ===
        data.otherPersonsLivingWithYou?.length;
      return parentsLivingWithApplicant && isOnlyParentsLivingWithApplicant;
    }

    return false;
  }

  static isSingleApplicant(data: any): boolean {
    return (data?.childrenLivingWithYou === 'no'
      && data?.maritalStatus === 'Single');
  }
}

export const basicEmailAddressPattern = '\\S+@\\S+\\.\\S+';
// 66437-Functionality might be used in future if SAMS wants to support french characters for the email field
// export const emailAddressPattern = '^[ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûüA-Za-z0-9_-]+(?:\\.[ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûüA-Za-z0-9_-]+)*@(?:[ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûüA-Za-z0-9](?:[ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûüA-Za-z0-9-]*[ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûüA-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$';
export const emailAddressPattern = '^[A-Za-z0-9_-]+(?:\\.[A-Za-z0-9_-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$';
// 66437-Functionality might be used in future if SAMS wants to support french characters for the email field
// export const emailAddressSpecialCharactersPattern = '^[a-zA-Z0-9*àÀçÇèéêëÈÉÊËìîïÌÎÏòôÒÔùûüÙÛÜ#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+(?:[@._-][a-zA-Z0-9*àÀçÇèéêëÈÉÊËìîïÌÎÏòôÒÔùûüÙÛÜ#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+)*[a-zA-Z0-9*àÀçÇèéêëÈÉÊËìîïÌÎÏòôÒÔùûüÙÛÜ#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+(?:[@._-][a-zA-Z0-9*àÀçÇèéêëÈÉÊËìîïÌÎÏòôÒÔùûüÙÛÜ#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+)*$';
export const emailAddressSpecialCharactersPattern = '^[a-zA-Z0-9*#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+(?:[@._-][a-zA-Z0-9*#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+)*[a-zA-Z0-9*#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+(?:[@._-][a-zA-Z0-9*#{},\\\\\\[\\]\'\\/`~!":?&<>=()+;$%|^ ]+)*$';
export const invalidFrenchCharacterPattern = /[áâãäåæÁÂÃÄÅÆÍíóõöøðñÓÕÖØúÚýþÿÐÑÝÞŸ]/;
export const invalidNameWithOnlySpecialCharacter = /^[- ']+$/;



