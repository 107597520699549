import {Observable} from 'rxjs';
import {PcLookupResponse} from '../models/pc-lookup-response';
import {PcLookupRequest} from '../models/pc-lookup-request';

export abstract class AddressLookupService {
  abstract getAddressChoices(request: PcLookupRequest): Observable<PcLookupResponse>

  abstract getAddressChoicesAndPopulateCache(request: PcLookupRequest, isHomeAddressUpdate: boolean,
                                             isMailingAddressUpdated: boolean,
                                             isTrusteeAddressUpdated: boolean): Observable<PcLookupResponse>

  abstract getCachedAddressChoices(): Observable<PcLookupResponse>

  abstract clearCachedAddressChoices(): void

  abstract clearCachedMailingAddressChoices(): void

  abstract isCachedAddressChoicesAvailable()

}
