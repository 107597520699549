import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PcLookupResponse} from '../../models/pc-lookup-response';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Address} from '../../models/address';
import {ApplicationAnswers} from '../../models/data.model';
import {IntakeService} from '../../services/intake.service';
import {AddressUtil} from '../../utils/address-util';
import {take} from 'rxjs/operators';
import {AddressLookupService} from '../../services/address-lookup.service';
import {PostalService} from '../../services/postal.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {popup} from '../../dialog-constants';
import {ExternalRouter} from '../../external.router';
import {ButtonUtil} from '../../utils/button-util';
import {TranslationUtil} from '../../utils/translation-util';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../services/config.service';
import {BaseComponent} from '../BaseComponent';
import {Idle} from '@ng-idle/core';
import {Choice} from '../../common/utils/questions/question-choices';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';

@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-address.component.html',
  styleUrls: ['./confirm-address.component.scss']
})
export class ConfirmAddressComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  loaded = false;
  pageName: string
  data: PcLookupResponse
  addressChoicesForm: FormGroup
  existingApplication: ApplicationAnswers
  isTrusteeAddress=false;
  userEnteredHomeAddress: Address
  userEnteredMailingAddress: Address
  userEnteredTrusteeAddress: Address
  homeAddressChoices: Choice[];
  mailingAddressChoices: Choice[];
  trusteeAddressChoices: Choice[];
  oasaUrl: string;
  selectAnOptionHomeAddressError = false;
  selectAnOptionMailAddressError = false;
  selectAnOptionTrusteeAddressError = false;
  homeAddressInvalid = false;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private fb: FormBuilder,
              private addressLookupService: AddressLookupService,
              public intakeService: IntakeService,
              private postalService: PostalService,
              public ngZone: NgZone, public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              public translator: TranslateService,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.confirmAddress;
  }

  ngOnInit(): void {
    this.addressChoicesForm = this.fb.group({homeAddress: [], mailingAddress: [], trusteeAddress: []})
    this.oasaUrl = popup.beforeYouStart1.link[this.configService.getClientConfig()?.envName] || popup.beforeYouStart1.link.dev

    this.subscriptions$.push(

      this.route.data.subscribe((data: {appData: ApplicationAnswers}) => {
        this.existingApplication = data.appData;
        this.isTrusteeAddress =
          this.existingApplication?.jsonData?.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified';
        this.userEnteredHomeAddress = AddressUtil.constructHomeAddressFromAnswers(data.appData.jsonData)
        this.translateAddressForDisplay(this.userEnteredHomeAddress)
        this.userEnteredMailingAddress = AddressUtil.constructMailingAddressFromAnswers(data.appData.jsonData)
        this.translateAddressForDisplay(this.userEnteredMailingAddress)
        this.userEnteredTrusteeAddress = AddressUtil.constructTrusteeAddressFromAnswers(data.appData.jsonData)
        this.translateAddressForDisplay(this.userEnteredTrusteeAddress)
        this.addressLookupService.getCachedAddressChoices().pipe(take(1)).subscribe(choices => {
          this.homeAddressChoices = [];
          this.mailingAddressChoices = [];
          this.trusteeAddressChoices = [];
          if (choices.homeAddressChoices && choices.homeAddressChoices.length) {
            choices.homeAddressChoices.forEach((choice, index) => {
              choice.streetDisplayType = choice.streetType
              choice.streetDirectionDisplay = choice.streetDirection
              this.homeAddressChoices[index] = {value: choice, label: index.toString()};
            })
            const i = this.homeAddressChoices.length;
            this.homeAddressChoices[i] = {value: {text: 'confirm-address.userProvidedAddressText'}, label: i.toString()};
          }
          if (this.isTrusteeAddress && choices.mailingAddressChoices && choices.mailingAddressChoices.length) {
            choices.mailingAddressChoices.forEach((choice, index) => {
              choice.streetDisplayType = choice.streetType
              choice.streetDirectionDisplay = choice.streetDirection
              this.trusteeAddressChoices[index] = {value: choice, label: index.toString()};
            })
            const i = this.trusteeAddressChoices.length;
            this.trusteeAddressChoices[i] = {value: {text: 'confirm-address.userProvidedAddressText'}, label: i.toString()};
            // Reset Mailing if Trustee is present
            this.mailingAddressChoices = [];
          }
          if (choices.mailingAddressChoices && choices.mailingAddressChoices.length && !this.isTrusteeAddress) {
            choices.mailingAddressChoices.forEach((choice, index) => {
              choice.streetDisplayType = choice.streetType
              choice.streetDirectionDisplay = choice.streetDirection
              this.mailingAddressChoices[index] = {value: choice, label: index.toString()};
            })
            const i = this.mailingAddressChoices.length;
            this.mailingAddressChoices[i] = {value: {text: 'confirm-address.userProvidedAddressText'}, label: i.toString()};
          }
          this.loaded = true
        })
      }, error => this.loaded = true)
    );
  }

  ngOnDestroy(): void {
    super.onDestroy()
  }

  onSubmit() {
    ButtonUtil.toggleContinueButton(false, '#continue-button');
    const homeAddressFormControl = this.addressChoicesForm.get('homeAddress')
    const mailingAddressFormControl = this.addressChoicesForm.get('mailingAddress')
    const trusteeAddressFormControl = this.addressChoicesForm.get('trusteeAddress')
    if (homeAddressFormControl.valid && mailingAddressFormControl.valid && trusteeAddressFormControl.valid) {
      this.selectAnOptionHomeAddressError = false
      this.selectAnOptionMailAddressError = false
      this.selectAnOptionTrusteeAddressError = false
      if (homeAddressFormControl.value && this.homeAddressChoices.length - 1 !== Number(homeAddressFormControl.value)) {
        const address: Address = this.homeAddressChoices[homeAddressFormControl.value].value;
        this.postalService.validatePostalCode(address.postalCode, (result) => {
          if (!result || !result.validPostalCode) {
            this.homeAddressInvalid = true;
            homeAddressFormControl.setErrors({incorrect: true})
            this.openDialog(popup.beforeYouStart1)
            ButtonUtil.toggleContinueButton(true, '#continue-button');
          } else if (result.locationInEmergencySituation) {
            this.openDialog(popup.locationInEmergency)
            ButtonUtil.toggleContinueButton(true, '#continue-button');
          } else {
            this.saveAndNavigateToNextPage({
              ...this.getUserSelectedHomeAddressChoice(homeAddressFormControl),
              ...this.getUserSelectedMailingAddressChoice(mailingAddressFormControl),
              ...this.getUserSelectedTrusteeAddressChoice(trusteeAddressFormControl)
            })
          }
        })
      }
      else if(this.isTrusteeAddress)
      {
        this.saveAndNavigateToNextPage(this.getUserSelectedTrusteeAddressChoice(trusteeAddressFormControl))
      }
      else {
        this.saveAndNavigateToNextPage(this.getUserSelectedMailingAddressChoice(mailingAddressFormControl))
      }
    } else {
      if (!homeAddressFormControl.valid) {
        this.selectAnOptionHomeAddressError = true
      }
      if (!mailingAddressFormControl.valid) {
        this.selectAnOptionMailAddressError = true
      }
      if (!trusteeAddressFormControl.valid) {
        this.selectAnOptionTrusteeAddressError = true
      }
      ButtonUtil.toggleContinueButton(true, '#continue-button');
    }
  }

  private saveAndNavigateToNextPage(updatedAddressAnswers: any) {
    if (updatedAddressAnswers) {
      this.intakeService.saveApplication(
        {jsonData: {...this.existingApplication.jsonData, ...updatedAddressAnswers}}, this.pageId)
        .subscribe(() => {
          this.clearAddressCacheAndNavigateToNextPage()
        }, error => {
          ButtonUtil.toggleContinueButton(true, '#continue-button');
        });
    } else {
      this.clearAddressCacheAndNavigateToNextPage()
    }
  }

  private getUserSelectedHomeAddressChoice(homeAddressFormControl: AbstractControl) {
    if (homeAddressFormControl.value && this.homeAddressChoices.length - 1 !== Number(homeAddressFormControl.value)) {
      return AddressUtil.populateHomeAddressAnswers(
        this.homeAddressChoices[homeAddressFormControl.value].value);
    }
  }

  private getUserSelectedMailingAddressChoice(mailingAddressFormControl: AbstractControl) {
    if (mailingAddressFormControl.value && this.mailingAddressChoices.length - 1 !== Number(mailingAddressFormControl.value)) {
      return AddressUtil.populateMailingAddressAnswers(
        this.mailingAddressChoices[mailingAddressFormControl.value].value);
    }
  }

  private getUserSelectedTrusteeAddressChoice(trusteeAddressFormControl: AbstractControl) {
    if (trusteeAddressFormControl.value && this.trusteeAddressChoices.length - 1 !== Number(trusteeAddressFormControl.value)) {
      return AddressUtil.populateTrusteeAddressAnswers(
        this.trusteeAddressChoices[trusteeAddressFormControl.value].value);
    }
  }

  private clearAddressCacheAndNavigateToNextPage() {
    this.addressLookupService.clearCachedAddressChoices()
    this.router.navigate(['/', 'intake', PageInfo.housingSituation])
  }

  private translateAddressForDisplay(addressIn: Address) {
    if (!addressIn) {
      return
    }
    if (addressIn.streetDisplayType) {
      addressIn.streetDisplayType = TranslationUtil.translateText(addressIn.streetDisplayType, this.translator)
    }
    if (addressIn.streetDirectionDisplay) {
      addressIn.streetDirectionDisplay = TranslationUtil.translateText(addressIn.streetDirection, this.translator)
    }
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    document.getElementById('ontario-logo')?.focus();
  }
}
