<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required ontario-padding-top-12-!">
      <sd-dynamic-container [showError]="showError" [id]="'housing-situation.panel'"
                        [questions]="housingSituationQuestions">
      </sd-dynamic-container>

      <sd-living-with-you-panel [data]="applicationAnswers" [showError]="showError"></sd-living-with-you-panel>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
