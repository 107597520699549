import {CaseWorkerAuthorizeService} from './caseWorkerAuthorizeService';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CaseWorkerAuthorizeServiceImpl implements CaseWorkerAuthorizeService {
  private _displayNameSource = new Subject<string>();
  private _displayName$ = this._displayNameSource.asObservable();
  claims:any;

  constructor(private msalService: MsalService, public configService: ConfigService) {
    this.claims = msalService.instance.getActiveAccount()?.idTokenClaims;
  }

  setClaims(claims: any): void{
    this.claims = claims;
    if (!this.isAuthorized()) {
      // Reset the name
      this._displayNameSource.next('');
    }
  }

  isIBauUser(): boolean {
    const matchingRoles: string[] = this.claims?.roles?.filter(
      e => (!!this.configService.getClientConfig().caseWorkerRoles &&
        this.configService.getClientConfig().caseWorkerRoles?.indexOf(e) !== -1));
    return matchingRoles?.length > 0;
  }

  // check if user has authorized roles to access SADA application
  isAuthorized(): boolean {
    if (this.isIBauUser()) {
      this._displayNameSource.next(this.getFirstName().concat(' ') + this.getLastName());
      return true;
    } else {
      return false;
    }

  }

  getDisplayName(): Observable<string> {
    return this._displayName$;
  }

  getCaseWorker(): any {
    return {
      caseWorkerFirstName: this.getFirstName(),
      caseWorkerLastName: this.getLastName(),
      userName: this.claims.preferred_username,
      roles: this.claims.roles,
      ministry: this.getMinistry()
    }
  }

  getFirstName(): string {
    const index = this.claims.name.indexOf(',');
    if (this.claims.name.indexOf('(') > 0 ) {
      return this.claims.name.substring(index + 1, this.claims.name.indexOf('(')).trim()
    } else {
      return this.claims.name.substring(index + 1).trim();
    }
  }

  getLastName(): string {
    const index = this.claims.name.indexOf(',');
    return this.claims.name.substring(0,index);
  }

  getMinistry() {
    const matches = [...this.claims.name.matchAll(/\(([^)]+)\)/g)]
    if (!!matches && matches.length > 0 && !!matches[matches.length - 1]) {
      return matches[matches.length - 1][1];
    }
    return '';
  }

  syncAuthorization(): void {
    // Dont sync if enableClaimTokenStub is true as only mock token will be used
    if (!this.configService.getClientConfig()?.enableClaimTokenStub) {
      this.setClaims(this.msalService.instance.getActiveAccount()?.idTokenClaims);
    }
  }
}
