<div class="form-group" [formGroup]="form" *ngIf="!isAddressSame || isTrusteeAddress">
  <div class="divider ontario-margin-top-32-!" aria-hidden="true"></div>
  <h2 class="ontario-h2 ontario-margin-top-32-!">
    {{ getAddressTitleKey() | translate }}
  </h2>
  <div *ngIf= "isTrusteeAddress" class="ontario-alert ontario-alert&#45;&#45;informational trustee-info">
              <div class="ontario-alert__header">
                <h2 id="trustee-info-header" class="ontario-alert__header-title ontario-h4" [innerHTML]="'address-information.trustee.banner.title' | translate">
                </h2>
              </div>
              <div>
                <p id="trustee-info-content" [innerHTML]="'address-information.trustee.banner.text' | translate"></p>
              </div>
  </div>
  <sd-alert-err
    *ngIf="(mailingAddressStreetNoMismatch && !isAddressSame) || trusteeAddressStreetNoMismatch"
    [errorId]="mailingAddressStreetNoMismatch ?
              'address-information.mailing-addr-street-no-mismatch' :
              'address-information.trustee-addr-street-no-mismatch'"
    [errorMessage]="'address-information.home-addr-street-no-mismatch' | translate"
    [isInnerHtml]="'true'">
  </sd-alert-err>
  <sd-dropdown-question
    [choices]="deliveryTypeChoices" [showError]="showError"
    [id]="getId('DeliveryType')"
    [required]="true"
    [controlName]="getControlName('DeliveryType')">
  </sd-dropdown-question>
  <sd-text-question *ngIf="addressDeliveryType === 'Rural route'"
                    [showError]="showError"
                    [id]="getId('RuralRoute')"
                    [validationFns]="ruralRouteValidationFunction"
                    [minLength]=1 [maxLength]=60 [capitalizeFirstLetter]=true
                    [characterWidth]=20
                    [required]="addressDeliveryType === 'Rural route'"
                    [controlName]="getControlName('RuralRoute')">
  </sd-text-question>
  <sd-text-question *ngIf="addressDeliveryType === 'PO Box'"
                    [showError]="showError"
                    [id]="getId('PoBox')"
                    [validationFns]="poBoxValidationFunction"
                    [minLength]=1 [maxLength]=60 [capitalizeFirstLetter]=true
                    [required]="addressDeliveryType === 'PO Box'"
                    [controlName]="getControlName('PoBox')">
  </sd-text-question>
  <sd-text-question *ngIf="addressDeliveryType === 'PO Box'"
                    [showError]="showError"
                    [id]="getId('Station')"
                    [validationFns]="stationValidationFunction"
                    [minLength]=1 [maxLength]=60 [capitalizeFirstLetter]=true
                    [required]="addressDeliveryType === 'PO Box'"
                    [controlName]="getControlName('Station')">
  </sd-text-question>
  <sd-text-question *ngIf="addressDeliveryType === 'General delivery'"
                    [showError]="showError"
                    [id]="getId('GeneralDelivery')"
                    [validationFns]="generalDeliveryValidationFunction"
                    [capitalizeFirstLetter]=true
                    [required]="addressDeliveryType === 'General delivery'"
                    [controlName]="getControlName('GeneralDelivery')">
  </sd-text-question>
  <sd-text-question *ngIf="addressDeliveryType !== 'General delivery'"
                    [showError]="showError" [id]="getId('ApartmentNumber')"
                    [validationFns]="apartmentNumberValidationFunction"
                    [characterWidth]=20
                    [minLength]=1 [maxLength]=50 [capitalizeFirstLetter]=true
                    [controlName]="getControlName('ApartmentNumber')">
  </sd-text-question>
  <sd-text-question *ngIf="addressDeliveryType !== 'General delivery'"
                    [showError]="showError" [id]="getId('StreetNumber')"
                    [validationFns]="streetNumberValidationFunction"
                    [characterWidth]=20
                    [minLength]=1 [maxLength]=60 [capitalizeFirstLetter]=true
                    [required]="addressDeliveryType === 'Standard street address'"
                    [controlName]="getControlName('StreetNumber')">
  </sd-text-question>
  <sd-dropdown-question *ngIf="addressDeliveryType !== 'General delivery'"
                        [choices]="streetNumberSuffixChoices" [showError]="showError"
                        [id]="getId('StreetNumberSuffix')"
                        [required]="false"
                        [controlName]="getControlName('StreetNumberSuffix')">
  </sd-dropdown-question>
  <sd-text-question *ngIf="addressDeliveryType !== 'General delivery'"
                    [showError]="showError" [id]="getId('StreetName')"
                    [validationFns]="streetNameValidationFunction"
                    [characterWidth]=20
                    [minLength]=1 [maxLength]=60 [capitalizeFirstLetter]=true
                    [required]="addressDeliveryType === 'Standard street address'"
                    [controlName]="getControlName('StreetName')">
  </sd-text-question>
  <sd-dropdown-question *ngIf="addressDeliveryType !== 'General delivery'"
                        [choices]="streetTypeSuffixChoices" [showError]="showError"
                        [id]="getId('StreetType')"
                        [required]="addressDeliveryType === 'Standard street address'"
                        [controlName]="getControlName('StreetType')">
  </sd-dropdown-question>
  <sd-dropdown-question *ngIf="addressDeliveryType !== 'General delivery'"
                        [choices]="directionChoices" [showError]="showError"
                        [id]="getId('Direction')"
                        [controlName]="getControlName('Direction')">
  </sd-dropdown-question>
  <sd-text-question
    [showError]="showError" [id]="getId('CityOrTown')"
    [validationFns]="cityOrTownValidationFunction"
    [characterWidth]=20
    [minLength]=1 [maxLength]=50 [capitalizeFirstLetter]=true
    [required]="true"
    [controlName]="getControlName('CityOrTown')">
  </sd-text-question>
  <sd-dropdown-question
    [choices]="mailingAddressProvinceChoice" [showError]="showError"
    [id]="getId('Province')"
    [required]="true"
    [controlName]="getControlName('Province')">
  </sd-dropdown-question>
  <sd-text-question
    [showError]="showError"
    [id]="getId('PostalCode')"
    [validationRegex]="postalCodeRegex" [validationRegexError]="'error.invalid.postalCode'"
    [capitalize]=true [required]="true"
    [characterWidth]=10
    hintText="address-information.postalCode.hintText"
    hintExpandableContent="address-information.postalCode.expandableHintText"
    [hintExpandableContentParam]="postalCodeFinderLinkParam"
    [controlName]="getControlName('PostalCode')">
  </sd-text-question>
</div>
