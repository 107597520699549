<div attr.name="{{fieldName}}" class="ontario-form-group {{required ? 'required' : ''}}" [formGroup]="form">
  <div>
    <label *ngIf="labelTitle" class="ontario-label" id="{{labelTitle}}">{{labelTitle | translate}}</label>
    <div *ngIf="labelDescription" class="ontario-margin-bottom-16-!">
      <span id="labelDescription">{{labelDescription | translate}}</span>
    </div>

    <label class="ontario-label" id="{{id}}" attr.for="{{getElementId()}}">
      <span *ngIf="!!label; else default_content">{{label | translate : labelParam}}</span>
      <ng-template #default_content>{{id | translate : labelParam}}</ng-template>
      <span class="ontario-label__flag">
        {{(required ? 'Required' : 'Optional') | translate}}
      </span>
    </label>
    <sd-hint-text *ngIf="hintText && !hintExpandableContent"
                  id="{{id}}-question-hintText"
                  [occurrence]="labelParam?.index"
                  [text]="hintText">
    </sd-hint-text>
  </div>

  <div *ngIf="description" class="ontario-margin-bottom-16-!">
    <span id="descriptionText">{{description | translate}}</span>
  </div>

  <sd-alert-err *ngIf="showError && formControl.hasError('required')"
                [errorId]="id.concat('-required-err')" [errorMessage]="'error.required' | translate">
  </sd-alert-err>
  <sd-alert-err *ngIf="showCustomError"
                [errorId]="id.concat('-custom-err')" [errorMessage]="showCustomError | translate: customErrorParam" [isInnerHtml]="'true'">
  </sd-alert-err>
  <sd-alert-err *ngIf="!formControl.hasError('required') && formControl.errors?.customErrorKey && formControl.touched"
      [errorId]="id.concat('-custom-err-key')" [errorMessage]="getErrorMessage(formControl.errors?.customErrorKey)" [isInnerHtml]="'true'">
  </sd-alert-err>

  <select id="{{getElementId()}}"
          class="ontario-input ontario-dropdown" [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
          [formControlName]="controlName"
          [attr.aria-disabled]="disabled ? true : null"
          attr.aria-describedby="{{id}}-err {{id}}-custom-err"
          attr.aria-required="{{required}}">
    <option value="" selected>{{'btn.choose' | translate}}</option>
    <option [value]="choice.value" *ngFor="let choice of choices">{{choice.label | translate}}</option>
  </select>
  <sd-hint-text *ngIf="hintText && hintExpandableContent"
                id="{{id}}-question-hintText"
                [occurrence]="labelParam?.index"
                [text]="hintText"
                [expandableContent]="hintExpandableContent"
                [expandableContentParam]="hintExpandableContentParam">
  </sd-hint-text>
</div>
