import {Component, Input} from '@angular/core';
import {amountMaxLength, positiveCurrencyPattern} from '../../../common/utils/validators/custom-validators';
import {ControlContainer, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {Choice} from '../../../common/utils/questions/question-choices';
import {validateUniqueLivingWithFirstLastNames} from '../housing-situation-alert-validators';
import {SharedUtil} from '@shared/shared.util';

@Component({
  selector: 'sd-living-with-you-panel',
  templateUrl: './living-with-you-panel.component.html',
  styleUrls: ['./living-with-you-panel.component.scss']
})
export class LivingWithYouPanelComponent {

  constructor(public controlContainer: ControlContainer, public fb: FormBuilder) {
  }

  get anyoneElseLivingBesidesFamily() {
    return this.form?.controls.anyoneElseLivingBesidesFamily;
  }

  get otherPersonsLivingWithYouFormArray() {
    return this.form.get('otherPersonsLivingWithYou') as FormArray;
  }

  @Input()
  data: any;

  @Input()
  showError = false;

  livingWithYouRelationshipChoices: Choice[];

  form: FormGroup;

  protected valueChangeSubjects$ = new Subject<void>();

  protected readonly positiveCurrencyPattern = positiveCurrencyPattern;

  firstNameValidationFns = [
    {
      validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
      errorKey: 'error.invalid.firstNameWithOnlyCharacters'
    },
    {
      validationFunction: SadaCustomValidator.validateName,
      errorKey: 'housing-situation.error.invalid.livingWithYouFirstName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }
  ];

  lastNameValidationFns = [
    {
      validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
      errorKey: 'error.invalid.lastNameWithOnlyCharacters'
    },
    {
      validationFunction: SadaCustomValidator.validateName,
      errorKey: 'housing-situation.error.invalid.livingWithYouLastName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }];

  livingWithYouDateValidationFns = [
    // This validation has to be in this order, to override the previous validation error.
    {
      validationFunction: (value: []) => {
        return SadaCustomValidator.isValidDate(value);
      },
      errorKey: 'housing-situation.error.invalid.livingWithYouDate'
    },
    {
      validationFunction: (value: []) => {
        return SadaCustomValidator.isValidDate(value) && // Needs chained validations to avoid taking invalid date.
          SadaCustomValidator.validateDateWithMinAndFuture([...value, this.data.jsonData.dateOfBirth])
      },
      errorKey: 'housing-situation.error.invalid.livingWithYouDate'
    }
  ];

  accountValueValidationFns = [
    {
      validationFunction: (value: any[]) => {
        return isNaN(value[0]) || (!isNaN(value[0]) && value[0] >= 0);
      },
      errorKey: 'error.invalid.min.amount'
    },
    {
      validationFunction: (value: []) => {
        return SadaCustomValidator.validateMaxNumericValueLength([...value, amountMaxLength]);
      },
      errorKey: 'error.invalid.max.amount'
    },
  ];

  protected readonly validateUniqueLivingWithFirstLastNames = validateUniqueLivingWithFirstLastNames;

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup;
    this.setupPersonLivingWithYouForm();
    this.anyoneElseLivingBesidesFamily.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.handleOtherPersonLivingWithYouPanel(value);
    });

    this.form?.controls.currentHousingSituation?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
          const updatedChoices = SharedUtil.getLivingWithYouRelationshipChoices(value);
          const bothChoicesUndefined = !this.livingWithYouRelationshipChoices && !updatedChoices;
          const choicesHaveChanged = !bothChoicesUndefined && (
              !this.livingWithYouRelationshipChoices || !updatedChoices
              || this.livingWithYouRelationshipChoices.length !== updatedChoices.length
              || !this.livingWithYouRelationshipChoices.every ((v, index) => v.value === updatedChoices[index].value)
            );

          if (choicesHaveChanged) {
            this.livingWithYouRelationshipChoices = updatedChoices;
            this.otherPersonsLivingWithYouFormArray.clear();
            this.otherPersonsLivingWithYouFormArray.disable();
            if (value !== 'I am staying in an emergency hostel or shelter' && this.anyoneElseLivingBesidesFamily.value === 'yes') {
              this.addOtherPersonLivingWithYou();
            }
          }
      });
  }

  private setupPersonLivingWithYouForm() {
    if (this.data.jsonData.currentHousingSituation) {
      this.livingWithYouRelationshipChoices = SharedUtil.getLivingWithYouRelationshipChoices(this.data.jsonData.currentHousingSituation);
    }
    this.form.addControl('otherPersonsLivingWithYou', this.fb.array([]))
    this.data.jsonData.otherPersonsLivingWithYou?.forEach(person => {
      this.addOtherPersonLivingWithYou(false, person);
    });
  }

  handleOtherPersonLivingWithYouPanel(value) {
    if ('yes' === value) {
      this.addOtherPersonLivingWithYou(true);
    } else {
      this.otherPersonsLivingWithYouFormArray?.controls.forEach(personFG => {
        const fg = personFG as FormGroup;
        Object.keys(fg.controls).forEach(key => {
          fg.get(key).disable();
        })
      })
    }
  }

  handleRelationShipValueChange(value: string, formGroup: FormGroup) {
    if ('' === value) {
      formGroup.get('livingWithYouFirstName')?.disable();
      formGroup.get('livingWithYouLastName')?.disable();
      formGroup.get('livingWithYouDate')?.disable();
      formGroup.get('livingWithYouPaymentAmount')?.disable();
      formGroup.get('livingWithYouProvideMeals')?.disable();
    } else {

      formGroup.get('livingWithYouFirstName')?.enable();
      formGroup.get('livingWithYouLastName')?.enable();
      formGroup.get('livingWithYouDate')?.enable();

      if (SharedUtil.shouldHideMealsAndPaymentAmountFieldsInHs(this.currentHousingSituation, value)) {
        formGroup.get('livingWithYouProvideMeals')?.disable();
        formGroup.get('livingWithYouPaymentAmount')?.disable();
      } else {
        formGroup.get('livingWithYouProvideMeals')?.enable();
        formGroup.get('livingWithYouPaymentAmount')?.enable();
      }
    }
  }

  isFieldVisible(controlName: string, index: number) {
    return this.form.get('otherPersonsLivingWithYou.' + index + '.' + controlName)?.enabled;
  }

  addOtherPersonLivingWithYou(addOnlyIfEmpty = false, personData?: any) {
    const formGroup = this.fb.group({
        livingWithYouRelationship: [''],
        livingWithYouFirstName: [],
        livingWithYouLastName: [],
        livingWithYouDate: [],
        livingWithYouPaymentAmount: [],
        livingWithYouProvideMeals: []
      })
    ;
    formGroup.get('livingWithYouRelationship').valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.handleRelationShipValueChange(value, formGroup);
      });

    if (!!personData) {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.get(controlName)?.setValue(personData?.[controlName]);
      });

      if (!SharedUtil.isValidLivingWithYouRelationship(this.currentHousingSituation, personData.livingWithYouRelationship)) {
        formGroup.get('livingWithYouRelationship')?.setValue('');
      }
    }

    if (addOnlyIfEmpty) {
      if (this.otherPersonsLivingWithYouFormArray.length === 0) {
        this.otherPersonsLivingWithYouFormArray.push(formGroup);
      } else if (this.otherPersonsLivingWithYouFormArray.disabled) {
        this.otherPersonsLivingWithYouFormArray.enable()
      }
    } else {
      this.otherPersonsLivingWithYouFormArray.push(formGroup);
    }

    // Remove focus from add income button
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  removeOtherPersonLivingWithYou(index: number) {
    this.otherPersonsLivingWithYouFormArray.removeAt(index);
  }

  getLivingWithYouProvideMealsLabelParams(index: number) {
    const firstName = this.otherPersonsLivingWithYouFormArray.at(index)?.get('livingWithYouFirstName')?.value;
    const lastName = this.otherPersonsLivingWithYouFormArray.at(index)?.get('livingWithYouLastName')?.value;

    return {
      param0: !!firstName ? firstName: '',
      param1: !!lastName ? lastName : ''
    };
  }

  get currentHousingSituation() {
    return this.form.get('currentHousingSituation')?.value;
  }
}
