<div class="form-group" [formGroup]="form" *ngIf="anyoneElseLivingBesidesFamily?.value === 'yes' && livingWithYouRelationshipChoices">
  <div formArrayName="otherPersonsLivingWithYou" class="panel-container" id="OtherPersonsLivingWithYouPanel">
    <h2 class="ontario-h3">
      <span>{{'housing-situation.additionalPeopleLivingWith' | translate}}</span>
    </h2>
    <ng-container *ngFor="let person of otherPersonsLivingWithYouFormArray.controls; let index=index">

      <div [formGroupName]="index" class="inner-card">
        <div
            class="ontario-h4">{{'housing-situation.otherPersonsLivingWithYou' | translate: {index: (index + 1)} }}</div>

        <div *ngIf="!validateUniqueLivingWithFirstLastNames(person, data)" class="alert mccssWarning">
          <span id="OtherPersonsLivingWithYouPanel{{index}}-alert" role="alert">{{'housing-situation.error.firstLastName.alreadyExists' | translate}}</span>
        </div>

        <div class="form-group">
          <sd-dropdown-question [choices]="livingWithYouRelationshipChoices" [showError]="showError"
                                id="OtherPersonsLivingWithYouPanel_livingWithYouRelationship{{index}}_select"
                                label='housing-situation.livingWithYouRelationship'
                                [required]="true"
                                controlName='livingWithYouRelationship'>
          </sd-dropdown-question>
          <sd-text-question *ngIf="isFieldVisible('livingWithYouFirstName', index)"
                            [showError]="showError" id="OtherPersonsLivingWithYouPanel_livingWithYouFirstName{{index}}_input"
                            [label]="'housing-situation.livingWithYouFirstName'"
                            [required]="true"
                            [validationFns]="firstNameValidationFns" [capitalizeFirstLetter]=true
                            [minLength]=1 [maxLength]=65
                            [characterWidth]=20 [truncateMultipleSpaces]="true"
                            controlName="livingWithYouFirstName">
          </sd-text-question>
          <sd-text-question *ngIf="isFieldVisible('livingWithYouLastName', index)"
                            [showError]="showError" id="OtherPersonsLivingWithYouPanel_livingWithYouLastName{{index}}_input"
                            [label]="'housing-situation.livingWithYouLastName'"
                            [required]="true"
                            [validationFns]="lastNameValidationFns" [capitalizeFirstLetter]=true
                            [minLength]=1 [maxLength]=65
                            [characterWidth]=20 [truncateMultipleSpaces]="true"
                            controlName="livingWithYouLastName">
          </sd-text-question>
          <sd-date *ngIf="isFieldVisible('livingWithYouDate', index)" [showError]="showError"
                   id="OtherPersonsLivingWithYouPanel_livingWithYouDate{{index}}"
                   label="housing-situation.livingWithYouDate"
                   controlNameDate="livingWithYouDate"
                   [validationFns]="livingWithYouDateValidationFns"
                   [needMarginTop]=true
                   [showDay]=true
                   [required]=true>
          </sd-date>
          <sd-text-question *ngIf="isFieldVisible('livingWithYouPaymentAmount', index)" [showError]="showError"
                            id="OtherPersonsLivingWithYouPanel_livingWithYouPaymentAmount{{index}}_input"
                            label="housing-situation.livingWithYouPaymentAmount"
                            hintText="housing-situation.livingWithYouPaymentAmount.hintText"
                            [characterWidth]="10"
                            [required]=true
                            [isDollarValue]=true
                            [validationRegex]="positiveCurrencyPattern"
                            [validationRegexError]="'error.invalid.currency.amount'"
                            [validationFns]="accountValueValidationFns"
                            controlName='livingWithYouPaymentAmount'>
          </sd-text-question>
          <!-- TBD - Context param-->
          <sd-radio-question *ngIf="isFieldVisible('livingWithYouProvideMeals', index)" [showError]="showError"
                             id="livingWithYouProvideMeals-radio-button-option-{{index}}"
                             label="housing-situation.livingWithYouProvideMeals"
                             [labelParam]="getLivingWithYouProvideMealsLabelParams(index)"
                             [context]="'housing-situation.livingWithYouProvideMeals.context'"
                             [required]="true"
                             controlName="livingWithYouProvideMeals">
          </sd-radio-question>
        </div>
        <button *ngIf="otherPersonsLivingWithYouFormArray.length > 1"
                [id]="'remove-panel-' + index"
                class="ontario-button ontario-button--tertiary" type="button"
                (click)="removeOtherPersonLivingWithYou(index)">{{'housing-situation.remove.person' | translate}}</button>
      </div>
    </ng-container>

    <button id="'add-panel-'" class="ontario-button ontario-button--secondary"
            type="button" (click)="addOtherPersonLivingWithYou()">
      <span>{{'housing-situation.add.other.person' | translate}}</span>
    </button>
  </div>
</div>
