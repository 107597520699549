import {AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {ExternalRouter} from '../../external.router';
import {Question} from '../../common/utils/questions/question';
import {HousingSituationQuestionService} from './housing-situation-question.service';
import {AppDataToQuestionsConverter, FormDataToAppDataConverter} from './housing-situation-mapper';
import {Observable, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApplicationAnswers} from '../../models/data.model';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {positiveCurrencyPattern} from '../../common/utils/validators/custom-validators';

@Component({
  selector: 'sd-housing-situation',
  templateUrl: './housing-situation.component.html',
  styleUrls: ['./housing-situation.component.scss']
})
export class HousingSituationComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  toContinue: boolean;
  showError = false
  questions: Question<string>[];
  positiveCurrencyPattern = positiveCurrencyPattern;

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              private questionService: HousingSituationQuestionService,
              private qcs: QuestionControlService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.housingSituation;
  }

  ngOnInit(): void {
    this.preInitializeForm().pipe(takeUntil(this._destroyed$)).subscribe((data: {appData: ApplicationAnswers}) => {
      if (data && data.appData?.jsonData) {
        this.applicationAnswers = data.appData;
        this.questions = [...this.questionService.getHousingSituationQuestions(this.applicationAnswers)];
        this.form = this.qcs.toFormGroup(this.questions);
        this.initializeForm();
        new AppDataToQuestionsConverter().convert(this.applicationAnswers, this.form, this.questionService, this.qcs)

        this.form?.controls.currentHousingSituation.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
          this.onCurrentHousingSituationChange(value)
        });
      }
    });
  }

  private preInitializeForm(): Observable<any> {
    return this.route.data;
  }

  onCurrentHousingSituationChange(value: any) {
    if (value === 'I am staying in an emergency hostel or shelter') {
      this.form.get('anyoneElseLivingBesidesFamily')?.disable();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  postInitializeForm() {
  }

  preSaveApplication() {
    new FormDataToAppDataConverter().convert(this.form, this.applicationAnswers)
    const {jsonData: {monthlyHousingCostForOwner}} = this.applicationAnswers;

    if(monthlyHousingCostForOwner) {
      this.applicationAnswers.jsonData.monthlyHousingCost = monthlyHousingCostForOwner;
    }

    if(!this.isFinancialIndependenceRequired()) {
      this.removeFinancialIndependenceData();
    }
  }

  onSubmit(toContinue: boolean): void {
    this.toContinue = toContinue;
    this.showRequiredInfoBanner = false;
    if (this.form.valid) {
      // this.logFormControlsValidStatus()
      this.showError = false;
      this.buttonContinueClick = true
      // call Intake service to save new application
      this.saveForm(toContinue)?.subscribe(x => {
        if (toContinue) {
          this.navigateToNextPage()
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }, error => {
        this.buttonContinueClick = false
        return of(error)
      })
    } else {
      // this.logFormControlsValidStatus()
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue);
    }
  }

  saveForm(toContinue: boolean): Observable<void> | undefined {
    const answers = {};

    // @ts-ignore
    Object.keys(this.form.controls).forEach(key => {
      const value = this.form?.get(key)?.value;
      if (value !== undefined && value.panels === undefined) {
        if (value === null || value === '' || this.form?.get(key)?.disabled) {
          // Need to remove the attr from the json data so that it wont be saved with previous value
          delete this.applicationAnswers.jsonData[key]
        } else {
          answers[key] = this.form?.get(key).value;
        }
      }
    });

    this.applicationAnswers.jsonData = {...this.applicationAnswers.jsonData, ...answers};

    this.preSaveApplication();

    if (this.authService.isAuthorizedToSave()) {
      return this.intakeService.saveToResume(this.applicationAnswers, this.pageId);
    } else {
      return this.intakeService.saveApplication(this.applicationAnswers, this.pageId);
    }
  }

  private navigateToNextPage() {
    if (this.isFinancialIndependenceRequired()) {
      this.intakeService.setFinIndependencePageRequired(true);
      this.router.navigate(['/', 'intake', PageInfo.financialIndependence]);
    } else {
      this.intakeService.setFinIndependencePageRequired(false);
      this.router.navigate(['/', 'intake', PageInfo.earnedIncome]);
    }
  }

  private isFinancialIndependenceRequired(): boolean {
    return SadaCustomValidator.isFinancialIndependenceRequired(this.applicationAnswers.jsonData);
  }

  private removeFinancialIndependenceData() {
    delete this.applicationAnswers.jsonData.outOfHighSchoolForFiveYears;
    delete this.applicationAnswers.jsonData.haveDiplomaOrDegree;
    delete this.applicationAnswers.jsonData.receivedSoleSupportStudentAssistance;
  }

  get housingSituationQuestions(): Question<string>[] {
    return this.questions;
  }

  get currentHousingSituation() {
    return this.form.get('currentHousingSituation')?.value;
  }

  get anyoneElseLivingBesidesFamily() {
    return this.form.get('anyoneElseLivingBesidesFamily')?.value;
  }
}
