import {
  applyingFor,
  bankAccountTypeChoices,
  bankNameChoices,
  communicationCheckboxChoices,
  contactMethodChoices,
  deliveryTypeChoices,
  developmentServicesOntarioChoices,
  directionChoices,
  hearingCheckboxChoices,
  householdOtherIncomeTypeChoices,
  housingCostChoices,
  housingSituationChoices,
  incomeChoices,
  institutionChoices,
  language,
  livingWithYouRelationshipChoices,
  maritalStatusChoices,
  mobilityCheckboxChoices, NeedTrustee,
  otherAssetTypeChoices,
  phoneTimeChoices,
  pregnantCheckboxChoices,
  provinceChoices,
  radioChildCareType,
  radioDefault,
  radioEarnedIncomeType,
  ReceivedSocialAssistanceProgram,
  relationship,
  schoolTypeChoices,
  sexAtBirth,
  statusInCanadaChoices,
  stayPartTimeOrFullTimeChoices,
  streetTypeChoices,
  vehicleMakeChoices,
  verbalCheckboxChoices,
  visualCheckboxChoices
} from './choices';
import {ApplicationAnswers} from '../../../models/data.model';
import {
  StatusInCanadaComponentUtil
} from '../../../common/status-in-canada-questions/util/status-in-canada-component.util';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {Page, Panel} from './page';
import {SharedUtil} from '@shared/shared.util';
import {getCurrentMonthAsString, getCurrentYear} from '../../../utils/date-util';
import {PageInfo} from 'src/app/models/page-map';
import {getChildrenYoungerThan18} from '../../earned-income/earned-income.util';
import {enableReceivingACSDFamilyFn} from '../../additional-information/family-information-question.service';

export const reviewQuestions: Page[] = [
  {
    name: PageInfo.bya,
    title: 'start-two.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error => error.field === 'applyingForYourselfOrSomeoneElse'))
      || !!(applicationValidationErrors.find(error=>error.field === 'trusteeFirstName'))
      || !!(applicationValidationErrors.find(error=>error.field === 'trusteeLastName'))
      || !!(applicationValidationErrors.find(error=>error.field === 'trusteePhone'))
      || !!(applicationValidationErrors.find(error=>error.field === 'trusteeEmail'))
      || !!(applicationValidationErrors.find(error=>error.field === 'trusteeContactLanguage'))
      || !!(applicationValidationErrors.find(error=>error.field === 'firstNationsName'))
      || !!(applicationValidationErrors.find(error=>error.field === 'applyingForSomeoneElseRelationshipNeedTrustee'))
      || !!(applicationValidationErrors.find(error=>error.field === 'applyingForSomeoneElseRelationship'))
      || !!(applicationValidationErrors.find(error=>error.field === 'statusInCanada')) : false
      || (!!applicationAnswers.jsonData.dateOfReleaseFromInstitution
        && (SadaCustomValidator.isInPastExcludeToday([applicationAnswers.jsonData.dateOfReleaseFromInstitution]) ||
          !SadaCustomValidator.isDateWithinDaysIncludeToday([applicationAnswers.jsonData.dateOfReleaseFromInstitution, '10'])))
      || (!!applicationAnswers.jsonData.receivingMoneyForReason && 'yes' === applicationAnswers.jsonData.receivingMoneyForReason.toLowerCase()),
    questions: [
      {
        type: 'radiogroup',
        name: 'receivingMoneyForReason',
        label: 'start-two.receivingMoneyForReason',
        label2: 'start-two.receivingMoneyForReason.list',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'receivingMoneyFromExistingProgram',
        label: 'start-two.receivingMoneyFromExistingProgram',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'moneyForImmediateNeed',
        label: 'start-two.moneyForImmediateNeed',
        choices: radioDefault,
        isRequired: true
      },
      {
        name: 'receivedSocialAssistanceInPast',
        type: 'radiogroup',
        label: 'start-two.receivedSocialAssistanceInPast',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'withDisability',
        label: 'start-two.hasDisability',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'dropdown',
        name: 'statusInCanada',
        label: 'start-two.statusInCanada',
        choices: statusInCanadaChoices,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'releasedFromInstitution',
        label: 'start-two.releasedFromInstitution',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'text',
        name: 'dateOfReleaseFromInstitution',
        label: 'start-two.dateOfReleaseFromInstitution',
        isRequired: true,
        isVisibleCondition: (applicationAnswers, panelData) => applicationAnswers.jsonData.releasedFromInstitution === 'yes'
      },
      {
        type: 'radiogroup',
        name: 'livingOnFirstNationsReserveLand',
        label: 'start-two.livingOnFirstNationsReserveLand',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'text',
        name: 'firstNationsName',
        label: 'start-two.firstNationsName',
        isRequired: true,
        isVisibleCondition: (applicationAnswers, panelData) => applicationAnswers.jsonData.livingOnFirstNationsReserveLand === 'yes'
      },
      {
        type: 'radiogroup',
        name: 'applyingForYourselfOrSomeoneElse',
        label: 'start-two.applyingForYourselfOrSomeoneElse',
        choices: applyingFor,
        isRequired: true
      },
      {
        type: 'dropdown',
        name: 'applyingForSomeoneElseRelationship',
        label: 'start-two.relationToApplicant',
        choices: relationship,
        isVisibleCondition: (applicationAnswers, panelData) => applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse ===
          'APPLICATION_OTHER',
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'applyingForSomeoneElseRelationshipNeedTrustee',
        label: 'start-two.need.trustee',
        choices: NeedTrustee,
        isVisibleCondition: (applicationAnswers, panelData) => applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse ===
          'APPLICATION_OTHER',
        isRequired: true
      },
      {
        type: 'text',
        name: 'trusteeFirstName',
        label: 'start-two.need.trustee.details.trusteeFirstName',
        isRequired: true,
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
        skipValueTranslate: true
      },
      {
        type: 'text',
        name: 'trusteeLastName',
        label: 'start-two.need.trustee.details.trusteeLastName',
        isRequired: true,
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
        skipValueTranslate: true
      },
      {
        type: 'checkbox',
        name: 'trusteeNoLastName',
        label: 'start-two.need.trustee.details.trusteeNoLastName',
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
      },
      {
       type: 'text',
        name: 'trusteePhone',
        label: 'start-two.need.trustee.details.phone',
        isRequired: true,
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
      },
      {
        type: 'text',
        name: 'trusteeEmail',
        label: 'start-two.need.trustee.details.email',
        isRequired: true,
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
      },
      {
        type: 'radiogroup',
        name: 'trusteeContactLanguage',
        label: 'start-two.need.trustee.details.contactLanguage',
        choices: language,
        isRequired: true,
        isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isTrusteeIdentified(applicationAnswers.jsonData),
      }
    ]
  },
  {
    name: PageInfo.personalInfo,
    title: 'personal-information.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'firstName'))
      || !!(applicationValidationErrors.find(error=>error.field === 'dateOfBirth'))
      || !!(applicationValidationErrors.find(error=>error.field === 'maritalStatus'))
      || !!(applicationValidationErrors.find(error=>error.field === 'statusInCanada'))
      || !!(applicationValidationErrors.find(error=>error.field === 'socialInsuranceNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'certificateOfIndianStatusNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'healthCardNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'immigrationFileNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'email'))
      : false,
    questions: [
      {
        type: 'text',
        name: 'firstName',
        label: 'personal-information.firstName',
        skipValueTranslate: true
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'personal-information.lastName',
        skipValueTranslate: true
      },
      {
        type: 'checkbox',
        name: 'noLastName',
        label: 'personal-information.noLastName'
      },
      {
        type: 'text',
        name: 'dateOfBirth',
        label: 'personal-information.dateOfBirth'
      },
      {
        type: 'radiogroup',
        name: 'sexAtBirth',
        label: 'personal-information.sexAtBirth',
        choices: sexAtBirth
      },
      {
        type: 'dropdown',
        name: 'maritalStatus',
        label: 'personal-information.maritalStatus',
        choices: maritalStatusChoices
      },
      {
        type: 'radiogroup',
        name: 'childrenLivingWithYou',
        label: 'personal-information.childrenLivingWithYou',
        choices: radioDefault
      },
      {
        type: 'dropdown',
        name: 'statusInCanada',
        label: 'personal-information.status.statusInCanada',
        choices: statusInCanadaChoices
      },
      {
        type: 'text',
        name: 'arrivalDateToCanada',
        label: 'personal-information.status.arrivalDateToCanada',
        isVisibleCondition: (applicationAnswers, panelData) => {
          return applicationAnswers.jsonData.statusInCanada &&
            StatusInCanadaComponentUtil.isStatusRequireImmigrationRelatedFields(applicationAnswers.jsonData.statusInCanada)
        }
      },
      {
        type: 'radiogroup',
        name: 'tenYearsLivedInCanada',
        label: 'personal-information.status.tenYearsLivedInCanada',
        isVisibleCondition: (applicationAnswers, panelData) => {
          return applicationAnswers.jsonData.statusInCanada &&
            StatusInCanadaComponentUtil.isTenYearsLivedInCanadaFieldVisible(applicationAnswers.jsonData.statusInCanada,
              applicationAnswers.jsonData.dateOfBirth)
        },
        choices: radioDefault
      },
      {
        type: 'radiogroup',
        name: 'applicantSponsored',
        label: 'personal-information.status.sponsored',
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isStatusRequireImmigrationRelatedFields(status) &&
            !StatusInCanadaComponentUtil.isGovernmentAssistedRefugee(status)
        },
        choices: radioDefault
      },
      {
        type: 'text',
        name: 'socialInsuranceNumber',
        label: 'personal-information.status.sinNumber',
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isCitizenBornInCanada(status) ||
            StatusInCanadaComponentUtil.isStatusIndian(status) ||
            StatusInCanadaComponentUtil.isNonCanadianBornCitizenNonRefugee(status) ||
            StatusInCanadaComponentUtil.isRefugee(status)
        }
      },
      {
        type: 'checkbox',
        name: 'applicantNoSin',
        label: 'personal-information.status.noSin',
        labelParams: [{param: {firstName: 'firstName'}}],
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isCitizenBornInCanada(status) ||
            StatusInCanadaComponentUtil.isStatusIndian(status) ||
            StatusInCanadaComponentUtil.isNonCanadianBornCitizenNonRefugee(status) ||
            StatusInCanadaComponentUtil.isRefugee(status)
        }
      },
      {
        type: 'text',
        name: 'certificateOfIndianStatusNumber',
        label: 'personal-information.status.certificateOfIndianStatusNumber',
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isStatusIndian(status)
        }
      },
      {
        type: 'text',
        name: 'healthCardNumber',
        label: 'personal-information.status.healthCardNumber',
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isCitizenBornInCanada(status) ||
            StatusInCanadaComponentUtil.isStatusIndian(status) ||
            StatusInCanadaComponentUtil.isNonCanadianBornCitizenNonRefugee(status) ||
            StatusInCanadaComponentUtil.isRefugee(status)
        }
      },
      {
        type: 'text',
        name: 'immigrationFileNumber',
        label: 'personal-information.status.immigrationFileNumber',
        isVisibleCondition: (applicationAnswers, panelData) => {
          const status = applicationAnswers.jsonData.statusInCanada;
          return status && StatusInCanadaComponentUtil.isStatusRequireImmigrationRelatedFields(status);
        }
      },
      {
        type: 'text',
        name: 'email',
        label: 'personal-information.email',
      },
      {
        type: 'checkbox',
        name: 'noUniqueEmail',
        label: 'personal-information.noUniqueEmail',
        isVisibleCondition: (applicationAnswers: ApplicationAnswers) => applicationAnswers.jsonData.noUniqueEmail,
      },
      {
        type: 'text',
        name: 'phone',
        label: 'personal-information.phone'
      },
      {
        type: 'radiogroup',
        name: 'contactLanguage',
        label: 'personal-information.contactLanguage',
        choices: language
      },
      {
        type: 'radiogroup',
        name: 'contactMethod',
        label: 'personal-information.preferredContact.contactMethod',
        choices: contactMethodChoices
      },
      {
        type: 'dropdown',
        name: 'contactTime',
        label: 'personal-information.preferredContact.contactTime',
        choices: phoneTimeChoices,
        isVisibleCondition:  (applicationAnswers: ApplicationAnswers) => applicationAnswers.jsonData.contactMethod === 'phone'
      }
    ],
  },
  {
    name: PageInfo.spouseInfo,
    title: 'spouse-information.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'spouseSocialInsuranceNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'spouseCertificateOfIndianStatusNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'spouseHealthCardNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'spouseImmigrationFileNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'spouseStatusInCanada'))
      || !!(applicationValidationErrors.find(error=>error.field === 'spouseEmail'))
      : false,
    isVisibleCondition: (applicationAnswers: ApplicationAnswers) => applicationAnswers.jsonData.maritalStatus ===
      'Married' || applicationAnswers.jsonData.maritalStatus === 'Common Law',
    questions: [
      {
        type: 'text',
        name: 'spouseFirstName',
        label: 'spouse-information.spouseFirstName',
        skipValueTranslate: true
      },
      {
        type: 'text',
        name: 'spouseLastName',
        label: 'spouse-information.spouseLastName',
        skipValueTranslate: true
      },
      {
        type: 'checkbox',
        name: 'spouseNoLastName',
        label: 'spouse-information.spouseNoLastName',
        labelParams: [{param: {param: 'spouseFirstName'}}],
      },
      {
        type: 'text',
        name: 'spouseDateOfBirth',
        label: 'spouse-information.spouseDateOfBirth'
      },
      {
        type: 'radiogroup',
        name: 'spouseSexAtBirth',
        label: 'spouse-information.spouseSexAtBirth',
        choices: sexAtBirth
      },
      {
        type: 'text',
        name: 'spouseEmail',
        label: 'spouse-information.spouseEmail'
      },
      {
        type: 'checkbox',
        name: 'spouseNoUniqueEmail',
        label: 'spouse-information.spouseNoUniqueEmail',
        labelParams: [{param: {param: 'spouseFirstName'}}],
        isVisibleCondition: (applicationAnswers: ApplicationAnswers) => applicationAnswers.jsonData.spouseNoUniqueEmail
      },
      {
        type: 'dropdown',
        name: 'spouseStatusInCanada',
        label: 'spouse-information.status.statusInCanada',
        choices: statusInCanadaChoices
      },
      {
        type: 'checkbox',
        name: 'spouseNoLegalStatusInCanada',
        label: 'spouse-information.status.noLegalStatusInCanada',
      },
      {
        type: 'radiogroup',
        name: 'spouseLiveWithApplicant',
        label: 'spouse-information.status.liveWithApplicant',
        choices: radioDefault,
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.spouseNoLegalStatusInCanada
      },
      {
        type: 'text',
        name: 'spouseArrivalDateToCanada',
        label: 'spouse-information.status.arrivalDateToCanada',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.spouseStatusInCanada &&
          !StatusInCanadaComponentUtil.isCitizenBornInCanada(applicationAnswers.jsonData.spouseStatusInCanada) &&
          !StatusInCanadaComponentUtil.isStatusIndian(applicationAnswers.jsonData.spouseStatusInCanada)
      },
      {
        type: 'radiogroup',
        name: 'spouseSponsored',
        label: 'spouse-information.status.sponsored',
        labelParams: [{param: {firstName: 'spouseFirstName'}}, {param: {lastName: 'spouseLastName'}}],
        isVisibleCondition: (applicationAnswers) => {
          const status = applicationAnswers.jsonData.spouseStatusInCanada;
          return status && StatusInCanadaComponentUtil.isStatusRequireImmigrationRelatedFields(status) &&
            !StatusInCanadaComponentUtil.isGovernmentAssistedRefugee(status)
        },
        choices: radioDefault
      },
      {
        type: 'text',
        name: 'spouseSocialInsuranceNumber',
        label: 'spouse-information.status.sinNumber',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.spouseStatusInCanada
        // visibleIf: '{spouseStatusInCanada} notempty'
      },
      {
        type: 'checkbox',
        name: 'spouseNoSin',
        label: 'spouse-information.status.noSin',
        labelParams: [{param: {firstName: 'spouseFirstName'}}],
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.spouseStatusInCanada
      },
      {
        type: 'text',
        name: 'spouseCertificateOfIndianStatusNumber',
        label: 'spouse-information.status.certificateOfIndianStatusNumber',
        isVisibleCondition: (applicationAnswers) => {
          const status = applicationAnswers.jsonData.spouseStatusInCanada;
          return status && StatusInCanadaComponentUtil.isStatusIndian(status)
        }
      },
      {
        type: 'text',
        name: 'spouseHealthCardNumber',
        label: 'spouse-information.status.healthCardNumber',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.spouseStatusInCanada
      },
      {
        type: 'text',
        name: 'spouseImmigrationFileNumber',
        label: 'spouse-information.status.immigrationFileNumber',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.spouseStatusInCanada &&
          !StatusInCanadaComponentUtil.isCitizenBornInCanada(applicationAnswers.jsonData.spouseStatusInCanada) &&
          !StatusInCanadaComponentUtil.isStatusIndian(applicationAnswers.jsonData.spouseStatusInCanada)
      }
    ]
  },
  {
    name: PageInfo.childrenInfo,
    title: 'children-information.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'childSocialInsuranceNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'childCertificateOfIndianStatusNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'childHealthCardNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'childImmigrationFileNumber'))
      || !!(applicationValidationErrors.find(error=>error.field === 'childStatusInCanada'))
      || !!(applicationValidationErrors.find(error=>error.field === 'childEmail'))
      : false,
    isVisibleCondition: (applicationAnswers: ApplicationAnswers) => applicationAnswers.jsonData.childrenLivingWithYou === 'yes',
    questions: [
      {
        type: 'paneldynamic',
        name: 'childList',
        label: '',
        panels: [{
          questions: [
            {
              type: 'text',
              name: 'childFirstName',
              label: 'children-information.childFirstName',
              skipValueTranslate: true
            },
            {
              type: 'text',
              name: 'childLastName',
              label: 'children-information.childLastName',
              skipValueTranslate: true
            },
            {
              type: 'checkbox',
              name: 'childNoLastName',
              label: 'children-information.childNoLastName',
              labelParams: [{param: {name: 'childFirstName'}}],
            },
            {
              type: 'text',
              name: 'childDateOfBirth',
              label: 'children-information.childDateOfBirth'
            },
            {
              type: 'radiogroup',
              name: 'childSexAtBirth',
              label: 'children-information.childSexAtBirth',
              choices: sexAtBirth
            },
            {
              type: 'text',
              name: 'childEmail',
              label: 'children-information.childEmail',
              isVisibleCondition: (applicationAnswers, panelData) => SadaCustomValidator.validateOptionalOverAge(
                [panelData.childDateOfBirth, 18])
            },
            {
              type: 'checkbox',
              name: 'childNoUniqueEmail',
              label: 'children-information.childNoUniqueEmail',
              labelParams: [{param: {name: 'childFirstName'}}],
              isVisibleCondition: (applicationAnswers, panelData) => panelData.childNoUniqueEmail &&
                SadaCustomValidator.validateOptionalOverAge(
                  [panelData.childDateOfBirth, 18])
            },
            {
              type: 'dropdown',
              name: 'childStatusInCanada',
              label: 'children-information.status.statusInCanada',
              choices: statusInCanadaChoices
            },
            {
              type: 'checkbox',
              name: 'childNoLegalStatusInCanada',
              label: 'children-information.status.noLegalStatusInCanada',
            },
            {
              type: 'radiogroup',
              name: 'childLiveWithApplicant',
              label: 'children-information.status.liveWithApplicant',
              choices: radioDefault,
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childNoLegalStatusInCanada
            },
            {
              type: 'text',
              name: 'childArrivalDateToCanada',
              label: 'children-information.status.arrivalDateToCanada',
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
                && !StatusInCanadaComponentUtil.isCitizenBornInCanada(panelData.childStatusInCanada)
                && !StatusInCanadaComponentUtil.isStatusIndian(panelData.childStatusInCanada)
              // visibleIf: '{panel.childStatusInCanada} notempty and {panel.childStatusInCanada} <> \'Canadian citizen born in Canada\'',
            },
            {
              type: 'radiogroup',
              name: 'childSponsored',
              label: 'children-information.status.sponsored',
              labelParams: [{param: {firstName: 'childFirstName'}}, {param: {lastName: 'childLastName'}}],
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
                && !StatusInCanadaComponentUtil.isCitizenBornInCanada(panelData.childStatusInCanada)
                && !StatusInCanadaComponentUtil.isStatusIndian(panelData.childStatusInCanada)
                && !StatusInCanadaComponentUtil.isStatusNotEligibleForSupport(panelData.childStatusInCanada)
                && !StatusInCanadaComponentUtil.isGovernmentAssistedRefugee(panelData.childStatusInCanada),
              choices: radioDefault
            },
            {
              type: 'text',
              name: 'childSocialInsuranceNumber',
              label: 'children-information.status.sinNumber',
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
            },
            {
              type: 'checkbox',
              name: 'childNoSin',
              label: 'children-information.status.noSin',
              labelParams: [{param: {firstName: 'childFirstName'}}],
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
            },
            {
              type: 'text',
              name: 'childCertificateOfIndianStatusNumber',
              label: 'children-information.status.certificateOfIndianStatusNumber',
              isVisibleCondition: (applicationAnswers, panelData) =>  {
                const status = panelData.childStatusInCanada
                return status && StatusInCanadaComponentUtil.isStatusIndian(status)
              }
            },
            {
              type: 'text',
              name: 'childHealthCardNumber',
              label: 'children-information.status.healthCardNumber',
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
            },
            {
              type: 'text',
              name: 'childImmigrationFileNumber',
              label: 'children-information.status.immigrationFileNumber',
              isVisibleCondition: (applicationAnswers, panelData) => !!panelData.childStatusInCanada
                && !StatusInCanadaComponentUtil.isCitizenBornInCanada(panelData.childStatusInCanada)
                && !StatusInCanadaComponentUtil.isStatusIndian(panelData.childStatusInCanada)
            }
          ]
        }]
      }
    ]
  },
  {
    name: PageInfo.additionalInfo,
    title: 'additional-information.singleApplicant.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'additionalSpecialDietOrMedicalCondition'))
      || !!(applicationValidationErrors.find(error=>error.field === 'developmentServicesOntario'))
      || !!(applicationValidationErrors.find(error=>error.field === 'receivingACSD'))
      || !!(applicationValidationErrors.find(error=>error.field === 'additionalReceivedSocialAssistanceProgram'))
      : false,
    isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.maritalStatus !== 'Common Law' &&
      applicationAnswers.jsonData.maritalStatus !== 'Married' && applicationAnswers.jsonData.childrenLivingWithYou !==
      'yes',
    questions: [
      {
        type: 'radiogroup',
        name: 'additionalReceivedSocialAssistanceInPast',
        label: 'additional-information.singleApplicant.receivedSocialAssistance',
        choices: radioDefault
      },
      {
        type: 'radiogroup',
        name: 'additionalReceivedSocialAssistanceProgram',
        label: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram',
        isVisibleCondition: ((applicationAnswers) => applicationAnswers.jsonData.additionalReceivedSocialAssistanceInPast === 'yes'),
        choices: ReceivedSocialAssistanceProgram
      },
      {
        type: 'text',
        name: 'additionalApplicationDate',
        label: 'additional-information.singleApplicant.socialAssistancePanel.lastReceivedAssistanceDate',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalReceivedSocialAssistanceInPast ===
          'yes')
      },
      {
        type: 'text',
        name: 'additionalMemberID',
        label: 'additional-information.memberID',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalReceivedSocialAssistanceInPast ===
          'yes')
      },
      {
        type: 'radiogroup',
        name: 'additionalCurrentlyResidingInAnInstitution',
        label: 'additional-information.currentlyResidingInAnInstitution',
        choices: radioDefault
      },
      {
        type: 'dropdown',
        name: 'additionalLivingInAnInstitutionDropdown',
        label: 'additional-information.livingInAnInstitutionPanel.whereAreYouStaying',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalCurrentlyResidingInAnInstitution ===
          'yes'),
        choices: institutionChoices
      },
      {
        type: 'text',
        name: 'additionalInstitutionName',
        label: 'additional-information.livingInAnInstitutionPanel.additionalInstitutionName',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => !!applicationAnswers.jsonData.additionalInstitutionName),
      },
      {
        type: 'radiogroup',
        name: 'additionalCurrentlyIncarcerated',
        label: 'additional-information.currentlyIncarcerated',
        choices: radioDefault
      },
      {
        type: 'text',
        name: 'incarceratedExpectedReleaseDate',
        label: 'additional-information.currentlyIncarceratedPanel.expectedReleaseDate',
        isVisibleCondition: ((applicationAnswers) => !!applicationAnswers.jsonData.incarceratedExpectedReleaseDate),
      },
      {
        type: 'radiogroup',
        name: 'incarceratedStayPartTimeOrFullTime',
        label: 'additional-information.currentlyIncarceratedPanel.stayPartTimeOrFullTime',
        choices: stayPartTimeOrFullTimeChoices,
        isVisibleCondition: ((applicationAnswers) => !!applicationAnswers.jsonData.incarceratedStayPartTimeOrFullTime),
      },
      {
        type: 'radiogroup',
        name: 'additionalAccommodationServices',
        label: 'additional-information.accommodationServices',
        choices: radioDefault
      },
      {
        type: 'checkbox',
        name: 'visualCheckbox',
        label: 'additional-information.multipleApplicants.visualSupport',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        choices: visualCheckboxChoices,
      },
      {
        type: 'textarea',
        name: 'visualDescription',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        label: 'additional-information.pleaseDescribe',
      },
      {
        type: 'checkbox',
        name: 'hearingCheckbox',
        label: 'additional-information.multipleApplicants.hearingSupport',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        choices: hearingCheckboxChoices,
      },
      {
        type: 'textarea',
        name: 'hearingDescription',
        label: 'additional-information.pleaseDescribe',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes')
      },
      {
        type: 'checkbox',
        name: 'verbalCheckbox',
        label: 'additional-information.multipleApplicants.verbalSupport',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        choices: verbalCheckboxChoices,
      },
      {
        type: 'textarea',
        name: 'verbalDescription',
        label: 'additional-information.pleaseDescribe',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes')
      },
      {
        type: 'checkbox',
        name: 'communicationCheckbox',
        label: 'additional-information.multipleApplicants.communicationSupport',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        choices: communicationCheckboxChoices,
      },
      {
        type: 'textarea',
        name: 'communicationDescription',
        label: 'additional-information.pleaseDescribe',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes')
      },
      {
        type: 'checkbox',
        name: 'mobilityCheckbox',
        label: 'additional-information.multipleApplicants.mobilitySupport',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes'),
        choices: mobilityCheckboxChoices,
      },
      {
        type: 'textarea',
        name: 'mobilityDescription',
        label: 'additional-information.pleaseDescribe',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalAccommodationServices ===
          'yes')
      },
      {
        type: 'radiogroup',
        name: 'additionalSpecialDietOrMedicalCondition',
        label: 'additional-information.specialDietOrMedicalCondition',
        choices: radioDefault
      },
      {
        type: 'radiogroup',
        name: 'additionalNutritionalNeeds',
        label: 'additional-information.nutritionalNeeds',
        choices: radioDefault
      },
      {
        type: 'checkbox',
        name: 'pregnantCheckbox',
        label: 'additional-information.pregnant',
        choices: pregnantCheckboxChoices,
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalNutritionalNeeds === 'yes')
      },
      {
        type: 'text',
        name: 'dueDate',
        label: 'additional-information.pregnantDueDate',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.pregnantCheckbox &&
          applicationAnswers.jsonData.additionalNutritionalNeeds === 'yes')
      },
      {
        type: 'checkbox',
        name: 'breastFeedingCheckbox',
        label: 'additional-information.breastFeeding',
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalNutritionalNeeds === 'yes')
      },
      {
        type: 'radiogroup',
        name: 'lactoseIntolerantDiet',
        label: 'additional-information.lactoseIntolerantDiet',
        isVisibleCondition: ((applicationAnswers,
                              // tslint:disable-next-line:max-line-length
                              panelJsonData) => applicationAnswers.jsonData.pregnantCheckbox || applicationAnswers.jsonData.breastFeedingCheckbox && applicationAnswers.jsonData.additionalNutritionalNeeds === 'yes'),
        choices: radioDefault
      },
      {
        type: 'radiogroup',
        name: 'personWithDisability',
        label: 'additional-information.hasDisability',
        choices: radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'developmentServicesOntario',
        label: 'additional-information.singleApplicant.developmentServicesOntario',
        choices: developmentServicesOntarioChoices,
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.personWithDisability === 'yes')
      },
      {
        type: 'radiogroup',
        name: 'receivingACSD',
        label: 'additional-information.singleApplicant.receivingACSD',
        choices: radioDefault,
        isRequired: true,
        isVisibleCondition: ((applicationAnswers) => enableReceivingACSDFamilyFn(applicationAnswers))
      },
      {
        type: 'radiogroup',
        name: 'additionalFullTimeStudent',
        label: 'additional-information.singleApplicant.fullTimeStudent',
        choices: radioDefault
      },
      {
        type: 'dropdown',
        name: 'additionalSchoolType',
        label: 'additional-information.singleApplicant.school',
        labelParams: [{param: {param0: 'firstName'}}, {param: {param1: 'lastName'}}],
        isVisibleCondition: ((applicationAnswers,
                              panelJsonData) => applicationAnswers.jsonData.additionalFullTimeStudent === 'yes'),
        choices: schoolTypeChoices
      },
      {
        type: 'radiogroup',
        name: 'additionalCaringForChild',
        label: 'additional-information.caringForChild',
        choices: radioDefault
      }
    ]
  },
  {
    name: PageInfo.additionalInfo,
    title: 'additional-information.multipleApplicants.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'specialDietOrMedicalConditionInFamily'))
      || !!(applicationValidationErrors.find(error=>error.field === 'moneyForImmediateNeedInFamily'))
      || !!(applicationValidationErrors.find(error=>error.field === 'developmentServicesOntario'))
      || !!(applicationValidationErrors.find(error=>error.field === 'receivingACSDFamily'))
      || !!(applicationValidationErrors.find(error=>error.field === 'receivedSocialAssistanceProgram'))
      : false,
    isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.maritalStatus === 'Common Law' ||
      applicationAnswers.jsonData.maritalStatus === 'Married' || applicationAnswers.jsonData.childrenLivingWithYou === 'yes',
    questions: [
      {
        type: 'radiogroup',
        name: 'familyReceivedSocialAssistanceInPast',
        label: 'additional-information.multipleApplicants.receivedSocialAssistance',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'socialAssistanceRecipients',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyReceivedSocialAssistanceInPast === 'yes'
      },
      {
        type: 'paneldynamic',
        name: 'socialAssistanceDetailsList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyReceivedSocialAssistanceInPast === 'yes',
        panels: [{
          label: 'household-income.review.applicant.type',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'radiogroup',
              name: 'receivedSocialAssistanceProgram',
              label: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram',
              choices: ReceivedSocialAssistanceProgram
            },
            {
              type: 'text',
              name: 'applicationDate',
              label: 'additional-information.multipleApplicants.review.assistanceDate',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            },
            {
              type: 'text',
              name: 'memberID',
              label: 'additional-information.memberID'
            }
          ]
        }]
      },
      {
        type: 'radiogroup',
        name: 'familyMembersResidingInInstitution',
        label: 'additional-information.multipleApplicants.currentlyResidingInAnInstitution',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'familyMembersResidingInInstitutionCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyMembersResidingInInstitution === 'yes'
      },
      {
        type: 'paneldynamic',
        name: 'familyMembersResidingInInstitutionList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyMembersResidingInInstitution === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'dropdown',
              name: 'institutionLocation',
              label: 'additional-information.multipleApplicants.review.institutionLocation',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
              choices: institutionChoices
            },
            {
              type: 'text',
              name: 'institutionName',
              label: 'additional-information.multipleApplicants.institutionName',
              isVisibleCondition: ((applicationAnswers,
                                    panelJsonData) => !!panelJsonData.institutionName
              ),
            }
          ]
        }]
      },

      {
        type: 'radiogroup',
        name: 'familyMembersCurrentlyIncarcerated',
        label: 'additional-information.multipleApplicants.currentlyIncarcerated',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'familyMembersCurrentlyIncarceratedCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyMembersCurrentlyIncarcerated === 'yes'
      },
      {
        type: 'paneldynamic',
        name: 'familyMembersCurrentlyIncarceratedList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.familyMembersCurrentlyIncarcerated === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'odsdate',
              name: 'expectedReleaseDate',
              label: 'additional-information.multipleApplicants.review.expectedReleaseDate',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            },
            {
              type: 'radiogroup',
              name: 'stayPartTimeOrFullTime',
              label: 'additional-information.multipleApplicants.review.stayPartTimeOrFullTime',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
              choices: stayPartTimeOrFullTimeChoices
            }
          ]
        }]
      },
      {
        type: 'radiogroup',
        name: 'additionalFamilyAccommodationServices',
        label: 'additional-information.multipleApplicants.familyAccommodationServices',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'familyAccommodationServicesCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.additionalFamilyAccommodationServices === 'yes',
      },
      {
        type: 'paneldynamic',
        name: 'additionalAccommodationServicesInFamilyList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.additionalFamilyAccommodationServices === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'checkbox',
              name: 'visualCheckbox',
              label: 'additional-information.multipleApplicants.visualSupport',
              choices: visualCheckboxChoices,
            },
            {
              type: 'textarea',
              name: 'visualDescription',
              label: 'additional-information.pleaseDescribe',
            },
            {
              type: 'checkbox',
              name: 'hearingCheckbox',
              label: 'additional-information.multipleApplicants.hearingSupport',
              choices: hearingCheckboxChoices,
            },
            {
              type: 'textarea',
              name: 'hearingDescription',
              label: 'additional-information.pleaseDescribe',
            },
            {
              type: 'checkbox',
              name: 'verbalCheckbox',
              label: 'additional-information.multipleApplicants.verbalSupport',
              choices: verbalCheckboxChoices,
            },
            {
              type: 'textarea',
              name: 'verbalDescription',
              label: 'additional-information.pleaseDescribe',
            },
            {
              type: 'checkbox',
              name: 'communicationCheckbox',
              label: 'additional-information.multipleApplicants.communicationSupport',
              choices: communicationCheckboxChoices,
            },
            {
              type: 'textarea',
              name: 'communicationDescription',
              label: 'additional-information.pleaseDescribe',
            },
            {
              type: 'checkbox',
              name: 'mobilityCheckbox',
              label: 'additional-information.multipleApplicants.mobilitySupport',
              choices: mobilityCheckboxChoices,
            },
            {
              type: 'textarea',
              name: 'mobilityDescription',
              label: 'additional-information.pleaseDescribe',
            }
          ]
        }]
      },
      {
        type: 'radiogroup',
        name: 'specialDietOrMedicalConditionInFamily',
        label: 'additional-information.multipleApplicants.additionalSpecialDietOrMedicalCondition',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'specialDietOrMedicalConditionInFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.specialDietOrMedicalConditionInFamily === 'yes',
      },
      {
        type: 'radiogroup',
        name: 'pregnantOrBreastFeedingInFamily',
        label: 'additional-information.multipleApplicants.nutritionalNeeds',
        choices: radioDefault
      },
      {
        type: 'applicantlist',
        name: 'pregnantOrBreastFeedingInFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.pregnantOrBreastFeedingInFamily === 'yes',
      },
      {
        type: 'paneldynamic',
        name: 'pregnantOrBreastFeedingInFamilyList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.pregnantOrBreastFeedingInFamily === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'checkbox',
              name: 'pregnantCheckbox',
              label: 'additional-information.pregnant',
              choices: pregnantCheckboxChoices,
            },
            {
              type: 'text',
              name: 'dueDate',
              label: 'additional-information.multipleApplicants.review.pregnantDueDate',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            },
            {
              type: 'checkbox',
              name: 'breastFeedingCheckbox',
              label: 'additional-information.breastFeeding',
            },
            {
              type: 'radiogroup',
              name: 'lactoseIntolerantDiet',
              label: 'additional-information.multipleApplicants.review.lactoseIntolerantDiet',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
              choices: radioDefault,
            },
          ]
        }]
      },
      {
        type: 'radiogroup',
        name: 'personWithDisabilityInFamily',
        label: 'additional-information.multipleApplicants.hasDisability',
        choices: radioDefault,
      },
      {
        type: 'applicantlist',
        name: 'personWithDisabilityInFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.personWithDisabilityInFamily === 'yes',
      },
      {
        type: 'paneldynamic',
        name: 'developmentServicesOntarioList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.personWithDisabilityInFamily === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'radiogroup',
              name: 'developmentServicesOntario',
              label: 'additional-information.multipleApplicants.review.developmentServicesOntario',
              choices: developmentServicesOntarioChoices,
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            }]
        }]
      },
      {
        type: 'radiogroup',
        name: 'receivingACSDFamily',
        label: 'additional-information.multipleApplicants.receivingACSD',
        choices: radioDefault,
        isVisibleCondition: ((applicationAnswers) => enableReceivingACSDFamilyFn(applicationAnswers))
      },
      {
        type: 'applicantlist',
        name: 'receivingACSDFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.receivingACSDFamily === 'yes',
      },
      {
        type: 'radiogroup',
        name: 'moneyForImmediateNeedInFamily',
        label: 'additional-information.multipleApplicants.moneyForImmediateNeed',
        choices: radioDefault,
      },
      {
        type: 'applicantlist',
        name: 'moneyForImmediateNeedInFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.moneyForImmediateNeedInFamily === 'yes',
      },
      {
        type: 'radiogroup',
        name: 'fullTimeStudentInFamily',
        label: 'additional-information.multipleApplicants.fullTimeStudent',
        choices: radioDefault,
      },
      {
        type: 'checkbox',
        name: 'fullTimeStudentInFamilyCheckbox',
        label: 'additional-information.select.all',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.fullTimeStudentInFamily === 'yes',
      },
      {
        type: 'paneldynamic',
        name: 'fullTimeStudentInFamilyList',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.fullTimeStudentInFamily === 'yes',
        panels: [{
          label: 'additional-information.multipleApplicants.review.applicantType',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'dropdown',
              name: 'schoolType',
              label: 'additional-information.multipleApplicants.review.school',
              labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
              choices: schoolTypeChoices
            }
          ]
        }]
      },
      {
        type: 'radiogroup',
        name: 'caringForChild',
        label: 'additional-information.caringForChild',
        choices: radioDefault,
      },
    ]
  },
  {
    name: PageInfo.sponsorship,
    title: 'sponsorship.page.title',
    isVisibleCondition: (applicationAnswers) => SadaCustomValidator.isFamilySponsored(
      [applicationAnswers.jsonData.spouseSponsored, applicationAnswers.jsonData.childList]),
    questions: [
      {
        type: 'applicantlist',
        name: 'anyoneSponsoredCheckbox',
        label: 'sponsorship.select.all.members',
      },
      {
        type: 'radiogroup',
        name: 'allHaveSameSponsor',
        label: 'sponsorship.same.sponsor',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.anyoneSponsoredList?.length > 1,
        choices: radioDefault
      },
      {
        type: 'text',
        name: 'commonSponsorFirstName',
        label: 'sponsorship.sponsor.first.name',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor === 'yes',
      },
      {
        type: 'text',
        name: 'commonSponsorLastName',
        label: 'sponsorship.sponsor.last.name',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor === 'yes',
      },
      {
        type: 'checkbox',
        name: 'commonSponsorNoLastName',
        label: 'sponsorship.sponsor.no.last.name',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor === 'yes',
      },
      {
        type: 'paneldynamic',
        name: 'anyoneSponsoredList',
        panels: [{
          label: 'sponsorship.review.sponsorship.details.for',
          labelParams: [{param: {param0: 'applicantName'}}],
          questions: [
            {
              type: 'text',
              name: 'sponsorFirstName',
              label: 'sponsorship.sponsor.first.name',
              isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor !== 'yes',
            },
            {
              type: 'text',
              name: 'sponsorLastName',
              label: 'sponsorship.sponsor.last.name',
              isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor !== 'yes',
            },
            {
              type: 'checkbox',
              name: 'sponsorNoLastName',
              label: 'sponsorship.sponsor.no.last.name',
              isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.allHaveSameSponsor !== 'yes',
            },
            {
              type: 'text',
              name: 'sponsorSupportAmount',
              label: 'sponsorship.review.multiple.amount.sponsor.pay',
              labelParams: [{param: {param0: 'applicantDisplayType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
              isDollarValue: true
            },
            {
              type: 'radiogroup',
              name: 'sponsorLivesWith',
              label: 'sponsorship.live.with.you',
              choices: radioDefault
            },
            {
              type: 'text',
              name: 'sponsorEndDate',
              label: 'sponsorship.end.date'
            }
          ]
        }]
      }
    ]
  },
  {
    name: PageInfo.sponsorship,
    title: 'sponsorship.page.title',
    isVisibleCondition: (applicationAnswers) => (applicationAnswers.jsonData.applicantSponsored === 'yes'
        && applicationAnswers.jsonData.maritalStatus !== 'Common Law'
        && applicationAnswers.jsonData.maritalStatus !== 'Married'
        && applicationAnswers.jsonData.childrenLivingWithYou !== 'yes') ||
      (applicationAnswers.jsonData.applicantSponsored === 'yes'
        && !SadaCustomValidator.isFamilySponsored([applicationAnswers.jsonData.spouseSponsored, applicationAnswers.jsonData.childList])),
    questions: [
      {
        type: 'text',
        name: 'sponsorFirstName',
        label: 'sponsorship.sponsor.first.name'
      },
      {
        type: 'text',
        name: 'sponsorLastName',
        label: 'sponsorship.sponsor.last.name'
      },
      {
        type: 'checkbox',
        name: 'sponsorNoLastName',
        label: 'sponsorship.sponsor.no.last.name'
      },
      {
        type: 'text',
        name: 'sponsorSupportAmount',
        label: 'sponsorship.single.amount.sponsor.pay',
        isDollarValue: true
      },
      {
        type: 'radiogroup',
        name: 'sponsorLivesWith',
        label: 'sponsorship.live.with.you',
        choices: radioDefault
      },
      {
        type: 'text',
        name: 'sponsorEndDate',
        label: 'sponsorship.end.date'
      }
    ]
  },
  {
    name: PageInfo.addressInfo,
    title: 'address-information.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'cityOrTown'))
      || !!(applicationValidationErrors.find(error=>error.field === 'postalCode')) : false,
    questions: [
      {
        type: 'radiogroup',
        name: 'deliveryType',
        label: 'address-information.deliveryType',
        choices: deliveryTypeChoices
      },
      {
        type: 'text',
        name: 'ruralRoute',
        label: 'address-information.ruralRoute',
        isVisibleCondition: (applicationAnswers, panelData) => applicationAnswers.jsonData.deliveryType === 'Rural route'
      },
      {
        type: 'text',
        name: 'apartmentNumber',
        label: 'address-information.apartmentNumber'
      },
      {
        type: 'text',
        name: 'streetNumber',
        label: 'address-information.streetNumber'
      },
      {
        type: 'dropdown',
        name: 'streetNumberSuffix',
        label: 'address-information.streetNumberSuffix'
      },
      {
        type: 'text',
        name: 'streetName',
        label: 'address-information.streetName'
      },
      {
        type: 'dropdown',
        name: 'streetType',
        label: 'address-information.streetType',
        choices: streetTypeChoices
      },
      {
        type: 'dropdown',
        name: 'direction',
        label: 'address-information.direction',
        choices: directionChoices
      },
      {
        type: 'text',
        name: 'cityOrTown',
        label: 'address-information.cityOrTown'
      },
      {
        type: 'text',
        name: 'province',
        label: 'address-information.province',
      },
      {
        type: 'text',
        name: 'postalCode',
        label: 'address-information.postalCode'
      },
      {
        type: 'checkbox',
        name: 'addressSame',
        label: 'address-information.addressSame',
        isVisibleCondition: (applicationAnswers) => {
            return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'subHeading',
        name:'mailingAddressTitle',
        label: 'address-information.mailing.address.title',
        isVisibleCondition: (applicationAnswers) => {
          return !applicationAnswers.jsonData.addressSame &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'mDeliveryType',
        label: 'address-information.mDeliveryType',
        choices: deliveryTypeChoices,
        isVisibleCondition: (applicationAnswers) => {
          return !applicationAnswers.jsonData.addressSame &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mGeneralDelivery',
        label: 'address-information.mGeneralDelivery',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType === 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mPoBox',
        label: 'address-information.mPoBox',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType === 'PO Box' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mStation',
        label: 'address-information.mStation',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType === 'PO Box' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mRuralRoute',
        label: 'address-information.mRuralRoute',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType === 'Rural route' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mApartmentNumber',
        label: 'address-information.mApartmentNumber',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mStreetNumber',
        label: 'address-information.mStreetNumber',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'mStreetNumberSuffix',
        label: 'address-information.mStreetNumberSuffix',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mStreetName',
        label: 'address-information.mStreetName',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'mStreetType',
        label: 'address-information.mStreetType',
        choices: streetTypeChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'mDirection',
        label: 'address-information.mDirection',
        choices: directionChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.mDeliveryType !== 'General delivery' && !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mCityOrTown',
        label: 'address-information.mCityOrTown',
        isVisibleCondition: (applicationAnswers) => {
          return !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'mProvince',
        label: 'address-information.mProvince',
        choices: provinceChoices,
        isVisibleCondition: (applicationAnswers) => {
          return !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'mPostalCode',
        label: 'address-information.mPostalCode',
        isVisibleCondition: (applicationAnswers) => {
          return !applicationAnswers.jsonData.addressSame
            && applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee !== 'trusteeIdentified'
        }
      },
      {
        type: 'subHeading',
        name:'trusteeTitle',
        label: 'address-information.trustee.address.title',
        isVisibleCondition: (applicationAnswers) => {
           return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'trusteeDeliveryType',
        label: 'address-information.trusteeDeliveryType',
        choices: deliveryTypeChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeGeneralDelivery',
        label: 'address-information.trusteeGeneralDelivery',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType === 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteePoBox',
        label: 'address-information.trusteePoBox',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType === 'PO Box' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeStation',
        label: 'address-information.trusteeStation',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType === 'PO Box' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeRuralRoute',
        label: 'address-information.trusteeRuralRoute',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType === 'Rural route' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeApartmentNumber',
        label: 'address-information.trusteeApartmentNumber',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeStreetNumber',
        label: 'address-information.trusteeStreetNumber',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'trusteeStreetNumberSuffix',
        label: 'address-information.trusteeStreetNumberSuffix',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeStreetName',
        label: 'address-information.trusteeStreetName',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'trusteeStreetType',
        label: 'address-information.trusteeStreetType',
        choices: streetTypeChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'trusteeDirection',
        label: 'address-information.trusteeDirection',
        choices: directionChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.trusteeDeliveryType !== 'General delivery' &&
            applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteeCityOrTown',
        label: 'address-information.trusteeCityOrTown',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'dropdown',
        name: 'trusteeProvince',
        label: 'address-information.trusteeProvince',
        choices: provinceChoices,
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      },
      {
        type: 'text',
        name: 'trusteePostalCode',
        label: 'address-information.trusteePostalCode',
        isVisibleCondition: (applicationAnswers) => {
          return applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
        }
      }
    ]
  },
  {
    name: PageInfo.housingSituation,
    title: 'housing-situation.page.title',
    isErrorIconVisibleCondition:  (applicationAnswers, applicationValidationErrors, reviewValidationErrors) => {
      const hasApplicationError =
        !!applicationValidationErrors &&
        applicationValidationErrors.some(error => error.field === 'currentHousingSituation');

      const isInvalidLivingWithYouRelationship =
        !!reviewValidationErrors &&
        reviewValidationErrors.some(error => error.field.includes('livingWithYouRelationship'));

      return hasApplicationError || isInvalidLivingWithYouRelationship;
    },
    questions: [
      {
        type: 'dropdown',
        name: 'currentHousingSituation',
        label: 'housing-situation.currentHousingSituation',
        choices: housingSituationChoices
      },
      {
        type: 'text',
        name: 'monthlyHousingCost',
        label: 'housing-situation.monthlyHousingCost',
        isDollarValue: true,
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.currentHousingSituation === 'I pay rent, my meals are not included'
          || applicationAnswers.jsonData.currentHousingSituation === 'I pay subsidized rent'
          || applicationAnswers.jsonData.currentHousingSituation === 'I own my home'
          || applicationAnswers.jsonData.currentHousingSituation === 'I pay rent that includes my meals'
      },
      {
        type: 'radiogroup',
        name: 'areYourMealsProvided',
        label: 'housing-situation.areYourMealsProvided',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.currentHousingSituation === 'I do not pay rent',
        choices: radioDefault
      },
      {
        type: 'checkboxlist',
        name: 'otherHousingCostList',
        label: 'housing-situation.housingCostDetailReview',
        choices: housingCostChoices,
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.currentHousingSituation === 'I pay rent, my meals are not included'
          // tslint:disable-next-line:max-line-length
          || applicationAnswers.jsonData.currentHousingSituation === 'I pay subsidized rent' || applicationAnswers.jsonData.currentHousingSituation === 'I own my home'
          || applicationAnswers.jsonData.currentHousingSituation === 'i do not pay rent without meals provided'
      },
      {
        type: 'text',
        name: 'otherHousingCostHeat',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostHeat.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostHeat
      },
      {
        type: 'text',
        name: 'otherHousingCostHydro',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostHydro.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostHydro
      },
      {
        type: 'text',
        name: 'otherHousingCostUtilities',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostUtilities.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostUtilities
      },
      {
        type: 'text',
        name: 'otherHousingCostCondoFee',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostCondoFee.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostCondoFee
      },
      {
        type: 'text',
        name: 'otherHousingCostHomeOrTenantInsurance',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostHomeOrTenantInsurance.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostHomeOrTenantInsurance
      },
      {
        type: 'text',
        name: 'otherHousingCostPropertyTax',
        isDollarValue: true,
        label: 'housing-situation.otherHousingCostPropertyTax.label.textbox',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.otherHousingCostPropertyTax
      },
      {
        type: 'radiogroup',
        name: 'anyoneElseLivingBesidesFamily',
        label: 'housing-situation.review.anyoneElseLivingBesidesFamily',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.currentHousingSituation !== 'I am staying in an emergency hostel or shelter',
        choices: radioDefault
      },
      {
        type: 'paneldynamic',
        name: 'otherPersonsLivingWithYou',
        isVisibleCondition: (applicationAnswers) => applicationAnswers.jsonData.anyoneElseLivingBesidesFamily === 'yes',
        panels: [{
          label: 'housing-situation.review.otherPersonsLivingWithYou',
          questions: [
            {
              type: 'dropdown',
              name: 'livingWithYouRelationship',
              label: 'housing-situation.livingWithYouRelationship',
              choices: livingWithYouRelationshipChoices
            },
            {
              type: 'text',
              name: 'livingWithYouFirstName',
              label: 'housing-situation.livingWithYouFirstName',
              isVisibleCondition: (applicationAnswers, panelData) => {
                return SharedUtil.isValidLivingWithYouRelationship(
                  applicationAnswers.jsonData.currentHousingSituation,
                  panelData.livingWithYouRelationship
                );
              }
            },
            {
              type: 'text',
              name: 'livingWithYouLastName',
              label: 'housing-situation.livingWithYouLastName',
              isVisibleCondition: (applicationAnswers, panelData) => {
                return SharedUtil.isValidLivingWithYouRelationship(
                  applicationAnswers.jsonData.currentHousingSituation,
                  panelData.livingWithYouRelationship
                );
              }
            },
            {
              type: 'text',
              name: 'livingWithYouDate',
              label: 'housing-situation.livingWithYouDate',
              isVisibleCondition: (applicationAnswers, panelData) => {
                return SharedUtil.isValidLivingWithYouRelationship(
                  applicationAnswers.jsonData.currentHousingSituation,
                  panelData.livingWithYouRelationship
                );
              }
            },
            {
              type: 'text',
              name: 'livingWithYouPaymentAmount',
              label: 'housing-situation.livingWithYouPaymentAmount',
              isDollarValue: true,
              isVisibleCondition: (applicationAnswers, panelData) => {
                const isValidRelationship = SharedUtil.isValidLivingWithYouRelationship(
                  applicationAnswers.jsonData.currentHousingSituation,
                  panelData.livingWithYouRelationship
                );
                return isValidRelationship && !SharedUtil.shouldHideMealsAndPaymentAmountFieldsInHs(
                  applicationAnswers.jsonData.currentHousingSituation, panelData.livingWithYouRelationship
                );
              }
            },
            {
              type: 'radiogroup',
              name: 'livingWithYouProvideMeals',
              label: 'housing-situation.livingWithYouProvideMeals',
              isVisibleCondition: (applicationAnswers, panelData) => {
                const isValidRelationship = SharedUtil.isValidLivingWithYouRelationship(
                  applicationAnswers.jsonData.currentHousingSituation,
                  panelData.livingWithYouRelationship
                );
                return isValidRelationship && !SharedUtil.shouldHideMealsAndPaymentAmountFieldsInHs(
                  applicationAnswers.jsonData.currentHousingSituation, panelData.livingWithYouRelationship
                );
              },
              choices: radioDefault
            }
          ]
        }]
      }
    ]
  },
  {
    name: PageInfo.financialIndependence,
    title: 'financial-independence.page.title',
    isVisibleCondition: (applicationAnswers: ApplicationAnswers) =>
      SadaCustomValidator.isFinancialIndependenceRequired(applicationAnswers.jsonData),
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'outOfHighSchoolForFiveYears'))
      || !!(applicationValidationErrors.find(error=>error.field === 'haveDiplomaOrDegree'))
      || !!(applicationValidationErrors.find(error=>error.field === 'receivedSoleSupportStudentAssistance'))
      : false,
    questions: [
      {
        type: 'radiogroup',
        name: 'outOfHighSchoolForFiveYears',
        label: 'financial-independence.outOfHighSchoolForFiveYears.question',
        choices:radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'haveDiplomaOrDegree',
        label: 'financial-independence.haveDiplomaOrDegree.question',
        choices:radioDefault,
        isRequired: true
      },
      {
        type: 'radiogroup',
        name: 'receivedSoleSupportStudentAssistance',
        label: 'financial-independence.receivedSoleSupportStudentAssistance.question',
        choices:radioDefault,
        isRequired: true
      },
    ]
  },
  {
    name: PageInfo.earnedIncome,
    title: 'earned-income.page.title',
    isErrorIconVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.earnedIncome
      && applicationAnswers.jsonData.earnedIncome[0].earnedIncomeMonth !== getCurrentMonthAsString(),
    questions: [
      {
        type: 'radiogroup',
        name: 'applicantReceiveEarnedIncome',
        label: 'earned-income.applicant.receive.income',
        labelParams: [{param: {month: getCurrentMonthAsString()},skipAttrValueFromJsonData: true},
          {param: {year: getCurrentYear()},skipAttrValueFromJsonData: true}],
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.applicantReceiveEarnedIncome,
        choices:radioDefault
      },
      {
        type: 'paneldynamic',
        name: 'earnedIncome',
        label: 'earned-income.review.income.for',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.applicantReceiveEarnedIncome === 'yes',
        panels:[
          {
            label: 'earned-income.review.income.for',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions:[
              {
                type: 'paneldynamic',
                name: 'earnedIncomeSourceList',
                panels: [{
                  label: 'earned-income.income',
                  questions: [
                    {
                      type: 'radiogroup',
                      name: 'earnedIncomeType',
                      label: 'earned-income.income.type',
                      choices:radioEarnedIncomeType
                    },
                    {
                      type: 'text',
                      name: 'employerName',
                      label: 'earned-income.employer.name',
                      isVisibleCondition:   (applicationAnswers, panelData) => panelData.earnedIncomeType ==='fullTime' ||
                      panelData.earnedIncomeType ==='partTime'
                    },
                    {
                      type: 'text',
                      name: 'businessName',
                      label: 'earned-income.business.name',
                      isVisibleCondition: (applicationAnswers, panelData) => panelData.earnedIncomeType ==='selfEmployed'
                    },
                    {
                      type: 'text',
                      name: 'programName',
                      label: 'earned-income.program.name',
                      isVisibleCondition: (applicationAnswers, panelData) => panelData.earnedIncomeType ==='training'
                    },
                    {
                      type: 'text',
                      name: 'earnedIncomePay',
                      label: 'earned-income.monthly.payment',
                      labelParams: [{param: {month: getCurrentMonthAsString()},skipAttrValueFromJsonData: true},
                        {param: {year: getCurrentYear()},skipAttrValueFromJsonData: true}]
                    },
                    {
                      type: 'radiogroup',
                      name: 'continueToReceiveThisIncome',
                      label: 'earned-income.continue.this.income',
                      choices: radioDefault
                    },
                    {
                      type: 'text',
                      name: 'dateOfFirstPay',
                      label: 'earned-income.date.of.pay'
                    },
                    {
                      type: 'radiogroup',
                      name: 'disabilityWorkExpense',
                      label: 'earned-income.disability.work.expense',
                      isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.personWithDisability === 'yes',
                      choices: radioDefault
                    },
                    {
                      type: 'text',
                      name: 'disabilityWorkExpenseMonthlyAmount',
                      isVisibleCondition:   (applicationAnswers, panelData) => panelData.disabilityWorkExpense ==='yes',
                      label: 'earned-income.disability.work.expense.monthly.amount'
                    }
                  ]
                }]
              }
            ]
          }
        ]
      },
      {
        type: 'checkbox',
        name: 'anyOneReceiveEarnedIncome',
        label: 'earned-income.anyone.receive.income',
        labelParams: [{param: {month: getCurrentMonthAsString()}, skipAttrValueFromJsonData: true},
          {param: {year: getCurrentYear()}, skipAttrValueFromJsonData: true}],
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.anyOneReceiveEarnedIncome &&
          applicationAnswers.jsonData.anyOneReceiveEarnedIncome[0] === 'None'
      },
      {
        type: 'applicantlist',
        name: 'earnedIncome',
        label: 'earned-income.anyone.receive.income',
        labelParams: [{param: {month: getCurrentMonthAsString()}, skipAttrValueFromJsonData: true},
          {param: {year: getCurrentYear()}, skipAttrValueFromJsonData: true}],
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.earnedIncome &&
          applicationAnswers.jsonData.anyOneReceiveEarnedIncome
      },
      {
        type: 'paneldynamic',
        name: 'earnedIncome',
        label: 'earned-income.review.income.for',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.earnedIncome &&
          applicationAnswers.jsonData.anyOneReceiveEarnedIncome,
        panels:[
          {
            label: 'earned-income.review.income.for',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions:[
              {
                type: 'paneldynamic',
                name: 'earnedIncomeSourceList',
                panels: [{
                  label: 'earned-income.income',
                  questions: [
                    {
                      type: 'radiogroup',
                      name: 'earnedIncomeType',
                      label: 'earned-income.income.type',
                      choices :radioEarnedIncomeType
                    },
                    {
                      type: 'text',
                      name: 'employerName',
                      label: 'earned-income.employer.name',
                      isVisibleCondition:  (applicationAnswers, panelData) => panelData.earnedIncomeType ==='fullTime' ||
                        panelData.earnedIncomeType ==='partTime'
                    },
                    {
                      type: 'text',
                      name: 'businessName',
                      label: 'earned-income.business.name',
                      isVisibleCondition:  (applicationAnswers, panelData) => panelData.earnedIncomeType ==='selfEmployed'
                    },
                    {
                      type: 'text',
                      name: 'programName',
                      label: 'earned-income.program.name',
                      isVisibleCondition:  (applicationAnswers, panelData) => panelData.earnedIncomeType ==='training'
                    },
                    {
                      type: 'text',
                      name: 'earnedIncomePay',
                      label: 'earned-income.monthly.payment',
                      labelParams: [{param: {month: getCurrentMonthAsString()},skipAttrValueFromJsonData: true},
                        {param: {year: getCurrentYear()},skipAttrValueFromJsonData: true}]
                    },
                    {
                      type: 'radiogroup',
                      name: 'continueToReceiveThisIncome',
                      label: 'earned-income.continue.this.income',
                      choices: radioDefault
                    },
                    {
                      type: 'text',
                      name: 'dateOfFirstPay',
                      label: 'earned-income.date.of.pay'
                    },
                    {
                      type: 'radiogroup',
                      name: 'disabilityWorkExpense',
                      label: 'earned-income.disability.work.expense',
                      isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.personWithDisabilityInFamily === 'yes',
                      choices: radioDefault
                    },
                    {
                      type: 'text',
                      name: 'disabilityWorkExpenseMonthlyAmount',
                      isVisibleCondition:   (applicationAnswers, panelData) => panelData.disabilityWorkExpense ==='yes',
                      label: 'earned-income.disability.work.expense.monthly.amount'
                    }
                  ]
                }]
              }
            ]
          }
        ]
      },
      {
        type: 'checkbox',
        name: 'anyChildCareExpenseList',
        label: 'earned-income.child.care.pay.for',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.childrenLivingWithYou === 'yes' &&
          applicationAnswers.jsonData.earnedIncome &&
          getChildrenYoungerThan18(applicationAnswers).length > 0
      },
      {
        type: 'paneldynamic',
        name: 'anyChildCareExpenseList',
        label: '',
        panels: [
          {
            questions: [
              {
                type: 'paneldynamic',
                name: 'childCare',
                panels: [{
                  label: 'earned-income.review.child.care.display',
                  labelParams: [{param: {name: 'careRecipientName'}}],
                  questions: [
                    {
                      type: 'radiogroup',
                      name: 'childCareExpenseType',
                      label: 'earned-income.child.care.income.program.type.select',
                      choices: radioChildCareType
                    },
                    {
                      type: 'text',
                      name: 'careProviderName',
                      label: 'earned-income.child.care.provider.name'
                    },
                    {
                      type: 'text',
                      name: 'childCareExpenseAmount',
                      label: 'earned-income.child.care.monthly.amount'
                    }
                  ]
                }]
              }
            ]
          }
        ],
      }
    ]
  },
  {
    name: PageInfo.householdIncome,
    title: 'household-income.page.title',
    questions: [
      {
        type: 'checkbox',
        name: 'hasDisabilityPayment',
        label: 'household-income.disabilityPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsDisability',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasDisabilityPayment
          && !!applicationAnswers.jsonData.otherIncomeRecipientsDisability,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListDisability',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasDisabilityPayment,
        panels: getOtherIncomeStateQuestions('hasDisabilityIncomeState', 'hasDisabilityIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasRetirementPayment',
        label: 'household-income.retirementPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsRetirement',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasRetirementPayment
          && applicationAnswers.jsonData.otherIncomeRecipientsRetirement,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListRetirement',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasRetirementPayment,
        panels: getOtherIncomeStateQuestions('hasRetirementPaymentIncomeState', 'hasRetirementPaymentIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasSurvivingSpousePayment',
        label: 'household-income.survivingSpousePensionPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsSurvivingSpousePayment',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSurvivingSpousePayment
          && applicationAnswers.jsonData.otherIncomeRecipientsSurvivingSpousePayment,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListSurvivingSpousePay',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSurvivingSpousePayment,
        panels: getOtherIncomeStateQuestions('hasSurvivingSpousePaymentIncomeState', 'hasSurvivingSpousePaymentIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasWsibLossOfIncome',
        label: 'household-income.lossOfIncomePayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsWsibLossOfIncome',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasWsibLossOfIncome
          && applicationAnswers.jsonData.otherIncomeRecipientsWsibLossOfIncome,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListWsibLossOfIncome',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasWsibLossOfIncome,
        panels: getOtherIncomeStateQuestions('wsibLossOfIncomeReceiving', 'wsibLossOfIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasWsibOther',
        label: 'household-income.otherPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsWsibOther',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasWsibOther
          && applicationAnswers.jsonData.otherIncomeRecipientsWsibOther,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListWsibOther',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasWsibOther,
        panels: getOtherIncomeStateQuestions('wsibOtherReceiving', 'wsibOtherMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasInsurancePayment',
        label: 'household-income.insurancePayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsInsurancePay',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasInsurancePayment
          && applicationAnswers.jsonData.otherIncomeRecipientsInsurancePay,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListInsurancePay',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasInsurancePayment,
        panels: getOtherIncomeStateQuestions('hasInsurancePaymentIncomeState', 'hasInsurancePaymentIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasOldAgeSecurity',
        label: 'household-income.oldAgeSecurity'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsOldAgeSecurity',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasOldAgeSecurity
          && applicationAnswers.jsonData.otherIncomeRecipientsOldAgeSecurity,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListOldAgeSecurity',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasOldAgeSecurity,
        panels: getOtherIncomeStateQuestions('hasOldAgeSecurityIncomeState', 'hasOldAgeSecurityIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasGuaranteedIncomeSupplement',
        label: 'household-income.guaranteedIncomeSupplement'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsGuaranteedIncomeSupplement',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasGuaranteedIncomeSupplement
          && applicationAnswers.jsonData.otherIncomeRecipientsGuaranteedIncomeSupplement,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListGuaranteedIncomeSupplement',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasGuaranteedIncomeSupplement,
        panels: getOtherIncomeStateQuestions('hasGuaranteedIncomeSupplementIncomeState', 'hasGuaranteedIncomeSupplementIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasSpousalAllowance',
        label: 'household-income.spousalAllowance'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsSpousalAllowance',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSpousalAllowance
          && applicationAnswers.jsonData.otherIncomeRecipientsSpousalAllowance,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListSpousalAllowance',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSpousalAllowance,
        panels: getOtherIncomeStateQuestions('hasSpousalAllowanceIncomeState', 'hasSpousalAllowanceIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasSurvivorAllowanceProgram',
        label: 'household-income.survivorAllowanceProgram'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsSurvivorAllowanceProgram',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSurvivorAllowanceProgram
          && applicationAnswers.jsonData.otherIncomeRecipientsSurvivorAllowanceProgram,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListSurvivorAllowanceProgram',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasSurvivorAllowanceProgram,
        panels: getOtherIncomeStateQuestions('hasSurvivorAllowanceProgramIncomeState', 'hasSurvivorAllowanceProgramIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasGainsPayment',
        label: 'household-income.gainsPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsGainsPayment',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasGainsPayment
          && applicationAnswers.jsonData.otherIncomeRecipientsGainsPayment,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListGainsPayment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasGainsPayment,
        panels: getOtherIncomeStateQuestions('hasGainsPaymentIncomeState', 'hasGainsPaymentIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasEIPayment',
        label: 'household-income.eiPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsEIPayment',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasEIPayment
          && applicationAnswers.jsonData.otherIncomeRecipientsEIPayment,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListEIPayment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasEIPayment,
        panels: getOtherIncomeStateQuestions('hasEIPaymentIncomeState', 'hasEIPaymentIncomeMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasStudentLoansGrantsOSAP',
        label: 'household-income.student-loan.osapPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsStudentLoansGrantsOSAP',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasStudentLoansGrantsOSAP
          && !!applicationAnswers.jsonData.otherIncomeRecipientsStudentLoansGrantsOSAP,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListStudentLoansGrantsOSAP',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasStudentLoansGrantsOSAP,
        panels: getOtherIncomeStateQuestions('studentLoansGrantsOSAPReceiving', 'studentLoansGrantsOSAPReceivingMonthlyAmount')
      },
      {
        type: 'checkbox',
        name: 'hasStudentLoansGrantsOther',
        label: 'household-income.otherStudentLoanPayment'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsStudentLoansGrantsOther',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasStudentLoansGrantsOther
          && !!applicationAnswers.jsonData.otherIncomeRecipientsStudentLoansGrantsOther,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeListStudentLoansGrantsOther',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasStudentLoansGrantsOther,
        panels: getOtherIncomeStateQuestions('studentLoansGrantsOtherReceiving', 'studentLoansGrantsOtherMonthlyAmount')
      },

      {
        type: 'checkbox',
        name: 'hasOtherPayments',
        label: 'household-income.other-income'
      },
      {
        type: 'applicantlist',
        name: 'otherIncomeRecipientsOtherPayments',
        label: 'household-income.who-gets-this-payment',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasOtherPayments
          && !!applicationAnswers.jsonData.otherIncomeRecipientsOtherPayments,
      },
      {
        type: 'paneldynamic',
        name: 'anyOtherIncomeList',
        isVisibleCondition: (applicationAnswers) => !!applicationAnswers.jsonData.hasOtherPayments,
        panels: [{
          label: 'household-income.review.applicant.type',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'paneldynamic',
              name: 'otherIncomeOtherPaymentList',
              panels: [{
                questions: [{
                  type: 'dropdown',
                  name: 'otherIncomeType',
                  label: 'household-income.other.income.type',
                  choices: householdOtherIncomeTypeChoices,
                  isVisibleCondition: (applicationAnswers, panelJsonData) => panelJsonData.otherIncomeType !== 'My income type is not listed here'
                },
                  {type: 'text',
                    name: 'otherIncomeDescription',
                    label: 'household-income.other.income.desc',
                    isVisibleCondition: (applicationAnswers, panelJsonData) => panelJsonData.otherIncomeType === 'My income type is not listed here'
                  },
                  {
                    type: 'radiogroup',
                    name: 'otherIncomeSourceReceiving',
                    radioValueAsLabel: true,
                    choices: incomeChoices
                  },
                  {
                    type: 'text',
                    name: 'otherIncomeSourceMonthlyAmount',
                    isDollarValue: true,
                    label: 'household-income.other.income.monthlyAmount',
                    isVisibleCondition: (applicationAnswers,
                                         panelJsonData) => panelJsonData.otherIncomeSourceReceiving ===
                        'Receiving this income' && panelJsonData.otherIncomeType !== 'HST return'
                  },
                  {
                    type: 'text',
                    name: 'otherIncomeSourceAnnualAmount',
                    isDollarValue: true,
                    label: 'household-income.other.income.annualAmount',
                    isVisibleCondition: (applicationAnswers,
                                         panelJsonData) => panelJsonData.otherIncomeSourceReceiving ===
                        'Receiving this income' &&  panelJsonData.otherIncomeType === 'HST return'
                  },
                  {
                    type: 'text',
                    name: 'otherIncomeValueOfPension',
                    isDollarValue: true,
                    label: 'household-income.totalValueOfPension.value',
                    isVisibleCondition: (applicationAnswers, panelJsonData) => panelJsonData.otherIncomeType === 'Pension - private'
                  }]
              }
              ]
            }
          ]
        }]
      },
      {
        type: 'checkbox',
        name: 'noOtherIncomeType',
        label: 'household-income.none.of.above'
      }
    ]
  },
  {
    name: PageInfo.financialAssets,
    title: 'financial-assets.page.title',
    isErrorIconVisibleCondition: (applicationAnswers, applicationValidationErrors) => !!applicationValidationErrors
      ? !!(applicationValidationErrors.find(error=>error.field === 'soldOrGivenItemsOfValue')) : false,
    questions: [
      {
        type: 'checkbox',
        name: 'ownItemsOfValueCashCheckbox',
        label: 'financial-assets.cash'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxCash',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueCashCheckbox &&
          !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxCash
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListCash',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueCashCheckbox,
        panels: [{
          label: 'household-income.review.applicant.type',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'text',
              name: 'ownItemsOfValueCashAmount',
              label: 'financial-assets.amount',
              isDollarValue: true
            }
          ]
        }
        ]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueBankAccountsCheckbox',
        label: 'financial-assets.bank.accounts'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxBankAccounts',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueBankAccountsCheckbox
          && !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxBankAccounts
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListBankAccounts',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueBankAccountsCheckbox,
        panels: [
          {
            label: 'household-income.review.applicant.type',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions: [
              {
                type: 'paneldynamic',
                name: 'bankAccountList',
                panels: [{
                  questions: [
                    {
                      type: 'textbox-auto-complete',
                      name: 'bankName',
                      label: 'financial-assets.bank.name',
                      choices: bankNameChoices
                    },
                    {
                      type: 'radiogroup',
                      name: 'accountType',
                      label: 'bank-details.accountType',
                      choices: bankAccountTypeChoices
                    },
                    {
                      type: 'text',
                      name: 'value',
                      label: 'financial-assets.bank.account.value',
                      isDollarValue: true
                    },
                    {
                      type: 'checkbox',
                      name: 'useAccountForDBD',
                      label: 'financial-assets.use.account.for.dbd',
                      isVisibleCondition(applicationAnswers, panelJsonData) {
                        return panelJsonData.useAccountForDBD && panelJsonData.useAccountForDBD[0] === 'yes';
                      }
                    },
                    {
                      type: 'text',
                      name: 'branchNumber',
                      label: 'bank-details.branchNumber',
                      isVisibleCondition(applicationAnswers, panelJsonData) {
                        return panelJsonData.useAccountForDBD && panelJsonData.useAccountForDBD[0] === 'yes';
                      }
                    },
                    {
                      type: 'text',
                      name: 'institutionNumber',
                      label: 'bank-details.institutionNumber',
                      isVisibleCondition(applicationAnswers, panelJsonData) {
                        return panelJsonData.useAccountForDBD && panelJsonData.useAccountForDBD[0] === 'yes';
                      }
                    },
                    {
                      type: 'text',
                      name: 'accountNumber',
                      label: 'bank-details.accountNumber',
                      isVisibleCondition(applicationAnswers, panelJsonData) {
                        return panelJsonData.useAccountForDBD && panelJsonData.useAccountForDBD[0] === 'yes';
                      }
                    }]
                }]
              }]
          }]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueInvestmentsCheckbox',
        label: 'financial-assets.investments'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxInvestment',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueInvestmentsCheckbox &&
          !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxInvestment
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListInvestment',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueInvestmentsCheckbox,
        panels: [
          {
            label: 'household-income.review.applicant.type',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions: [
              {
                type: 'text',
                name: 'investmentsTotalValue',
                label: 'financial-assets.investment.value',
                isDollarValue: true
              }]
          }]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueVehiclesCheckbox',
        label: 'financial-assets.vehicles'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxVehicle',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueVehiclesCheckbox
          && !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxVehicle
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListVehicle',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueVehiclesCheckbox,
        panels: [
          {
            label: 'household-income.review.applicant.type',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions: [
              {
                type: 'paneldynamic',
                name: 'vehicleList',
                panels: [{
                  questions: [
                    {
                      type: 'text',
                      name: 'year',
                      label: 'financial-assets.vehicle.year',
                    },
                    {
                      type: 'textbox-auto-complete',
                      name: 'make',
                      label: 'financial-assets.vehicle.make',
                      choices: vehicleMakeChoices
                    },
                    {
                      type: 'text',
                      name: 'model',
                      label: 'financial-assets.vehicle.model',
                    },
                    {
                      type: 'text',
                      name: 'value',
                      label: 'financial-assets.vehicle.value',
                      isDollarValue: true
                    },
                    {
                      type: 'radiogroup',
                      name: 'usedForWork',
                      label: 'financial-assets.vehicle.for.work',
                      choices: radioDefault
                    }]
                }]
              }]
          }]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueOpgtCheckbox',
        label: 'financial-assets.trust.fund.OPGT'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxTrustFundOpgt',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOpgtCheckbox &&
          !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOpgt
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListTrustFundOpgt',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOpgtCheckbox,
        panels: [{
          label: 'household-income.review.applicant.type',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'text',
              name: 'opgtTotalValue',
              label: 'financial-assets.investment.value',
              isDollarValue: true
            },
            {
              type: 'text',
              name: 'opgtAccessibleValue',
              label: 'financial-assets.trust.fund.accessible',
              isDollarValue: true
            }
          ]
        }
        ]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueOtherTrustCheckbox',
        label: 'financial-assets.trust.fund.other'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxTrustFundOther',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOtherTrustCheckbox &&
          !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxTrustFundOther
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListTrustFundOther',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOtherTrustCheckbox,
        panels: [{
          label: 'household-income.review.applicant.type',
          labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
          questions: [
            {
              type: 'text',
              name: 'privateTrustTotalValue',
              label: 'financial-assets.investment.value',
              isDollarValue: true
            },
            {
              type: 'text',
              name: 'privateTrustAccessibleValue',
              label: 'financial-assets.trust.fund.other.amount',
              isDollarValue: true
            }
          ]
        }
        ]
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueOwnedHomeCheckbox',
        label: 'financial-assets.owned.home',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.currentHousingSituation === 'I own my home'
      },
      {
        type: 'checkbox',
        name: 'ownItemsOfValueOtherAssetsCheckbox',
        label: 'financial-assets.other.assets'
      },
      {
        type: 'applicantlist',
        name: 'ownItemsOfValueApplicantCheckboxOtherAssets',
        label: 'financial-assets.who.own.this.asset',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOtherAssetsCheckbox
          && !!applicationAnswers.jsonData.ownItemsOfValueApplicantCheckboxOtherAssets
      },
      {
        type: 'paneldynamic',
        name: 'ownItemsOfValueDetailsListOtherAssets',
        isVisibleCondition: applicationAnswers => !!applicationAnswers.jsonData.ownItemsOfValueOtherAssetsCheckbox,
        panels: [
          {
            label: 'household-income.review.applicant.type',
            labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
            questions: [
              {
                type: 'paneldynamic',
                name: 'otherAssetList',
                panels: [{
                  questions: [
                    {
                      type: 'dropdown',
                      name: 'assetType',
                      label: 'financial-assets.other.asset.type',
                      choices: otherAssetTypeChoices,
                      isVisibleCondition: (applicationAnswers, panelData) => panelData.assetType !== 'My asset type is not listed here'
                    },
                    {
                      type: 'text',
                      name: 'description',
                      label: 'financial-assets.other.asset.desc',
                      isVisibleCondition: (applicationAnswers, panelData) => panelData.assetType === 'My asset type is not listed here'
                    },
                    {
                      type: 'text',
                      name: 'value',
                      label: 'financial-assets.other.asset.value',
                      isDollarValue: true
                    },
                    {
                      type: 'text',
                      name: 'moneyInheritanceValue',
                      label: 'financial-assets.other.asset.moneyInheritanceValue',
                      isDollarValue: true,
                      isVisibleCondition: (applicationAnswers, panelData) => panelData.description ==='Money from an inheritance'
                    }
                  ]
                }]
              }]
          }]
      },
      {
        type: 'checkbox',
        name: 'noOtherFinancialAssets',
        label: 'financial-assets.none.of.above'
      },
      {
        type: 'radiogroup',
        name: 'soldOrGivenItemsOfValue',
        label: 'financial-assets.sold.give.assets',
        choices: radioDefault
      }
      ]
  },
  {
    name: PageInfo.bankDetails,
    title: 'bank-details.page.title',
    isVisibleCondition: applicationAnswers => !SharedUtil.checkIfDbdShouldBeExcluded(applicationAnswers),
    questions: [
      {
        type: 'radiogroup',
        name: 'provideBankDetails',
        label: 'bank-details.provideBankDetails',
        choices: radioDefault
      },
      {
        type: 'textbox-auto-complete',
        name: 'bankName',
        label: 'bank-details.bankName',
        choices: bankNameChoices,
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      },
      {
        type: 'text',
        name: 'accountValue',
        label: 'bank-details.accountValue',
        isDollarValue: true,
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      },
      {
        type: 'radiogroup',
        name: 'accountType',
        label: 'bank-details.accountType',
        choices: bankAccountTypeChoices,
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      },
      {
        type: 'text',
        name: 'branchNumber',
        label: 'bank-details.branchNumber',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      },
      {
        type: 'text',
        name: 'institutionNumber',
        label: 'bank-details.institutionNumber',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      },
      {
        type: 'text',
        name: 'accountNumber',
        label: 'bank-details.accountNumber',
        isVisibleCondition: applicationAnswers => applicationAnswers.jsonData.provideBankDetails === 'yes'
      }
    ]
  }
]

export function getOtherIncomeStateQuestions(incomeStateAttr: string, amountAttr: string): Panel[] {
  return [{
    label: 'household-income.review.applicant.type',
    labelParams: [{param: {param0: 'applicantType'}, translateParamValue: true}, {param: {param1: 'applicantName'}}],
    questions: [
      {
        type: 'radiogroup',
        name: incomeStateAttr,
        radioValueAsLabel: true,
        choices: incomeChoices
      },
      {
        type: 'text',
        name: amountAttr,
        isDollarValue: true,
        label: 'household-income.monthlyAmount.value',
        isVisibleCondition: (applicationAnswers, panelJsonData) => panelJsonData[incomeStateAttr] === 'Receiving this income'
      }
    ]
  }];
}
