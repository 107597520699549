export enum SadaErrorCodes {
  EC0001 = 'EC0001',// Application already exists with same reference number
  EC0003 = 'EC0003', // Home address postal code doesnt match with user entered street number or city
  EC0004 = 'EC0004', // Mailing address postal code doesnt match with user entered street number or city
  EC0005 = 'EC0005', // Mandatory fields are missing
  EC0101 = 'EC0101',// Schema validation error

  SADAERR101 = 'SADAERR101', // PSE Error during enroll success
  SADAERR102 = 'SADAERR102', // PSE Error during resume app froom myb
  SADAERR103 = 'SADAERR103', // PA access call back processing error
  SADAERR104 = 'SADAERR104', // PSE Redirect error on callback

  AUTHEC01 = 'AUTHEC01', // Error in Auth service
  AUTHEC02 = 'AUTHEC02', // Public secure account already linked to this SADA application. Part of redirect from PAAccess.
  AUTHEC03 = 'AUTHEC03', // Cant resume application. Might be submitted or completed. Part of redirect from PAAccess.
  AUTHEC04 = 'AUTHEC04', // PS subject mismatch
  AUTHEC05 = 'AUTHEC05', // JWT token expired
  AUTHEC06 = 'AUTHEC06', // Invalid JWT Token
  AUTHEC07 = 'AUTHEC07',  // MyBenefits account already exists for this PS email
  AUTHEC08 = 'AUTHEC08', // Invalid PS Token
  AUTHEC09 = 'AUTHEC09', // Invalid access token
  AUTHEC10 = 'AUTHEC10', // Invalid access token during login confirmation flow
  AUTHEC11 = 'AUTHEC11', // Public secure account already linked with a sada application. Possible when using same PS account for two different SADA application.
  AUTHEC12 = 'AUTHEC12', // Authorization header available in unauthenticated flow
  AUTHEC13 = 'AUTHEC13', // Member has active PDC
  AUTHEC14 = 'AUTHEC14', // All PDCs are inactive, application is submitted under a different person
  AUTHEC15 = 'AUTHEC15' // Application is in PAQ
}
