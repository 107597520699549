<sd-page-container [pageId]="pageId" [showBackButton]=true>
  <form [formGroup]="addressChoicesForm" sdDisableAutocomplete>
    <div  class="form-group required">
      <div *ngIf="homeAddressChoices?.length" >
        <div class="row">
          <div class="ontario-h3 col-md-10" id="home-address-title">{{'confirm-address.homeAddressTitle' | translate}}</div>
        </div>

        <div id="address-you-provided" class="mt-3 ontario-h5">
          <span>{{'confirm-address.userProvidedAddressText' | translate}}</span>:
        </div>
        <div id="user-entered-address">
          <app-address-display [address]="userEnteredHomeAddress"></app-address-display>
        </div>

        <sd-radio-question [showError]="false" [id]="'confirm-address.homeAddress'"
                           [label]="'confirm-address.homeAddressChoiceText'"
                           [required]="true" [ignoreValidationSetup]="false"
                           controlName="homeAddress"
                           [choices]="homeAddressChoices"
                           [displayQuestionLabel]="false">
          <div class="ontario-h4 ontario-margin-top-40-! ontario-margin-bottom-16-!" [attr.aria-hidden]="true">
            {{'confirm-address.homeAddressChoiceText' | translate}} <span class="ontario-label__flag">{{'Required' | translate}}</span>
          </div>
          <sd-alert-err *ngIf="selectAnOptionHomeAddressError"
                        [errorId]="'home-address-alert'" [errorMessage]="'confirm-address.selectAnOptionAlert' | translate">
          </sd-alert-err>
          <sd-alert-err *ngIf="homeAddressInvalid"
                        [errorId]="'home-address-postal-code-error'"
                        [errorMessage]="'confirm-address.postalCodeMismatch' | translate"
                        [isInnerHtml]="'true'">
          </sd-alert-err>
          <div *ngFor="let choice of homeAddressChoices; let i = index" class="ontario-radios__item">
            <input class="ontario-radios__input" [attr.aria-labelledby]="'confirm-address.homeAddress-radio-button-option-label-'+i" [formControlName]="'homeAddress'" id="confirm-address.homeAddress-radio-button-option-{{i}}" name="homeAddress" type="radio" value="{{choice.label}}">
            <label class="ontario-label ontario-radios__label" id="confirm-address.homeAddress-radio-button-option-label-{{i}}" for="confirm-address.homeAddress-radio-button-option-{{i}}" [attr.aria-hidden]="true">
              <app-address-display [address]="choice.value"></app-address-display>
            </label>
          </div>
        </sd-radio-question>
        <div class="divider my-4" aria-hidden="true" *ngIf="mailingAddressChoices?.length || trusteeAddressChoices?.length"></div>
      </div>
      <div *ngIf="mailingAddressChoices?.length">
        <div class="row">
          <div class="ontario-h3 col-md-10" id="mailing-address-title">{{'confirm-address.mailingAddressTitle' | translate}}</div>
        </div>
        <div class="mt-3 ontario-h5">
          <span>{{'confirm-address.userProvidedAddressText' | translate}}</span>:
        </div>
        <div id="user-entered-mailing-address">
          <app-address-display [address]="userEnteredMailingAddress"></app-address-display>
        </div>

        <sd-radio-question [showError]="false" [id]="'confirm-address.mailingAddress'"
                           [required]="true" [ignoreValidationSetup]="false"
                           [label]="'confirm-address.mailingAddressChoiceText'"
                           controlName="mailingAddress"
                           [choices]="mailingAddressChoices"
                           [displayQuestionLabel]="false">
          <div class="ontario-h4 ontario-margin-top-40-! ontario-margin-bottom-16-!" [attr.aria-hidden]="true">
            {{'confirm-address.mailingAddressChoiceText' | translate}} <span class="ontario-label__flag">{{'Required' | translate}}</span>
          </div>
          <sd-alert-err  *ngIf="selectAnOptionMailAddressError"
                         [errorId]="'mailing-address-alert'" [errorMessage]="'confirm-address.selectAnOptionAlert' | translate">
          </sd-alert-err>
          <div *ngFor="let choice of mailingAddressChoices; let i = index" class="ontario-radios__item">
            <input class="ontario-radios__input" [formControlName]="'mailingAddress'" id="confirm-address.mailingAddress-radio-button-option-{{i}}" name="mailingAddress" type="radio" value="{{choice.label}}" [attr.aria-labelledby]="'confirm-address.mailingAddress-radio-button-option-label-'+i">
            <label class="ontario-label ontario-radios__label"  id="confirm-address.mailingAddress-radio-button-option-label-{{i}}" for="confirm-address.mailingAddress-radio-button-option-{{i}}" [attr.aria-hidden]="true">
              <app-address-display [address]="choice.value"></app-address-display>
            </label>
          </div>
        </sd-radio-question>
      </div>
      <div *ngIf="trusteeAddressChoices?.length">
        <div class="row">
          <div class="ontario-h3 col-md-10" id="trustee-address-title">{{'confirm-address.trusteeAddressTitle' | translate}}</div>
        </div>
        <div class="mt-3 ontario-h5">
          <span>{{'confirm-address.userProvidedAddressText' | translate}}</span>:
        </div>
        <div id="user-entered-trustee-address">
          <app-address-display [address]="userEnteredTrusteeAddress"></app-address-display>
        </div>

        <sd-radio-question [showError]="false" [id]="'confirm-address.trusteeAddress'"
                           [required]="true" [ignoreValidationSetup]="false"
                           [label]="'confirm-address.trusteeAddressChoiceText'"
                           controlName="trusteeAddress"
                           [choices]="trusteeAddressChoices"
                           [displayQuestionLabel]="false">
          <div class="ontario-h4 ontario-margin-top-40-! ontario-margin-bottom-16-!" [attr.aria-hidden]="true">
            {{'confirm-address.trusteeAddressChoiceText' | translate}} <span class="ontario-label__flag">{{'Required' | translate}}</span>
          </div>
          <sd-alert-err  *ngIf="selectAnOptionTrusteeAddressError"
                         [errorId]="'trustee-address-alert'" [errorMessage]="'confirm-address.selectAnOptionAlert' | translate">
          </sd-alert-err>
          <div *ngFor="let choice of trusteeAddressChoices; let i = index" class="ontario-radios__item">
            <input class="ontario-radios__input" [formControlName]="'trusteeAddress'" id="confirm-address.trusteeAddress-radio-button-option-{{i}}" name="trusteeAddress" type="radio" value="{{choice.label}}" [attr.aria-labelledby]="'confirm-address.trusteeAddress-radio-button-option-label-'+i">
            <label class="ontario-label ontario-radios__label"  id="confirm-address.trusteeAddress-radio-button-option-label-{{i}}" for="confirm-address.trusteeAddress-radio-button-option-{{i}}" [attr.aria-hidden]="true">
              <app-address-display [address]="choice.value"></app-address-display>
            </label>
          </div>
        </sd-radio-question>
      </div>
    </div>
    <app-mccss-submit-button dataE2E="continueBtn" buttonLabel="confirm-address.confirmAddressButton" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
  </form>
</sd-page-container>
