import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  DirectionChoices,
  MailingDeliveryTypeChoices,
  ProvinceChoices,
  StreetNumberSuffixChoices,
  StreetTypeChoices
} from '../address-question-choices';
import {
  ApartmentNumberValidationFunction,
  CityOrTownValidationFunction,
  MGeneralDeliveryValidationFunction,
  PoBoxValidationFunction,
  RuralRouteValidationFunction,
  StationValidationFunction,
  StreetNameValidationFunction,
  StreetNumberValidationFunction
} from '../address-question-validation-functions';
import { AddressUtil } from '../../../utils/address-util';

@Component({
  selector: 'sd-mailing-or-trustee-information-panel',
  templateUrl: './mailing-or-trustee-information-panel.component.html',
  styleUrls: ['./mailing-or-trustee-information-panel.component.scss']
})
export class MailingOrTrusteeInformationPanelComponent implements OnChanges {
  @Input() data: any;
  @Input() showError = false;
  @Input() isAddressSame=false;
  @Input() isTrusteeAddress: boolean;
  @Input() postalCodeFinderLinkParam: any;
  @Input() mailingAddressStreetNoMismatch = false;
  @Input() trusteeAddressStreetNoMismatch= false;

  form: FormGroup;
  addressDeliveryType: string | undefined;
  private isInitialized = false;
  private valueChangeSubjects$ = new Subject<void>();
  deliveryTypeChoices = MailingDeliveryTypeChoices;
  streetNumberSuffixChoices = StreetNumberSuffixChoices;
  streetTypeSuffixChoices = StreetTypeChoices;
  directionChoices = DirectionChoices;
  mailingAddressProvinceChoice = ProvinceChoices;
  postalCodeRegex = '^(?![WZDFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d$';
  apartmentNumberValidationFunction = ApartmentNumberValidationFunction;
  streetNumberValidationFunction = StreetNumberValidationFunction;
  streetNameValidationFunction = StreetNameValidationFunction;
  cityOrTownValidationFunction = CityOrTownValidationFunction;
  ruralRouteValidationFunction = RuralRouteValidationFunction;
  generalDeliveryValidationFunction = MGeneralDeliveryValidationFunction;
  poBoxValidationFunction = PoBoxValidationFunction;
  stationValidationFunction = StationValidationFunction;

  constructor(
    public controlContainer: ControlContainer,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup;
    this.trackDeliveryTypeChanges();
    this.prepopulateMailingOrTrusteeInformation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // isInitialized is to add a control to the ngOnchange triggering
    if (changes.isAddressSame && this.isInitialized) {
      this.handleAddressSameChange(changes.isAddressSame.currentValue);
    }
    this.isInitialized = true;
  }

  ngOnDestroy() {
    this.valueChangeSubjects$.next();
    this.valueChangeSubjects$.complete();
  }

  private trackDeliveryTypeChanges(): void {
    const deliveryType = this.isTrusteeAddress ? 'trusteeDeliveryType' : 'mDeliveryType';
    this.form.controls[deliveryType].valueChanges
      .pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(value => this.onMailingOrTrusteeAddressDeliveryTypeChange(value));
  }

  private handleAddressSameChange(isAddressSame: boolean): void {
    if (isAddressSame) {
      AddressUtil.resetMailingAddressValidators(this.form);
    } else {
      this.setDefaultValuesForMailingAddress();
    }
  }

  getId(base: string): string {
    return this.isTrusteeAddress ? `address-information.trustee${base}` : `address-information.m${base}`;
  }

  getControlName(baseName: string): string {
    return this.isTrusteeAddress ? `trustee${baseName}` : `m${baseName}`;
  }

  getAddressTitleKey(): string {
    return this.isTrusteeAddress
      ? 'address-information.trustee.address.title'
      : 'address-information.mailing.address.title';
  }

  private prepopulateMailingOrTrusteeInformation() {
    this.initializeTrusteeAddressIfRequired();
    this.addressDeliveryType = this.data.jsonData?.trusteeDeliveryType || this.data.jsonData?.mDeliveryType;
  }

  onMailingOrTrusteeAddressDeliveryTypeChange(value: string) {
    this.addressDeliveryType = value;

    const resetValidators = {
      'Standard street address': [
        AddressUtil.resetValidatorsForTypeRuralRoute,
        AddressUtil.resetValidatorsForTypePOBox,
        AddressUtil.resetValidatorsForTypeGeneralDelivery,
      ],
      'PO Box': [
        AddressUtil.resetValidatorsForTypeRuralRoute,
        AddressUtil.resetValidatorsForTypeGeneralDelivery,
      ],
      'Rural route': [
        AddressUtil.resetValidatorsForTypePOBox,
        AddressUtil.resetValidatorsForTypeGeneralDelivery,
      ],
      'General delivery': [
        AddressUtil.resetValidatorsForTypeRuralRoute,
        AddressUtil.resetValidatorsForTypePOBox,
        AddressUtil.resetValidatorsForTypeStandStreetAddress,
      ],
    };

    resetValidators[value]?.forEach(fn => fn(this.form, this.isTrusteeAddress));
  }

  private initializeTrusteeAddressIfRequired() {
    if (!this.data.jsonData?.trusteeDeliveryType && this.isTrusteeAddress) {
      this.addressDeliveryType = 'Standard street address';
      this.setTrusteeAddressDefaults();
    } else if (!this.isTrusteeAddress) {
      this.resetTrusteeAddressValidators();
    }
  }

  private setTrusteeAddressDefaults() {
    this.form.patchValue({
      trusteeDeliveryType: 'Standard street address',
      trusteeProvince: 'Ontario',
      trusteeStreetNumber: '',
      trusteeStreetName: '',
      trusteeCityOrTown: '',
      trusteePostalCode: '',
      trusteeApartmentNumber: '',
    });
  }

  private resetTrusteeAddressValidators() {
    this.addressDeliveryType = '';
    AddressUtil.resetTrusteeAddressValidators(this.form, true);
  }

  private setDefaultValuesForMailingAddress() {
    this.form.patchValue({
      mDeliveryType: 'Standard street address',
      mProvince: 'Ontario',
      mStreetNumber: '',
      mStreetName: '',
      mCityOrTown: '',
      mPostalCode: '',
      mApartmentNumber: ''
    });
  }
}
