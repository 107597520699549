<div attr.name="{{fieldName}}" class="ontario-form-group" [ngClass]="{'ontario-form-group': !showLabel}" [formGroup]="form">
  <label *ngIf="showLabel" [ngClass]="{'ontario-label': applyStylingToLabel}" id="{{id}}">{{id | translate : labelParam}}
    <span class="ontario-label__flag">{{(required ? 'Required' : 'Optional') | translate}}</span>
  </label>

  <sd-alert-err *ngIf="showCustomError" [errorId]="id.concat('-custom-err')" [errorMessage]="showCustomError | translate" >
  </sd-alert-err>

  <div formArrayName="items" attr.aria-labelledby="{{id}}" class="ontario-checkboxes ontario-checkboxes__single-option">
    <div *ngFor="let item of items.controls; index as i" [ngClass]="setBackgroundClass()" class="ontario-checkboxes__item">
      <input id="{{id}}-checkbox{{labelParam?.index}}-option{{i + 1}}" type="checkbox" class="ontario-checkboxes__input" [formControlName]="i">
      <label id="{{id}}-checkbox{{labelParam?.index}}-label{{i + 1}}" class="ontario-checkboxes__label" for="{{id}}-checkbox{{labelParam?.index}}-option{{i + 1}}"
             [innerHTML]="options[i].label | translate: options[i].labelParam "></label>
    </div>
  </div>
  <sd-hint-text *ngIf="hintText && hintExpandableContent"
                id="{{id}}-question-hintText"
                [occurrence]="labelParam?.index"
                [text]="hintText"
                [expandableContent]="hintExpandableContent"
                [expandableContentParam]="hintExpandableContentParam">
  </sd-hint-text>
</div>
