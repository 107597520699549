<div class="form-group" [formGroup]="form">
  <sd-dropdown-question *ngIf="isFieldVisible(formControlNames.statusInCanada, statusInCanada)" [choices]="statusInCanadaChoices" [showError]="showError" [id]="id + '.statusInCanada'"
                        [validationFunctions]="statusDropDownValidationFns"
                        [required]="isFieldVisible(formControlNames.noLegalStatusInCanada, statusInCanada) && isStatusInCanadaRequired() ? false : true"
                        [controlName]="formControlNames.statusInCanada.name"
                        [disabled]="disableIdentifyingFields"
                        [labelParam]="labelParam">
  </sd-dropdown-question>
  <sd-checkbox-question *ngIf="isFieldVisible(formControlNames.noLegalStatusInCanada, statusInCanada)" [id]="id + '.noLegalStatusInCanada'"
                        [options]="noLegalStatusInCanadaCheckBoxItem"
                        [hintText]="!!hintText && !isPrimaryApplicant() ? id + '.hintText.statusInCanada' : undefined"
                        [hintExpandableContent]="!!hintContent && !isPrimaryApplicant() ? id + '.hintText.statusInCanada.hintContent' : undefined"
                        [showLabel]=false
                        [labelParam]="!!labelParam?.index ? labelParam : undefined"
                        (toggle)="onNoLegalStatusInCanadaCheckBoxChanges($event)">
  </sd-checkbox-question>
  <sd-radio-question *ngIf="isFieldVisible(formControlNames.liveWithApplicant, statusInCanada)" [choices]="choices"
                     [showError]="showError"
                     [id]="id + '.liveWithApplicant'"
                     [required]=true
                     [labelParam]="labelParam"
                     [controlName]="formControlNames.liveWithApplicant?.name" [context]="id+'.liveWithApplicant.context'">
  </sd-radio-question>
  <sd-date *ngIf="isFieldVisible(formControlNames.arrivalDateToCanada, statusInCanada)" [showError]="showError"
           [id]="'status-in-canada-arrivalDateToCanada'"
           [label]="id + '.arrivalDateToCanada'"
           [showDay]=true
           [labelParam]="labelParam"
           [controlNameDate]="formControlNames.arrivalDateToCanada.name"
           (blurEvent)="onArrivalDateToCanadaBlur($event)"
           [required]=true
           [needMarginTop]=true
           [smallRightMargin] = smallRightMargin
           [customHintText]="'statusInCanada.hintText.arrivalDateToCanada'"
           elementIndex="{{parentFormGroupIndex}}">
  </sd-date>
  <sd-radio-question *ngIf="isFieldVisible(formControlNames.tenYearsLivedInCanada, statusInCanada)" [choices]="choices"
                     [showError]="showError"
                     [id]="id + '.tenYearsLivedInCanada'"
                     [required]=true
                     [controlName]="formControlNames.tenYearsLivedInCanada.name" [context]="id+'.tenYearsLivedInCanada.context'">
  </sd-radio-question>
  <sd-radio-question *ngIf="isFieldVisible(formControlNames.sponsored, statusInCanada)" [choices]="choices"
                     [showError]="showError"
                     [id]="id + '.sponsored'"
                     [labelParam]="labelParam"
                     [required]="immigrationInfoFieldsRequired"
                     [hintText]="!!hintText ? id + '.hintText.sponsored' : undefined"
                     [hintExpandableContent]="!!hintContent ? id + '.hintText.sponsored.hintContent' : undefined"
                     [controlName]="formControlNames.sponsored.name" [context]="id+'.sponsored.context'">
  </sd-radio-question>
  <sd-text-question *ngIf="isFieldVisible(formControlNames.socialInsuranceNumber, statusInCanada)"
                    [showError]="showError"
                    [id]="id + '.sinNumber'"
                    [dataType]="dataType.SIN"
                    [validationFns]="sinValidationFns"
                    [asyncValidationFns]="asyncSinValidationFns"
                    [characterWidth]= 10
                    [required]="isSINRequired()"
                    [disabled]="disableIdentifyingFields"
                    [hintText]="!!hintText ? id + '.hintText.sinNumber' : undefined"
                    [hintExpandableContent]="!!hintContent ? id + '.hintText.sinNumber.hintContent' : undefined"
                    [controlName]="formControlNames.socialInsuranceNumber.name"
                    [showCustomError]="invalidSINError"
                    [labelParam]="labelParam"
                    elementIndex="{{parentFormGroupIndex}}">
  </sd-text-question>
  <sd-checkbox-question *ngIf="isFieldVisible(formControlNames.socialInsuranceNumber, statusInCanada) && !disableIdentifyingFields" [id]="id + '.noSin'" [options]="noSINCheckBoxItems"
                        [showLabel]=false
                        [labelParam]="!!labelParam?.index ? labelParam : undefined"
                        (toggle)="onNoSINCheckBoxChanges($event)"
                        backgroundClass={{checkboxBackgroundClass}}>
  </sd-checkbox-question>
  <sd-text-question *ngIf="isFieldVisible(formControlNames.certificateOfIndianStatusNumber, statusInCanada)"
                    [showError]="showError"
                    [id]="id + '.certificateOfIndianStatusNumber'"
                    [dataType]="dataType.CISN"
                    [validationFns]="certificateOfIndianStatusNumberValidationFns"
                    [hintText]="!!hintText ? id + '.hintText.certificateOfIndianStatusNumber' : undefined"
                    [characterWidth]= 10
                    [required] = "isCertificateOfIndianStatusNumberRequired()"
                    [disabled]="disableIdentifyingFields"
                    [controlName]="formControlNames.certificateOfIndianStatusNumber.name"
                    [showCustomError]="invalidCISNError"
                    [labelParam]="labelParam"
                    elementIndex="{{parentFormGroupIndex}}">
  </sd-text-question>
  <sd-text-question *ngIf="isFieldVisible(formControlNames.healthCardNumber, statusInCanada)"
                    [showError]="showError"
                    [id]="id + '.healthCardNumber'"
                    [dataType]="dataType.HN"
                    [validationFns]="healthCardNoValidationFns"
                    [asyncValidationFns]="asyncHealthCardNoValidationFns"
                    [characterWidth]= 10
                    [hintText]="!!hintText ? id + '.hintText.healthCardNumber' : undefined"
                    [hintExpandableContent]="!!hintContent ? id + '.hintText.healthCardNumber.hintContent' : undefined"
                    [required] = "isHealthCardNumberRequired()"
                    [disabled]="disableIdentifyingFields"
                    [controlName]="formControlNames.healthCardNumber.name"
                    [showCustomError]="invalidHNError"
                    [labelParam]="labelParam"
                    elementIndex="{{parentFormGroupIndex}}">
  </sd-text-question>
  <sd-text-question *ngIf="isFieldVisible(formControlNames.immigrationFileNumber, statusInCanada)" [showError]="showError"
                    [id]="id + '.immigrationFileNumber'"
                    [required]="immigrationInfoFieldsRequired && isUCIRequired()"
                    [disabled]="disableIdentifyingFields"
                    [validationFns]="uciFieldValidationFns"
                    [characterWidth]= 10
                    [hintText]="!!hintText ? id + '.hintText.immigrationFileNumber' : undefined"
                    [hintExpandableContent]="!!hintContent ? id + '.hintText.immigrationFileNumber.hintContent' : undefined"
                    [hintExpandableContentParam]="hintContentParam"
                    [controlName]="formControlNames.immigrationFileNumber.name"
                    [labelParam]="labelParam">
  </sd-text-question>
</div>
