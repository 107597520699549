import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AddressUtil} from '../../utils/address-util';
import * as lodash from 'lodash';
import {PcLookupRequest} from '../../models/pc-lookup-request';
import {AddressLookupService} from '../../services/address-lookup.service';
import {ApplicationAnswers} from '../../models/data.model';
import {SadaErrorCodes} from 'src/app/utils/sada-error-codes';
import {PostalService} from '../../services/postal.service';
import {
  DirectionChoices,
  HomeDeliveryTypeChoices,
  StreetNumberSuffixChoices,
  StreetTypeChoices
} from './address-question-choices';
import {
  ApartmentNumberValidationFunction,
  CityOrTownValidationFunction,
  RuralRouteValidationFunction,
  StreetNameValidationFunction,
  StreetNumberValidationFunction
} from './address-question-validation-functions';
import {ExternalRouter} from '../../external.router';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '../../services/config.service';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {UrlInfo} from '../../models/url-map';


@Component({
  selector: 'app-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss']
})
export class AddressInformationComponent extends PageBaseComponent implements OnInit, AfterViewChecked, OnDestroy {
  showError = false;
  invalidPostalCodeError: string;
  onlineSocialAssistanceLinkParam: any;
  onlineSocialAssistanceLinkValue: string;
  officeLocatorLinkParam: any;
  postalCodeFinderLinkParam: any;
  @Output() addressChange: EventEmitter<boolean> = new EventEmitter();

  deliveryTypeChoices = HomeDeliveryTypeChoices
  homeAddressDeliveryType = 'Standard street address'
  mailingAddressDeliveryType: string | undefined
  trusteeAddressDeliveryType: string | undefined
  isAddressSame = true
  homeAddressStreetNoMismatch: boolean | false
  mailingAddressStreetNoMismatch: boolean | false
  trusteeAddressStreetNoMismatch: boolean | false
  existingApplicationAnswers: ApplicationAnswers = {jsonData: {}}
  onlineSocialAssistanceLink= ''

  addressSameCheckBoxItems = [{value: 'yes', label: 'address-information.addressSame', checked: true}]
  streetNumberSuffixChoices = StreetNumberSuffixChoices
  streetTypeSuffixChoices = StreetTypeChoices
  directionChoices = DirectionChoices
  postalCodeRegex = '^(?![WZDFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d(?![DFIOQU])[A-Z]\\d$';

  apartmentNumberValidationFunction = ApartmentNumberValidationFunction
  streetNumberValidationFunction = StreetNumberValidationFunction
  streetNameValidationFunction = StreetNameValidationFunction
  cityOrTownValidationFunction = CityOrTownValidationFunction
  ruralRouteValidationFunction = RuralRouteValidationFunction

  constructor(private formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService,
              @Inject(LOCALE_ID) protected localeId: string,
              public translator: TranslateService,
              private addressLookupService: AddressLookupService,
              private postalService: PostalService,
              public ngZone: NgZone,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.addressInfo;
    this.setupLinks();
    StartOnePopup.beforeYouStart1.link = this.onlineSocialAssistanceLink;
    LocationInEmergencyPopup.locationInEmergency.link = this.onlineSocialAssistanceLink;
  }

  ngOnInit(): void {
    this.setupForm()
    this.initializeForm();

    this.form?.controls.deliveryType.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onDeliveryTypeChange(value)
    });
    this.form.controls.postalCode.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => this.onPostalCodeChange(value));

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onDeliveryTypeChange(value: any) {
    this.homeAddressDeliveryType = value
    if (value === 'Standard street address') {
      // This is mandatory to do because the visibility(if it does not show) will not trigger ngOnChanges event in
      // TextQuestionComponnet to remove the validators.
      this.form.controls.ruralRoute.clearValidators()
      this.form.controls.ruralRoute.updateValueAndValidity()
    }
  }

  private setupLinks(): void {
    this.onlineSocialAssistanceLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.onlineSocialAssistance);
    this.onlineSocialAssistanceLinkValue = this.onlineSocialAssistanceLink;
    this.officeLocatorLinkParam = {link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)};
    this.postalCodeFinderLinkParam = {link: this.configService.getUrl(this.translator.currentLang, UrlInfo.postalCodeFinder)};
  }

  setupForm(): void {
    this.form = this.formBuilder.group({
      deliveryType: [],
      ruralRoute: [],
      addressSame: [],
      apartmentNumber: [],
      streetNumber: [],
      streetNumberSuffix: [''],
      streetName: [],
      streetType: [''],
      direction: [''],
      cityOrTown: [],
      province: ['Ontario'],
      postalCode: [],
      mDeliveryType: [''],
      mRuralRoute: [],
      mPoBox: [],
      mStation: [],
      mGeneralDelivery: [],
      mApartmentNumber: [],
      mStreetNumber: [],
      mStreetNumberSuffix: [''],
      mStreetName: [],
      mStreetType: [''],
      mDirection: [''],
      mCityOrTown: [],
      mProvince: [],
      mPostalCode: [],
      trusteeDeliveryType: [''],
      trusteeRuralRoute: [],
      trusteePoBox: [],
      trusteeStation: [],
      trusteeGeneralDelivery: [],
      trusteeApartmentNumber: [],
      trusteeStreetNumber: [],
      trusteeStreetNumberSuffix: [''],
      trusteeStreetName: [],
      trusteeStreetType: [''],
      trusteeDirection: [''],
      trusteeCityOrTown: [],
      trusteeProvince: [],
      trusteePostalCode: []
    });
  }

  postInitializeForm() {
    if (this.applicationAnswers.jsonData?.trusteeDeliveryType && !this.isTrusteeAddress()) {
      // reset addressSame checkbox if not trusteeIdentified
      this.applicationAnswers.jsonData = {
        ...this.applicationAnswers.jsonData,
        addressSame: ['yes'],
      }
      this.form.controls.addressSame.setValue(['yes']);
    }
    if (this.applicationAnswers.jsonData?.postalCode?.length) {
      this.form.controls.postalCode.setValue(this.applicationAnswers.jsonData.postalCode)
    }
    if (!this.isTrusteeAddress()) {
      this.isAddressSame = this.applicationAnswers.jsonData?.addressSame?.[0] === 'yes';
      this.addressSameCheckBoxItems[0].checked = this.isAddressSame;
    }
    if (this.applicationAnswers.jsonData?.deliveryType) {
      this.homeAddressDeliveryType = this.applicationAnswers.jsonData.deliveryType
    }
    this.form.controls.province.disable()
  }

  onAddressSameCheckBoxChanges(event: any): void {
    if (event && event.length) {
      this.form.controls.addressSame.setValue(event)
      this.addressLookupService.clearCachedMailingAddressChoices();
      this.isAddressSame = true;
    } else {
      this.isAddressSame = false;
    }
    this.addressChange.emit(this.isAddressSame);
  }

  onPostalCodeChange(value: any) {
    this.invalidPostalCodeError = undefined;
  }

  get postalCodeValue(): string {
    return this.form.controls.postalCode?.value;
  }

  onSubmit(toContinue: boolean): void {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    this.existingApplicationAnswers = JSON.parse(JSON.stringify(this.applicationAnswers))

    if (this.form.valid) {
      this.postalService.validatePostalCode(this.postalCodeValue, (result) => {
        if (!result || !result.validPostalCode) {
          this.invalidPostalCodeError = 'start-one.invalid.postalCode';
          this.onlineSocialAssistanceLinkParam = {link: this.onlineSocialAssistanceLinkValue};
          this.showError = true;
          this.openDialog(StartOnePopup.beforeYouStart1)
        } else if (result.locationInEmergencySituation) {
          this.openDialog(LocationInEmergencyPopup.locationInEmergency)
        } else {
          this.showError = false;
          // call Intake service to save new application
          this.saveForm()?.subscribe(x => {
            this.handlePCLookup(toContinue);
          })
        }
      })
    } else {
      this.showError = true;
      this.scrollToInvalidFormControl(toContinue);
    }
  }

  preSaveApplication() {
    this.trusteeAddressDeliveryType = this.form.get('trusteeDeliveryType')?.value;
    this.mailingAddressDeliveryType = this.form.get('mDeliveryType')?.value;
    if (!this.isAddressSame||this.isTrusteeAddress()) {
      this.applicationAnswers.jsonData.addressSame = []
    }
    if (this.homeAddressDeliveryType === 'Standard street address') {
      delete this.applicationAnswers.jsonData?.ruralRoute;
    }

    if (this.isAddressSame||this.isTrusteeAddress()) {
      delete this.applicationAnswers.jsonData?.mDeliveryType
      AddressUtil.removeAllFieldsFromMailingAddressJson(this.applicationAnswers.jsonData)
    }

    if (this.applicationAnswers.jsonData.trusteeDeliveryType && !this.isTrusteeAddress()) {
      delete this.applicationAnswers.jsonData?.trusteeDeliveryType;
      AddressUtil.removeAllFieldsFromTrusteeAddressJson(this.applicationAnswers.jsonData, true);
    }

    const isTrustee = this.isTrusteeAddress();
    const deliveryTypes = ['Standard street address', 'PO Box', 'Rural route', 'General delivery'];
    const removeFields = {
      'Standard street address': [
        AddressUtil.removeRuralRouteFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removePoBoxFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson,
      ],
      'PO Box': [
        AddressUtil.removeRuralRouteFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson,
      ],
      'Rural route': [
        AddressUtil.removePoBoxFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson,
      ],
      'General delivery': [
        AddressUtil.removeRuralRouteFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removePoBoxFieldsFromMailingOrTrusteeAddressJson,
        AddressUtil.removeStandardStreetAddressFieldsFromMailingOrTrusteeAddressJson,
      ],
    };

    deliveryTypes.forEach(type => {
      if (this.mailingAddressDeliveryType === type || this.trusteeAddressDeliveryType === type) {
        removeFields[type].forEach(fn => fn(this.applicationAnswers.jsonData, isTrustee));
      }
    });
  }

  private handlePCLookup(toContinue: boolean) {
    const isHomeAddressUpdated = this.isHomeAddressUpdated()
    const isMailingAddressUpdated = this.isMailingAddressUpdated()
    const isTrusteeAddressUpdated = this.isTrusteeAddressUpdated()

    const mailingAddress = isTrusteeAddressUpdated
      ? AddressUtil.constructTrusteeAddressFromAnswers(this.applicationAnswers.jsonData)
      : (isMailingAddressUpdated
        ? AddressUtil.constructMailingAddressFromAnswers(this.applicationAnswers.jsonData)
        : null);
    if (isHomeAddressUpdated || isMailingAddressUpdated || isTrusteeAddressUpdated) {
      const pcLookUpRequest: PcLookupRequest = {
        homeAddress: isHomeAddressUpdated ? AddressUtil.constructHomeAddressFromAnswers(this.applicationAnswers.jsonData) : null,
        mailingAddress
      }

      this.addressLookupService.getAddressChoicesAndPopulateCache(pcLookUpRequest, isHomeAddressUpdated,
        isMailingAddressUpdated,isTrusteeAddressUpdated).subscribe(data => {
          if (toContinue && !this.authService.isAuthorizedToSave()) {
            this.intakeService.saveApplication(this.applicationAnswers, this.pageId).subscribe(() => {
              if (data && ((Array.isArray(data.homeAddressChoices) && data.homeAddressChoices.length)
                || (Array.isArray(data.mailingAddressChoices) && data.mailingAddressChoices.length))) {
                this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
              } else {
                this.router.navigate(['/', 'intake', PageInfo.housingSituation])
              }
            })
          } else if (toContinue) {
            this.saveApplicationToResume(this.applicationAnswers, this.pageId).subscribe(() => {
              if (data && ((Array.isArray(data.homeAddressChoices) && data.homeAddressChoices.length)
                || (Array.isArray(data.mailingAddressChoices) && data.mailingAddressChoices.length))) {
                this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
              } else {
                this.router.navigate(['/', 'intake', PageInfo.housingSituation])
              }
            })
          } else {
            this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
          }
        }, err => {
          this.handleAddressChoiceError(err, toContinue)
        })
    } else if (!toContinue) {
      this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
    } else if (this.addressLookupService.isCachedAddressChoicesAvailable()) {
      this.router.navigate(['/', 'intake', PageInfo.confirmAddress], {skipLocationChange: true})
    } else {
      this.router.navigate(['/', 'intake', PageInfo.housingSituation])
    }
  }

  private isHomeAddressUpdated() {
    const existingHomeAddress = AddressUtil.constructHomeAddressFromAnswers(this.existingApplicationAnswers.jsonData)
    const updatedHomeAddress = AddressUtil.constructHomeAddressFromAnswers(this.applicationAnswers.jsonData)
    return !lodash.isEqual(existingHomeAddress, updatedHomeAddress)
  }

  private isMailingAddressUpdated() {
    const isAddressSame = this.applicationAnswers.jsonData.addressSame?.[0] === 'yes';
    if ((!this.applicationAnswers.jsonData.addressSame || !isAddressSame) && !this.isTrusteeAddress()) {
      const existingMailingAddress = AddressUtil.constructMailingAddressFromAnswers(this.existingApplicationAnswers.jsonData)
      const updatedMailingAddress = AddressUtil.constructMailingAddressFromAnswers(this.applicationAnswers.jsonData)
      return !lodash.isEqual(existingMailingAddress, updatedMailingAddress)
    } else {
      return false;
    }
  }
  private isTrusteeAddressUpdated() {
    if (this.isTrusteeAddress()) {
      const existingMailingAddress = AddressUtil.constructTrusteeAddressFromAnswers(this.existingApplicationAnswers.jsonData)
      const updatedMailingAddress = AddressUtil.constructTrusteeAddressFromAnswers(this.applicationAnswers.jsonData)
      return !lodash.isEqual(existingMailingAddress, updatedMailingAddress)
    } else {
      return false;
    }
  }

  private handleAddressChoiceError(err: any, toContinue: boolean): void {

    if (err.status === 400 && SadaErrorCodes.EC0003 === err.error.errorCode) {
      this.homeAddressStreetNoMismatch = true
      window.scrollTo(0, 0)
    }
    else if (err.status === 400 && SadaErrorCodes.EC0004 === err.error.errorCode && this.isTrusteeAddress()) {
      this.trusteeAddressStreetNoMismatch = true
    }else if (err.status === 400 && SadaErrorCodes.EC0004 === err.error.errorCode) {
      this.mailingAddressStreetNoMismatch = true
    }
    else {
      if (!toContinue) { // Save and exit
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      } else {
        this.router.navigate(['/', 'intake', PageInfo.housingSituation])
      }
    }
  }

  isTrusteeAddress()
  {
    return this.intakeService.getIsApplyingForSomeoneElse()
      && this.applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified'
  }
}

export const StartOnePopup = {
  beforeYouStart1: {
    title: 'start-one.dialog.beforeYouStart1.title',
    body: 'start-one.dialog.beforeYouStart1.body',
    button: 'start-one.dialog.beforeYouStart1.button',
    link: ''
  }
}

export const LocationInEmergencyPopup = {
  locationInEmergency: {
    title: 'start-one.dialog.locationInEmergency.title',
    body: 'start-one.dialog.locationInEmergency.body',
    button: 'start-one.dialog.locationInEmergency.button',
    link: ''
  }
}
