import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {QuestionBaseComponent} from '../QuestionBaseComponent';
import {Choice} from '../../utils/questions/question-choices';
import {TranslateService} from '@ngx-translate/core';
import {functionValidator} from '../../utils/validators/custom-validators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sd-dropdown-question',
  templateUrl: './dropdown-question.component.html',
  styleUrls: ['./dropdown-question.component.scss']
})
export class DropdownQuestionComponent extends QuestionBaseComponent implements OnInit, OnChanges, OnDestroy{
  @Input()
  public controlName: string;

  @Input()
  public showError = false;

  @Input()
  public showCustomError: string | undefined;
  @Input()
  public customErrorParam: any;  // Params for the custom error message.

  @Input()
  public labelTitle: string | undefined;

  @Input()
  public labelDescription: string | undefined;

  @Input()
  public id: string | undefined;

  @Input()
  public label: string | undefined;

  @Input()
  tooltip: string | undefined;

  @Input()
  description: string | undefined;

  @Input()
  choices: Choice[] | undefined;

  @Input()
  public required: boolean | false;

  @Input()
  public validationFunctions: [{validationFunction: any, errorKey: string}] | undefined;

  // If the validators are set at parent level set this to true so that the original validators or not overridden. If this
  // components validation setup is used then dont set up any validators at parent level
  @Input()
  public ignoreValidationSetup: boolean | false;

  @Input()
  public labelParam: any | {};

  na: string|undefined;

  constructor(public formBuilder: FormBuilder, public translator: TranslateService, public controlContainer: ControlContainer) {
    super(controlContainer, formBuilder);
  }

  ngOnInit(): void {
    this.setUpForm(this.controlName);
    this.setupValidators();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.form && changes.required && !changes.required.isFirstChange()) {
      this.setupValidators()
    }
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get fieldName(): string {
    return this.getFieldName(this.id);
  }

  setupValidators() {
    if(!this.ignoreValidationSetup) {
      this.form.clearValidators()
      const validators: ValidatorFn[] = []

      if (this.required) {
        validators.push(Validators.required)
      }

      if (this.validationFunctions) {
        this.validationFunctions.forEach(v => {
          validators.push(functionValidator(v.validationFunction, v.errorKey))
        })
      }

      this.formControl.setValidators(validators)
      this.formControl.updateValueAndValidity()
    }
  }

  getErrorMessage(key: string) {
    return this.translator.instant(key)
  }

  getElementId(): string {
    return 'select-' + this.id + (!!this.labelParam?.index ? '-'.concat(this.labelParam.index) : '')
  }

  get shouldShowErrorStyle(): boolean {

    return (this.showError && this.formControl.hasError('required'))
      || (this.showCustomError !== undefined && this.showCustomError !== '')
      || (!this.formControl.hasError('required') && this.formControl.errors?.customErrorKey && this.formControl.touched)
  }
}
