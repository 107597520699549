import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageBaseComponent} from '../../PageBaseComponent';
import {GoogleReCaptchaV2Service} from '../../../services/google-recaptcha.service';
import {IntakeService} from '../../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../../external.router';
import {ApplicationStatusService} from '../../../services/application-status.service';
import {AppStateService} from '../../../services/app-state.service';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {catchError, filter, takeUntil, tap} from 'rxjs/operators';
import {ConfigService} from '../../../services/config.service';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {PageInfo} from '../../../models/page-map';
import {ApplicationStatusResponse, ApplicationStatusType} from '../../../models/application-status-response';
import {ProgramType} from '../../../models/program-type';
import {ScheduledMaintenanceService} from '../../../services/scheduled.maintenance.service';
import {of} from 'rxjs';
import {MaintenanceBannerConfiguration} from '../../../models/MaintenanceBannerConfiguration';
import {getBannerClass, getBannerIcon, isPastDateCheck} from "../../../utils/outage-banner-util";

@Component({
  selector: 'sd-asc-landing',
  templateUrl: './asc-landing.component.html',
  styleUrls: ['./asc-landing.component.scss']
})
export class AscLandingComponent extends PageBaseComponent implements OnInit, OnDestroy {
  errorMsgKey: string|undefined;
  showError = false;
  recaptcha: any = null;

  banners: MaintenanceBannerConfiguration[] = [];

  refCodeLengthValidatorFn = [
    {
      validationFunction: (value) => value && SadaCustomValidator.validateRequiredLength([value, 20, 20, true]),
      errorKey: 'app-status-landing.error.notEqualLengthOf20Chars'
    },
    {
      validationFunction: (value) => {
        return SadaCustomValidator.validateRefCodeFormat(value);
      }, errorKey: 'app-status-landing.error.invalidRefCode'
    }
  ]

  /**
   * Reference to recaptcha v2 element
   */
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private appStateService: AppStateService,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public appStatusService: ApplicationStatusService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              private cdr: ChangeDetectorRef,
              public recaptchaV2Service: GoogleReCaptchaV2Service,
              protected configService: ConfigService,
              private schMaintenanceService: ScheduledMaintenanceService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.ascLanding;
  }

  ngOnInit(): void {
    this.setupForm();

    this.populateReferenceCode();

    // clear record not found error when user is typing. Only show validation errors on submit form.
    this.form.controls.referenceCode.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.errorMsgKey = undefined;
    });

    this.schMaintenanceService.getBannerConfig().pipe(
      tap( res => {
        this.banners = res.banners
          .filter(banner =>
            banner.enable &&
            isPastDateCheck(banner.postStart, banner.postEnd) &&
            banner.shownOnStatusChecker
          )
          .sort((a, b) => a.order - b.order);
      }),
      catchError( err => {
        // ignore all errors
        return of(err)
      })
    ).subscribe();

    if (this.enableRecaptcha) {
      this.translator.onLangChange.subscribe(() => {
        if (this.recaptchaV2Service.captchaIsLoaded) {
          this.recaptchaV2Service.changeLanguage(this.captchaElem);
        }
      })
    }
  }

  postInitializeForm(){}

  preSaveApplication(){}

  getBannerClass(type: string): string {
    return getBannerClass(type);
  }

  getBannerIcon(type: string): string {
    return getBannerIcon(type);
  }
  setupForm(): void {
    this.form = this.formBuilder.group({
      referenceCode: [this.appStatusService.getReferenceCode()],
      recaptcha: ['']
    });

    // MUST pass component's ChangeDetectorRef to recaptcha service
    this.recaptchaV2Service.setChangeDetectorRef(this.cdr);
  }

  /**
   * pre-populate reference code if provided in URL parameter.
   */
  populateReferenceCode(): void {
    this.route.queryParams.pipe(
      filter(params => params.referenceCode))
      .subscribe(params => {
        this.form.controls.referenceCode.setValue(params.referenceCode);
      });
  }

  /**
   * Display ReCaptcha if the backend service is having it enabled.
   */
  get enableRecaptcha() {
    return this.recaptchaV2Service.enableRecaptcha;
  }

  onSubmit() {
    if ( this.form.valid ) {

      // Execute invisible recaptcha v2
      // If recaptcha v2 is enabled, only call app status from success handler
      if (this.enableRecaptcha) {
        if (this.recaptchaV2Service.captchaIsLoaded) {
          if (this.captchaElem == null) {
            console.log('null captcha elem');
          }
          else {
            // console.log('Using the following reCAPTCHA v2 site key: '+this.recaptchaV2Service.siteKey)
            console.log('Executing invisible reCAPTCHA v2');
            this.captchaElem.execute();
          }
        }
        else {
          console.log('reCAPTCHA v2 has not loaded, try again.');
        }
      }

      // If recaptcha v2 is disabled, call app status directly
      else {
        this.getAppStatusAndNavigate();
      }
    } else {
      this.showError = true
    }
  }

  handleRecaptchaV2Success($event) {
    this.recaptchaV2Service.handleAppStatusRecaptchaSuccess($event).subscribe(resp => {
      if (this.recaptchaV2Service.captchaSuccess && !this.recaptchaV2Service.reCaptchaVerifyCallFailure) {
        console.log('reCAPTCHA success on frontend');
        this.getAppStatusAndNavigate();
      }
      else {
        console.log('reCAPTCHA failed on frontend');
      }
    });
  }

  private getAppStatusAndNavigate(): void {
    // call get application status
    this.appStatusService.getStatus(this.form.get('referenceCode').value).subscribe({
      next: (resp) => {
        this.handleStatusResponse(resp);
      },
      error: (error) => {
        this.handleErrorResponse(error);
      }
    });
  }

  // Handle returned application status response
  private handleStatusResponse(resp: ApplicationStatusResponse) {
    this.appStatusService.setReferenceCode(this.form.get('referenceCode').value);
    this.appStatusService.setStatusResponse(resp);

    const programStatus = this.appStatusService.getStatusResponse().programStatuses.find((program) =>
      program.programType === ProgramType.ONW ||
      program.programType === ProgramType.ODS ||
      program.programType === ProgramType.ONWODS);

    if (programStatus && (programStatus.applicationStatus === ApplicationStatusType.ApplicationPending)) {
      this.router.navigate(['/', 'applicationstatuspending']);
    } else if (programStatus && (programStatus.applicationStatus === ApplicationStatusType.Expired)) {
      this.showError = true;
      this.errorMsgKey = 'app-status-landing.error.expired';
      this.resetCaptcha();
    } else {
      this.router.navigate(['/', 'applicationstatusresults']);
    }
  }

  // Handle HTTP error response
  private handleErrorResponse(error: any) {

    this.showError = true;
    // handle 404 for ref code not found
    if ((error?.status === 404) && (error?.error?.error === 'Not Found')) {  // Application not found
      this.errorMsgKey = 'app-status-landing.error.notFound';
    } else { // all other errors, display generic message
      this.appStateService.emitState({ hasHttpError: true });
    }
    this.resetCaptcha();
  }

  private resetCaptcha() {
    if (this.captchaElem != null) {
      this.captchaElem.resetCaptcha();
    }
  }

  goToLandingPage() {
    return this.router.navigate(['/', 'intake', PageInfo.home]);
  }

  ngOnDestroy() {
    super.onDestroy();
  }
}
