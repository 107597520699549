import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Subject, Subscription} from 'rxjs';

import {popup} from '../../../dialog-constants';
import {AnswerList} from '../../../models/eid/answer-list.model';
import {EidProgress, Person} from '../../../models/eid/person.model';
import {QuestionList} from '../../../models/eid/question-list.model';
import {EidService} from '../../../services/eid.service';
import {ButtonUtil} from '../../../utils/button-util';
import {PageScrollingUtil} from '../../../utils/page-scrolling-util';
import {Timer} from '../../../utils/timer';
import {MccssDialogTitleComponent} from '../../../mccss-dialog-title/mccss-dialog-title.component';
import {PageInfo} from '../../../models/page-map';

@Component({
  selector: 'app-eid-questions',
  templateUrl: './eid-questions.component.html',
  styleUrls: ['./eid-questions.component.scss']
})
export class EidQuestionsComponent implements OnInit, OnDestroy {
  protected readonly _destroyed$ = new Subject<void>();

  pageId: string;
  questionsForm: FormGroup
  questionList: QuestionList
  person: Person
  isLoaded: boolean
  allQuestionAnswered: boolean;
  subscriptions$: Subscription[] = [];

  readonly THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
  readonly INACTIVITY_POPUP_TIME = 1200;  // In seconds

  constructor(private eidService: EidService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private idle: Idle,
              private ngZone: NgZone,
              private timer: Timer) {
    this.pageId = PageInfo.eidQuestions;
    this.allQuestionAnswered = true;
    this.route.params.subscribe(params => {
      this.eidService.getEligiblePersons().subscribe(persons => {
        this.person = persons?.find(person => person.personId === params.personId)
        eidService.readEIdApplicantQuestions(this.person.personId).subscribe(questionList => {
          this.isLoaded = true
          this.questionList = questionList;
          const controlsConfig = {};
          for (const question of questionList.questions) {
            controlsConfig[question.id] = []
          }
          this.questionsForm = this.fb.group(controlsConfig)
          this.setEidQuestionsOptions(this.questionList)
        }, () => {
          this.onQuestionsUnavailable()
        })
      }, error => {
        this.onQuestionsUnavailable()
      })
    })

    ngZone.runOutsideAngular(() => {
      idle.setIdle(this.INACTIVITY_POPUP_TIME);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    })

    this.subscriptions$.push(
      idle.onIdleStart.subscribe(() => this.ngZone.run(() => {
        this.dialog.closeAll()
        this.openTitlePopup(popup.eidInactiveTimeout)
      }))
    )

    this.subscriptions$.push(
      timer.getTimer(this.THIRTY_MINUTES_IN_MILLISECONDS).subscribe(() => this.ngZone.run(() => {
        this.eidService.cancelEIdForApplicant(this.person.personId).subscribe()
        this.eidService.updateEidProgress(this.person.personId, EidProgress.skipped)
        this.router.navigate(['../..', 'confirm'], {
          replaceUrl: true,
          relativeTo: this.route
        })
      })
    ))

    ngZone.runOutsideAngular(() => {
      idle.watch();
    });
  }

  private onQuestionsUnavailable() {
    this.isLoaded = true
    this.eidService.updateEidProgress(this.person.personId, EidProgress.unavailable)
    this.router.navigate(['../..', 'confirm'], {
      replaceUrl: true,
      relativeTo: this.route
    })
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.idle.stop()
    this.idle.ngOnDestroy();
    this.dialog.closeAll()
    this.subscriptions$.forEach(s => s.unsubscribe());
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  onContinueClicked() {
    this.isLoaded = false
    const answerList: AnswerList = {
      id: this.questionList.id,
      transactionKey: this.questionList.transactionKey,
      personId: this.person.personId,

      answers: Object.keys(this.questionsForm.controls).map(key => {
        return {
          questionId: key,
          id: this.questionsForm.value[key]
        }
      })
    }

    if (this.isAnswersProvided(answerList)) {
      this.allQuestionAnswered = true;
      this.eidService.answerEIdApplicantQuestions(this.person.personId, answerList).subscribe(() => {
          this.isLoaded = true
          this.eidService.updateEidProgress(this.person.personId, EidProgress.completed)
          this.router.navigate(['../..', 'confirm'], {
            replaceUrl: true,
            relativeTo: this.route
          })
        },
        error => {
          this.isLoaded = true
        })
    } else {
      this.isLoaded = true;
      this.allQuestionAnswered = false;
      PageScrollingUtil.scrollToElement(PageScrollingUtil.locateElement('div.page-description'))
    }
  }

  private openTitlePopup(content: any, buttonAction?: any, closeAction?: any) {
    const dialogRef = this.dialog.open(MccssDialogTitleComponent, {
      panelClass: 'sada-dialog-class'
    })
    dialogRef.componentInstance.dialogHeader = content.title
    dialogRef.componentInstance.dialogBody = content.body
    dialogRef.componentInstance.dialogButtonText = content.button
    dialogRef.componentInstance.buttonAction = () => {
      if (buttonAction) {
        buttonAction()
      }
      dialogRef.close()
    }
    dialogRef.componentInstance.closeButtonAction = () => {
      if (closeAction) {
        closeAction()
      }
      dialogRef.close()
    }
  }

  private isAnswersProvided(answerList: AnswerList): boolean {
    if (!answerList?.answers || answerList?.answers.length === 0) {
      return true;
    }
    const unansweredQuestions = answerList.answers.filter(answer => !answer.id);
    return (unansweredQuestions.length === 0);
  }

  private setEidQuestionsOptions(eidQuestionList: QuestionList) {
    eidQuestionList?.questions?.forEach( eidQuestion =>
    {
      const eidOptions = []
      eidQuestion?.answerChoices.forEach(choice => {
        eidOptions.push({value: choice.id, label: choice.text})
      })
      eidQuestion.options = eidOptions
    })
  }
}
