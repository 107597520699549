<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <sd-info-text header="eid-questions.page.alert.title.time.limit" content="eid-questions.page.time.limit" contentId="pageAlertContentId"></sd-info-text>
  </div>
  <div id="spinner-container" *ngIf="!isLoaded">
    <div class="row">
      <div class="col-12">
        <div id="spinner">
        <svg class='ontario-loading-indicator__spinner' viewBox='0 0 66 66'>
          <circle fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>
        </svg>
          <span class="sr-only">{{'eid-page.loading.indicator.text' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div [innerHTML]="'eid-questions.page.description' | translate" class="col page-description mt-3 mb-5" aria-label="description" *ngIf="!isLoaded">
  </div>
  <sd-alert-err *ngIf="!allQuestionAnswered" [errorId]="'missing-answer-error-msg'" [errorMessage]="'error.eid.question.not.answered' | translate"></sd-alert-err>
  <form [formGroup]="questionsForm" *ngIf="questionList && isLoaded" sdDisableAutocomplete>
    <div class="ontario-h2 mt-3 mb-4" aria-label="person-name">
      {{this.person.names.join(' ')}}
    </div>
    <div class="form-group required">
      <div *ngFor="let eidQuestion of questionList.questions">
        <sd-radio-question [showError]="false" [id]="eidQuestion.text"
                           [choices]="eidQuestion.options" [required]="true"
                           [controlName]="eidQuestion.id">
        </sd-radio-question>
      </div>

    </div>
    <app-mccss-submit-button buttonId="next-button" dataE2E="continueBtn" buttonLabel="btn.continue" [preventDoubleClick]="true" (handleClick)="onContinueClicked()"></app-mccss-submit-button>
  </form>
  <div [innerHTML]="'eid-page.loading.text' | translate" class="col mt-3 mb-5 loading-text page-description" *ngIf="!isLoaded"></div>
</sd-page-container>
