import {Address} from '../models/address';

export class AddressUtil {
  static constructHomeAddressFromAnswers(answers: any): Address {
    return {
      type: answers.deliveryType,
      routeNumber: answers.ruralRoute,
      postalCode: answers.postalCode,
      streetDirection: answers.direction,
      streetDirectionDisplay: answers.direction,
      municipality: answers.cityOrTown,
      province: answers.province,
      streetName: answers.streetName,
      streetNumber: answers.streetNumber,
      streetNumberSuffix: answers.streetNumberSuffix,
      streetType: answers.streetType,
      streetDisplayType: answers.streetType,
      unitNumber: answers.apartmentNumber
    }
  }

  static constructMailingAddressFromAnswers(answers: any): Address {
    if (!answers.addressSame || answers.addressSame[0] !== 'yes') {
      return {
        type: answers.mDeliveryType,
        deliveryInstallationArea: answers.mStation,
        deliveryInstallationName: answers.mGeneralDelivery,
        routeNumber: answers.mRuralRoute,
        boxNumber: answers.mPoBox,
        unitNumber: answers.mApartmentNumber,
        streetName: answers.mStreetName,
        streetNumber: answers.mStreetNumber,
        streetNumberSuffix: answers.mStreetNumberSuffix,
        streetType: answers.mStreetType,
        streetDisplayType: answers.mStreetType,
        streetDirection: answers.mDirection,
        streetDirectionDisplay: answers.mDirection,
        municipality: answers.mCityOrTown,
        province: answers.mProvince,
        postalCode: answers.mPostalCode
      }
    }
  }
  // TODO in 68588
  static constructTrusteeAddressFromAnswers(answers: any): Address {
      return {
        type: answers.trusteeDeliveryType,
        deliveryInstallationArea: answers.trusteeStation,
        deliveryInstallationName: answers.trusteeGeneralDelivery,
        routeNumber: answers.trusteeRuralRoute,
        boxNumber: answers.trusteePoBox,
        unitNumber: answers.trusteeApartmentNumber,
        streetName: answers.trusteeStreetName,
        streetNumber: answers.trusteeStreetNumber,
        streetNumberSuffix: answers.trusteeStreetNumberSuffix,
        streetType: answers.trusteeStreetType,
        streetDisplayType: answers.trusteeStreetType,
        streetDirection: answers.trusteeDirection,
        streetDirectionDisplay: answers.trusteeDirection,
        municipality: answers.trusteeCityOrTown,
        province: answers.trusteeProvince,
        postalCode: answers.trusteePostalCode
      }
  }

  static populateHomeAddressAnswers(address: Address): any {
    return {
      deliveryType: address.type,
      ruralRoute: address.routeNumber,
      apartmentNumber: address.unitNumber,
      streetNumber: address.streetNumber,
      streetNumberSuffix: address.streetNumberSuffix,
      streetName: address.streetName,
      streetType: address.streetType,
      direction: address.streetDirection,
      cityOrTown: address.municipality,
      province: address.province,
      postalCode: address.postalCode
    }
  }

  static populateMailingAddressAnswers(address: Address): any {
    return {
      mDeliveryType: address.type,
      mPoBox: address.boxNumber,
      mGeneralDelivery: AddressUtil.constructGeneralDelivery(
        [address.deliveryInstallationType, address.deliveryInstallationName, address.deliveryInstallationArea]),
      mRuralRoute: address.routeNumber,
      mApartmentNumber: address.unitNumber,
      mStreetNumber: address.streetNumber,
      mStreetNumberSuffix: address.streetNumberSuffix,
      mStreetName: address.streetName,
      mStreetType: address.streetType,
      mDirection: address.streetDirection,
      mCityOrTown: address.municipality,
      mProvince: address.province,
      mPostalCode: address.postalCode
    }
  }

  static populateTrusteeAddressAnswers(address: Address): any {
    return {
      trusteeDeliveryType: address.type,
      trusteePoBox: address.boxNumber,
      trusteeGeneralDelivery: AddressUtil.constructGeneralDelivery(
        [address.deliveryInstallationType, address.deliveryInstallationName, address.deliveryInstallationArea]),
      trusteeRuralRoute: address.routeNumber,
      trusteeApartmentNumber: address.unitNumber,
      trusteeStreetNumber: address.streetNumber,
      trusteeStreetNumberSuffix: address.streetNumberSuffix,
      trusteeStreetName: address.streetName,
      trusteeStreetType: address.streetType,
      trusteeDirection: address.streetDirection,
      trusteeCityOrTown: address.municipality,
      trusteeProvince: address.province,
      trusteePostalCode: address.postalCode
    }
  }

  static resetMailingAddressValidators(form: any): void {
    form.controls.mDeliveryType.clearValidators()
    form.controls.mDeliveryType.updateValueAndValidity()
    this.resetValidatorsForTypeRuralRoute(form)
    this.resetValidatorsForTypePOBox(form)
    this.resetValidatorsForTypeGeneralDelivery(form)
    this.resetValidatorsForTypeStandStreetAddress(form)
    this.resetValidatorsForMailingOrTrusteeAddressCommonFields(form)
  }

  static resetTrusteeAddressValidators(form: any,isTrusteeAddress: boolean): void {
    form.controls.trusteeDeliveryType.clearValidators()
    form.controls.trusteeDeliveryType.updateValueAndValidity()
    this.resetValidatorsForTypeRuralRoute(form,isTrusteeAddress)
    this.resetValidatorsForTypePOBox(form,isTrusteeAddress)
    this.resetValidatorsForTypeGeneralDelivery(form,isTrusteeAddress)
    this.resetValidatorsForTypeStandStreetAddress(form,isTrusteeAddress)
    this.resetValidatorsForMailingOrTrusteeAddressCommonFields(form,isTrusteeAddress)
  }

  static resetValidatorsForTypePOBox(form: any, isTrusteeAddress: boolean=false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const controls = ['PoBox', 'Station'];

    controls.forEach(control => {
      form.controls[`${prefix}${control}`].clearValidators();
      form.controls[`${prefix}${control}`].updateValueAndValidity();
    });
  }

  static resetValidatorsForTypeGeneralDelivery(form: any, isTrusteeAddress: boolean=false): void {
    const controlType = isTrusteeAddress ? 'trusteeGeneralDelivery' : 'mGeneralDelivery';
    form.controls[controlType].clearValidators();
    form.controls[controlType].updateValueAndValidity();
  }

  static resetValidatorsForTypeRuralRoute(form: any, isTrusteeAddress: boolean=false): void {
    const controlType = isTrusteeAddress ? 'trusteeRuralRoute' : 'mRuralRoute';
    form.controls[controlType].clearValidators();
    form.controls[controlType].updateValueAndValidity();
  }

  static resetValidatorsForTypeStandStreetAddress(form: any, isTrusteeAddress: boolean=false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const controls = ['ApartmentNumber', 'StreetNumber', 'StreetNumberSuffix', 'StreetName', 'StreetType', 'Direction'];

    controls.forEach(control => {
      form.controls[`${prefix}${control}`].clearValidators();
      form.controls[`${prefix}${control}`].updateValueAndValidity();
    });
  }

  static resetValidatorsForMailingOrTrusteeAddressCommonFields(form: any, isTrusteeAddress: boolean=false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const controls = ['CityOrTown', 'Province', 'PostalCode'];

    controls.forEach(control => {
      form.controls[`${prefix}${control}`].clearValidators();
      form.controls[`${prefix}${control}`].updateValueAndValidity();
    });
  }

  private static constructGeneralDelivery(params: string[]): string {
    const filteredParams = params.filter(Boolean)
    return filteredParams.length > 0 ? filteredParams.join(' ') : undefined;
  }

  static removeAllFieldsFromMailingAddressJson(json: any){
    this.removeStandardStreetAddressFieldsFromMailingOrTrusteeAddressJson(json)
    this.removePoBoxFieldsFromMailingOrTrusteeAddressJson(json)
    this.removeRuralRouteFieldsFromMailingOrTrusteeAddressJson(json)
    this.removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson(json)
    this.removeCommonFieldsFromMailingOrTrusteeAddressJson(json)
  }

  static removeAllFieldsFromTrusteeAddressJson(json: any,isTrusteeAddress:boolean){
    this.removeStandardStreetAddressFieldsFromMailingOrTrusteeAddressJson(json,isTrusteeAddress)
    this.removePoBoxFieldsFromMailingOrTrusteeAddressJson(json,isTrusteeAddress)
    this.removeRuralRouteFieldsFromMailingOrTrusteeAddressJson(json,isTrusteeAddress)
    this.removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson(json,isTrusteeAddress)
    this.removeCommonFieldsFromMailingOrTrusteeAddressJson(json,isTrusteeAddress)
  }

  static removeCommonFieldsFromMailingOrTrusteeAddressJson(json: any, isTrusteeAddress: boolean = false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const fields = ['CityOrTown', 'Province', 'PostalCode'];

    fields.forEach(field => delete json?.[`${prefix}${field}`]);
  }

  static removePoBoxFieldsFromMailingOrTrusteeAddressJson(json: any, isTrusteeAddress: boolean = false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const fields = ['PoBox', 'Station'];

    fields.forEach(field => delete json?.[`${prefix}${field}`]);
  }


  static removeStandardStreetAddressFieldsFromMailingOrTrusteeAddressJson(json: any, isTrusteeAddress: boolean = false): void {
    const prefix = isTrusteeAddress ? 'trustee' : 'm';
    const fields = ['ApartmentNumber', 'StreetNumber', 'StreetNumberSuffix', 'StreetName', 'StreetType', 'Direction'];

    fields.forEach(field => delete json?.[`${prefix}${field}`]);
  }

  static removeGeneralDeliveryFieldsFromMailingOrTrusteeAddressJson(json: any, isTrusteeAddress: boolean = false): void {
    isTrusteeAddress ? delete json?.trusteeGeneralDelivery : delete json?.mGeneralDelivery;
  }

  static removeRuralRouteFieldsFromMailingOrTrusteeAddressJson(json: any, isTrusteeAddress: boolean = false): void {
    isTrusteeAddress ? delete json?.trusteeRuralRoute : delete json?.mRuralRoute;
  }

}
