import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ConfigService} from '../../../services/config.service';
import {PageInfo} from '../../../models/page-map';
import {MenuService} from '../../../services/menu.service';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../../services/intake.service';
import {AuthService} from '../../../services/auth.service';
import {AppState, AppStateService} from '../../../services/app-state.service';
import {WindowService} from '../../../services/window.service';
import {RouteStateService} from 'src/app/services/route-state.service';

@Component({
  selector: 'sd-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit, AfterViewInit {
  private readonly _destroying$ = new Subject<void>()

  @Input()
  pageId: string | undefined; // Should match with page level key in en.json and fr.json. ex: start-two.

  @Input()
  pageHeader: string | undefined; // Optional sub header text

  @Input()
  pageTitleInsideComponent: boolean | undefined;

  @Input()
  customPageTitleKey: string | undefined; // Override the page title key if necessary
                                          // ex. additional-info requires single vs. family title keys

  @Input() // Passed to the back to top button to disable scroll to improve performance on certain pages
  disableScrollEvent: boolean | undefined;

  @Input() // To determine to display Public Secure Redirection error message.
  showPsRedirectError: boolean;

  @Input()
  showResumeBanner: boolean;

  @Input()
  showRequiredInfoBanner: boolean;

  @Input()
  showBackButton = false;

  pageTitleKey: string;
  isLandingPage: boolean;
  isErrorOrInfoPage: boolean;
  isStatusChecker: boolean;
  addDivider: boolean;
  showPsRedirectErrorFromMenu: boolean;
  skipDividerPages: Array<string> = [PageInfo.ascLanding, PageInfo.ascPending, PageInfo.ascResultsOds, PageInfo.ascResultsOnw,
    PageInfo.ascResultsOnwOds, PageInfo.globalError, PageInfo.loginConfirmation, PageInfo.unauthorized, 'app-status.results.with.warning',
    PageInfo.bya]

  percentageCompletedMap = new Map<string, number>([]);

  showBackendErrorBox$ = this.appState.state$.pipe(map((appState: AppState) => appState.hasHttpError));

  constructor(
    private location: Location,
    protected configService: ConfigService,
    private router: Router,
    public intake: IntakeService,
    public route: ActivatedRoute,
    private authService: AuthService,
    protected menuService: MenuService,
    private appState: AppStateService,
    private windowService: WindowService,
    private routeStateService: RouteStateService,
  ) { }

  ngOnInit(): void {
    // Use custom page title key if provided, otherwise default from page id
    this.pageTitleKey = this.customPageTitleKey ? this.customPageTitleKey : this.pageId+'.page.title';
    this.addDivider = true;
    const configMap = this.configService?.getClientConfig()?.pageProgressMap;
    if (configMap) {
      // client config JSON to map
      this.percentageCompletedMap = new Map(Object.entries(configMap));

      // Do not display percent indicator on before you apply page
      this.percentageCompletedMap.set(PageInfo.bya, null);
    }

    if(this.pageId === PageInfo.home) {
      this.isLandingPage = true;
    } else if (this.pageId === PageInfo.ascLanding) {
      this.isStatusChecker = true;
    } else if(this.pageId && this.skipDividerPages.includes(this.pageId)) {
      this.addDivider = false;
    }

    this.showPsRedirectErrorFromMenu = false;
    // Subscribe to error changes in the header to display in page
    if (this.menuService) {
      this.menuService.getShowPSRedirectError()
        .pipe(
          takeUntil(this._destroying$)
        )
        .subscribe(
          value => this.showPsRedirectErrorFromMenu = value
        )
    }

    this.showBackendErrorBox$.pipe(filter(hasErr => !!hasErr), takeUntil(this._destroying$)).subscribe(() => {
      setTimeout(() => this.windowService.scrollToTop())
    });

    this.routeStateService.isErrorOrInfoPage$.pipe(takeUntil(this._destroying$))
      .subscribe(isErrorOrInfoPage => {
        this.isErrorOrInfoPage = isErrorOrInfoPage;
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    document.getElementById('ontario-logo')?.focus();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onClick() {
    let navigateToPage;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i<PageInfo.pageOrderedArray.length; i++) {
      if (PageInfo.pageOrderedArray[i] === this.pageId) {
        navigateToPage = i-1
      }
    }
    for (let x = navigateToPage; x >= 0; x--) {
      if (PageInfo.pageOrderedArray[x] === PageInfo.confirmAddress) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.spouseInfo) && (!this.intake.getAppSpouseRequired())) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.childrenInfo) && (!this.intake.getAppChildRequired())) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.sponsorship) && (!this.intake.getSponsorshipRequired())) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.createAccount) &&
        (this.authService.isAuthorizedToSave() || this.intake.getIsApplyingForSomeoneElse())) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.bankDetails) && (this.intake.getShouldExcludeDbd())) {
        continue
      } else if ((PageInfo.pageOrderedArray[x] === PageInfo.financialIndependence) && (!this.intake.getFinIndependencePageRequired())) {
        continue
      } else {
        navigateToPage = x
        break
      }
    }
    this.router.navigate(['intake', PageInfo.pageOrderedArray[navigateToPage]])
  }
}
