<div *ngIf="!isLandingPage" class="col-12 px-0">
  <div class="ontario-step-indicator">
    <div class="ontario-row">
      <div class="ontario-columns ontario-small-12 back-button-container">
        <div class="ontario-step-indicator--with-back-button">
          <button *ngIf="showBackButton" class="ontario-button ontario-button--tertiary" (click)="onClick()">
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="#ontario-icon-chevron-left"></use>
            </svg>{{'back' | translate}}
          </button>
          <div *ngIf="pageTitleKey && !showBackButton"></div>
          <div *ngIf="percentageCompletedMap.get(pageId)">
            <sd-progress-indicator [value]="percentageCompletedMap.get(pageId)"></sd-progress-indicator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-container">
  <div class="page-inner-container" [ngClass]="{'ontario-padding-bottom-24-!': !isLandingPage}" [ngClass]="{'text-on-bg-img': isErrorOrInfoPage}">
    <hr *ngIf="!isLandingPage && addDivider" class="divider" aria-hidden="true">
    <sd-message-box id="backend-error-box" *ngIf="showBackendErrorBox$ | async" msgType="error"
                    [title]="'error.service.down.title' | translate"
                    [message]="(isStatusChecker ? 'error.service.down.asc' : 'error.service.down') | translate">
    </sd-message-box>
    <div *ngIf="showPsRedirectError || showPsRedirectErrorFromMenu" class="ontario-margin-bottom-32-!">
      <sd-alert-err [errorId]="'psRedirectError'"
                    [errorMessage]="'error.ps.redirect.error' | translate">
      </sd-alert-err>
    </div>
    <div *ngIf="showResumeBanner">
      <sd-info-text header="page-info-alert.welcome.back.title"
                    contentId="pageInfoAlertWelcomeBackId"
                    content="page-info-alert.welcome.back.content">
      </sd-info-text>
    </div>
    <sd-warning-text *ngIf="showRequiredInfoBanner" [title]="'warning-required-fields.title'">
      <div id="warning-required-field-content">
        {{'warning-required-fields.content' | translate}} <b>{{'warning-required-fields.content2' | translate}}</b>.
      </div>
    </sd-warning-text>
    <ng-content select="#page-alerts-container"></ng-content>
    <div *ngIf="pageTitleKey&&!pageTitleInsideComponent">
      <h1 *ngIf="!isLandingPage" data-e2e="pageTitle" id="{{pageTitleKey}}" class="ontario-h1" aria-level="1" tabindex="0">{{pageTitleKey | translate}}</h1>
      <h2 *ngIf="pageHeader" data-e2e="pageHeader"  class="ontario-h2">{{pageHeader | translate}}</h2>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<sd-back-to-top-button [disableScrollEvent]="disableScrollEvent"></sd-back-to-top-button>
