import {ApplicationAnswers} from '../../models/data.model';

export class SharedUtil {

  static checkIfDbdShouldBeExcluded(applicationAnswers: ApplicationAnswers): boolean {

    if (applicationAnswers.jsonData.ownItemsOfValueDetailsListBankAccounts) {
      return applicationAnswers.jsonData.ownItemsOfValueDetailsListBankAccounts?.filter(
        bankAccount => {
        return bankAccount.applicantType.indexOf('Applicant') >= 0;
      }).length > 0
    }
    return false
  }
  static shouldHideMealsAndPaymentAmountFieldsInHs(currentHousingSituation: string, relationShip: string) {
    return ['Roommate', 'Landlord', 'Other'].includes(relationShip) ||
      ('Parent' !== relationShip &&
        ['I do not pay rent', 'i do not pay rent without meals provided', 'i do not pay rent with meals provided',
          'I have no housing costs'].includes(currentHousingSituation));
  }

  static isValidLivingWithYouRelationship(currentHousingSituation: string, livingWithYouRelationship: string): boolean {
    const validChoices = SharedUtil.getLivingWithYouRelationshipChoices(currentHousingSituation) || [];
    const validValues = validChoices.map(choice => choice.value);
    return validValues.includes(livingWithYouRelationship);
  }

  static getLivingWithYouRelationshipChoices(currentHousingSituation: string) {
    if (['I pay rent, my meals are not included',
      'I pay rent that includes my meals',
      'I pay subsidized rent'].includes(currentHousingSituation)) {
      return [{
        value: 'Parent',
        label: 'housing-situation.choices.livingWithYouRelationship.parent'
      },
        {
          value: 'Roommate',
          label: 'housing-situation.choices.livingWithYouRelationship.roomMate'
        },
        {
          value: 'Landlord',
          label: 'housing-situation.choices.livingWithYouRelationship.landlord'
        }];
    } else if ('I own my home' === currentHousingSituation) {
      return [{
        value: 'Parent',
        label: 'housing-situation.choices.livingWithYouRelationship.parent'
      },
        {
          value: 'Relative',
          label: 'housing-situation.choices.livingWithYouRelationship.relative'
        },
        {
          value: 'Tenant',
          label: 'housing-situation.choices.livingWithYouRelationship.tenant'
        }];
    } else if (['I do not pay rent', 'i do not pay rent without meals provided',
      'i do not pay rent with meals provided'].includes(currentHousingSituation)) {
      return [{
        value: 'Parent',
        label: 'housing-situation.choices.livingWithYouRelationship.parent'
      },
        {
          value: 'Relative',
          label: 'housing-situation.choices.livingWithYouRelationship.relative'
        },
        {
          value: 'Roommate',
          label: 'housing-situation.choices.livingWithYouRelationship.roomMate'
        }];
    } else if ('I have no housing costs' === currentHousingSituation) {
      return [{
        value: 'Parent',
        label: 'housing-situation.choices.livingWithYouRelationship.parent'
      },
        {
          value: 'Relative',
          label: 'housing-situation.choices.livingWithYouRelationship.relative'
        },
        {
          value: 'Other',
          label: 'housing-situation.choices.livingWithYouRelationship.other'
        }];
    }
  }
}

export const swapKeyValue = (obj, key: string[]) => Object.keys(key.reduce((object, path) => object[path], {...obj}) || [])
  .map(k => ({[key.reduce((object, path) => object[path], {...obj})[k]]: `${key.join('.')}.${k}`}))

export const flatToObject = (objs: any[][]) => objs.reduce((p,c)=>([...p, ...c])).reduce((p,c)=>({...p, ...c}), {})
